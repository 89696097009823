
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { 
  ArrowRight, 
  Bot, 
  MessageSquare, 
  FileText, 
  Database,
  Workflow,
  FileSearch,
  FileSpreadsheet,
  PenTool,
  ChartBar,
  ClipboardList,
  Search,
  Lightbulb,
  Code,
  Activity,
  Wrench,
  Building2
} from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';

const Solutions = () => {
  useEffect(() => {
    document.title = 'AI Solutions | Synthreo';
  }, []);

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="relative py-32 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="max-w-3xl">
              <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-4">
                AI Solutions <br />
                <span>Tailored to </span>
                <span className="text-synthreo-blue-medium">Your</span><br />
                <span>Business Needs</span>
              </h1>
              <p className="text-xl mb-8 text-gray-300 font-poppins">
                From automating routine tasks to enhancing complex decision-making, 
                our AI agents deliver measurable results.
              </p>
            </div>
          </div>
        </section>

        {/* Solutions Overview */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto">
            <div className="mb-16">
              <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">
                Our Solutions
              </h2>
              <p className="text-xl mb-10 text-gray-300 max-w-3xl font-poppins">
                Explore our comprehensive range of AI solutions designed to transform your business operations.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <MessageSquare size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">AI Agents & Chatbots</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Transform customer interactions with intelligent AI assistants.
                    </p>
                    <Button variant="outline" className="bg-transparent text-synthreo-blue-medium border-synthreo-blue-dark/50 hover:bg-synthreo-blue-darkest/50">
                      <Link to="/solutions/ai-agents-chatbots">Learn More</Link>
                    </Button>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Workflow size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Workflow Automation</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Streamline business processes with intelligent automation.
                    </p>
                    <Button variant="outline" className="bg-transparent text-synthreo-blue-medium border-synthreo-blue-dark/50 hover:bg-synthreo-blue-darkest/50">
                      <Link to="/solutions/workflow-automation">Learn More</Link>
                    </Button>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <FileText size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Document Processing</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Extract insights from content with AI-powered document processing.
                    </p>
                    <Button variant="outline" className="bg-transparent text-synthreo-blue-medium border-synthreo-blue-dark/50 hover:bg-synthreo-blue-darkest/50">
                      <Link to="/solutions/document-processing">Learn More</Link>
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </section>

        {/* Implementation Process */}
        <section className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="mb-10">
              <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">
                Seamless Implementation, Measurable Results
              </h2>
              <p className="text-xl mb-10 text-gray-300 max-w-3xl font-poppins">
                Our structured approach ensures successful AI adoption with minimal disruption to your operations.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Search size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Discovery & Assessment</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      We analyze your current processes, identify high-impact opportunities, and define success metrics.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Lightbulb size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Solution Design</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Our experts design a tailored AI solution that integrates with your existing systems and workflows.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Code size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Development & Testing</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      We build and rigorously test your AI solution to ensure it meets performance and security requirements.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Activity size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Deployment & Training</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      We deploy your solution and provide comprehensive training to ensure successful adoption.
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </section>

        {/* Success Stories */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto">
            <div>
              <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">
                Success Stories
              </h2>
              <p className="text-xl mb-10 text-gray-300 max-w-3xl font-poppins">
                See how organizations like yours are achieving remarkable results with Synthreo.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Building2 size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Global Financial Services Firm</h3>
                    <div className="space-y-3 text-gray-300 font-poppins">
                      <p className="flex items-start">
                        <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                        <span>75% reduction in customer service response time</span>
                      </p>
                      <p className="flex items-start">
                        <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                        <span>Deployed AI agents to handle routine customer inquiries, freeing advisors to focus on complex client needs</span>
                      </p>
                    </div>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Building2 size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Healthcare Provider Network</h3>
                    <div className="space-y-3 text-gray-300 font-poppins">
                      <p className="flex items-start">
                        <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                        <span>92% decrease in insurance claim processing errors</span>
                      </p>
                      <p className="flex items-start">
                        <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                        <span>Automated claims processing workflow, reducing processing time from days to minutes</span>
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="mt-10 text-center">
                <Button className="btn-glow text-white">
                  <Link to="/case-studies">View All Case Studies</Link>
                </Button>
              </div>
            </div>
          </div>
        </section>

        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default Solutions;
