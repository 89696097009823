
import React from 'react';
import { MessageSquare, FileText, Database, Globe } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { 
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from '@/components/ui/carousel';

const UseCases = () => {
  const useCases = [
    {
      icon: <MessageSquare className="h-8 w-8 text-synthreo-blue-light" />,
      title: "Customer Service AI Agents",
      description: "AI-powered agents that handle customer inquiries 24/7, resolving issues quickly and escalating only when necessary.",
      result: "60% reduction in response times and 40% decrease in support costs"
    },
    {
      icon: <FileText className="h-8 w-8 text-synthreo-blue-light" />,
      title: "Document Processing & Analysis",
      description: "Automated extraction and analysis of data from documents, contracts, and forms with high accuracy.",
      result: "85% faster processing with 95% accuracy compared to manual methods"
    },
    {
      icon: <Database className="h-8 w-8 text-synthreo-blue-light" />,
      title: "Data Analysis & Reporting",
      description: "AI agents that analyze large datasets, identify patterns, and generate actionable reports automatically.",
      result: "70% reduction in reporting time with enhanced insight quality"
    },
    {
      icon: <Globe className="h-8 w-8 text-synthreo-blue-light" />,
      title: "Multilingual Content Management",
      description: "Create, translate, and optimize content across languages while maintaining brand voice and compliance.",
      result: "80% faster content creation with consistent quality across markets"
    }
  ];

  return (
    <section id="use-cases" className="py-24 bg-synthreo-black relative">
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/3 bottom-1/3 h-[350px] w-[350px] rounded-full bg-synthreo-blue-dark/5 blur-3xl" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
            AI Agents <span className="text-gradient">in Action</span>
          </h2>
          <p className="text-xl text-gray-300 font-poppins">
            See how our AI solutions are delivering real-world results across industries and business functions.
          </p>
        </div>

        <div className="mb-16 px-6">
          <Carousel
            opts={{
              align: "start",
              loop: true
            }}
            className="w-full"
          >
            <CarouselContent>
              {useCases.map((useCase, index) => (
                <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 pl-4">
                  <div className="card-glow h-full rounded-lg p-8 flex flex-col">
                    <div className="mb-6 p-3 w-fit rounded-lg bg-synthreo-blue-darkest/30 blue-glow">
                      {useCase.icon}
                    </div>
                    <h3 className="text-2xl font-semibold mb-4 font-gilroy">{useCase.title}</h3>
                    <p className="text-gray-300 mb-6 flex-grow font-poppins">{useCase.description}</p>
                    <div className="bg-synthreo-blue-darkest/40 p-4 rounded-lg mt-auto">
                      <p className="text-synthreo-blue-light font-medium font-poppins">Result: {useCase.result}</p>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <div className="flex justify-center mt-8">
              <CarouselPrevious className="relative static translate-y-0 mr-4" />
              <CarouselNext className="relative static translate-y-0" />
            </div>
          </Carousel>
        </div>

        <div className="text-center">
          <Button
            className="rounded-lg bg-synthreo-black-light px-10 py-4 text-base font-medium text-white transition-colors hover:bg-synthreo-black-dark h-auto font-gilroy"
            asChild
          >
            <a href="/case-studies">See All Use Cases</a>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
