
/**
 * Checks if the user has consented to a specific cookie type
 * @param type - The type of cookie consent to check ('analytics' or 'marketing')
 * @returns boolean indicating if consent was given
 */
export const hasCookieConsent = (type: 'analytics' | 'marketing'): boolean => {
  // If user has no consent stored, assume they haven't made a choice yet
  if (!localStorage.getItem('cookieConsent')) {
    return false;
  }

  // Check if user accepted all cookies
  if (localStorage.getItem('cookieConsent') === 'all') {
    return true;
  }

  // For selected cookies, check the specific type
  if (localStorage.getItem('cookieConsent') === 'selected') {
    return localStorage.getItem(`${type}Consent`) === 'true';
  }

  // If consent is 'rejected' or any other value, return false
  return false;
};

/**
 * Opens the cookie consent dialog again
 */
export const openCookieSettings = (): void => {
  // We now preserve the consent flag but trigger the dialog to open
  // This way we don't lose the user's preferences when they want to review them
  
  // Force a page reload with a query parameter to reopen settings
  // We'll handle this differently now - just dispatch a custom event
  const event = new CustomEvent('openCookieSettings');
  window.dispatchEvent(event);
};

/**
 * Checks if user has made any cookie consent choice
 * @returns boolean indicating if user has made a choice
 */
export const hasUserMadeConsentChoice = (): boolean => {
  return localStorage.getItem('cookieConsent') !== null;
};

/**
 * Gets the current cookie consent status
 * @returns Object with the current consent status
 */
export const getCurrentConsentStatus = () => {
  const consentChoice = localStorage.getItem('cookieConsent');
  const analyticsConsent = localStorage.getItem('analyticsConsent') === 'true';
  const marketingConsent = localStorage.getItem('marketingConsent') === 'true';
  
  return {
    hasChosen: consentChoice !== null,
    consentChoice: consentChoice || 'none',
    analyticsConsent,
    marketingConsent
  };
};

/**
 * Save cookie consent settings and dispatch event
 */
export const saveCookieConsent = (
  consentType: 'all' | 'selected' | 'rejected', 
  analytics?: boolean, 
  marketing?: boolean
): void => {
  // Store the consent type
  localStorage.setItem('cookieConsent', consentType);
  
  // For specific consent types
  if (consentType === 'all') {
    localStorage.setItem('analyticsConsent', 'true');
    localStorage.setItem('marketingConsent', 'true');
  } else if (consentType === 'selected') {
    localStorage.setItem('analyticsConsent', analytics ? 'true' : 'false');
    localStorage.setItem('marketingConsent', marketing ? 'true' : 'false');
  } else if (consentType === 'rejected') {
    localStorage.setItem('analyticsConsent', 'false');
    localStorage.setItem('marketingConsent', 'false');
  }
  
  // Dispatch an event to notify all listeners that consent has changed
  const event = new CustomEvent('consentUpdated');
  window.dispatchEvent(event);
};
