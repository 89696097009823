
/**
 * Initialize and handle Botx chat service
 */

import { toast } from 'sonner';

let botxLoaded = false;

/**
 * Initializes Botx chat with error handling
 */
export function initializeBotx() {
  if (botxLoaded) return;

  try {
    // Create a global error handler specifically for Botx JSON parsing errors
    const originalJSONParse = JSON.parse;
    JSON.parse = function(text) {
      try {
        return originalJSONParse(text);
      } catch (e) {
        // Return empty object instead of throwing for Botx script
        if (e.stack && e.stack.includes('botx.min.js')) {
          console.info('Recovering from Botx JSON parse error');
          return {};
        }
        throw e; // Re-throw for non-Botx errors
      }
    };
    
    botxLoaded = true;
  } catch(e) {
    console.error("Error initializing Botx:", e);
    recoverFromBlankScreen();
  }
}

/**
 * Recovery function for blank screens
 */
export function recoverFromBlankScreen() {
  try {
    console.log("Running blank screen recovery check");
    const root = document.getElementById('root');
    if (root && root.style.visibility !== 'visible') {
      console.log("Forcing root to be visible");
      root.style.visibility = 'visible';
      
      // Notify with toast to inform user
      toast.info("App recovered from loading issue", {
        duration: 3000,
        position: "bottom-center"
      });
    }
    document.documentElement.classList.remove('js-loading');
  } catch(e) {
    console.error("Error in recovery function:", e);
  }
}
