
import React, { useEffect } from 'react';
import SolutionLayout from '@/components/layouts/SolutionLayout';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { 
  ArrowRight, 
  PenTool, 
  FileSearch, 
  FileSpreadsheet
} from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';

const DocumentProcessing = () => {
  // Schema for Document Processing page
  const docProcessingSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Document Processing | Synthreo",
    "description": "Generate high-quality content at scale or extract valuable insights from existing documents with Synthreo's AI-powered document processing.",
    "url": "https://synthreo.ai/solutions/document-processing",
    "mainEntity": {
      "@type": "Service",
      "name": "Document Processing",
      "provider": {
        "@type": "Organization",
        "name": "Synthreo"
      },
      "description": "AI-powered document creation, analysis, and data extraction solutions.",
      "offers": {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Document Processing Solutions"
        }
      }
    }
  };

  return (
    <SolutionLayout 
      title="Document Processing | Synthreo"
      description="Generate high-quality content at scale or extract valuable insights from existing documents with Synthreo's AI-powered document processing."
      url="https://synthreo.ai/solutions/document-processing"
      canonical="https://synthreo.ai/solutions/document-processing"
      keywords="document processing, content generation, document analysis, data extraction, AI document processing"
      schema={docProcessingSchema}
    >
      {/* Hero Section */}
      <section className="relative py-32 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-4 text-center">
              Document <span className="text-synthreo-blue-medium">Processing</span>
            </h1>
            <p className="text-xl mb-8 text-gray-300 font-poppins text-center">
              Generate high-quality content at scale or extract valuable insights from existing documents.
            </p>
          </div>
        </div>
      </section>

      {/* Creating or Processing Documents and Content */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
        <div className="max-w-7xl mx-auto">
          <div className="mb-16">
            <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6 text-center">
              Creating or Processing Documents and Content
            </h2>
            <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto font-poppins text-center">
              Generate high-quality content at scale or extract valuable insights from existing documents.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <div className="mb-4 text-synthreo-blue-medium text-center">
                    <PenTool size={28} className="mx-auto" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Content Generation</h3>
                  <p className="text-gray-300 mb-4 font-poppins text-center">
                    Create engaging materials, reports, documentation, and other content types with AI assistance.
                  </p>
                </CardContent>
              </Card>

              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <div className="mb-4 text-synthreo-blue-medium text-center">
                    <FileSearch size={28} className="mx-auto" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Document Analysis</h3>
                  <p className="text-gray-300 mb-4 font-poppins text-center">
                    Analyze contracts, reports, and other documents to identify key information and insights.
                  </p>
                </CardContent>
              </Card>

              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <div className="mb-4 text-synthreo-blue-medium text-center">
                    <FileSpreadsheet size={28} className="mx-auto" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Data Extraction and Summarization</h3>
                  <p className="text-gray-300 mb-4 font-poppins text-center">
                    Extract structured data from unstructured documents and provide concise summaries of lengthy content.
                  </p>
                </CardContent>
              </Card>
            </div>

            <div className="mt-10 bg-synthreo-blue-darkest/30 rounded-lg p-6 border border-synthreo-blue-dark/20 text-center">
              <h4 className="text-lg font-bold mb-2 font-gilroy text-center">Use Case Example</h4>
              <p className="text-gray-300 mb-4 font-poppins text-center">
                A medical manufacturer used Synthreo's AI-powered clinical assessment agent to analyze vast numbers of clinical documents, resulting in a 68% reduction in evaluation time and a 47% improvement in diagnostic accuracy while adapting to changing regulatory requirements.
              </p>
              <div className="text-center">
                <Button variant="outline" className="bg-transparent text-synthreo-blue-medium border-synthreo-blue-dark/50 hover:bg-synthreo-blue-darkest/50">
                  <Link to="/resources/case-studies/clinical-assessments-automation">Learn More</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Stories */}
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6 text-center">
              Success Stories
            </h2>
            <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto font-poppins text-center">
              See how organizations are achieving remarkable results with Synthreo Document Processing.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Legal Services Firm</h3>
                  <div className="space-y-3 text-gray-300 font-poppins">
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>98% accuracy in contract analysis</span>
                    </p>
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>Reduced contract review time from weeks to hours by automatically extracting key clauses</span>
                    </p>
                  </div>
                </CardContent>
              </Card>

              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Research Organization</h3>
                  <div className="space-y-3 text-gray-300 font-poppins">
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>85% faster literature review process</span>
                    </p>
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>Implemented AI-powered document analysis to extract and summarize key findings from research papers</span>
                    </p>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="mt-10 text-center">
              <Button className="btn-glow text-white">
                <Link to="/resources/case-studies" className="w-full h-full flex items-center justify-center">View All Case Studies</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </SolutionLayout>
  );
};

export default DocumentProcessing;
