
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Building, Target, CheckCircle, BarChart, Briefcase, Lightbulb } from 'lucide-react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import SEOMetaTags from '@/components/SEOMetaTags';

const CaseStudyDetail = () => {
  const { slug } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${slug ? slug.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) + ' | ' : ''}Synthreo`;
  }, [slug]);

  const caseStudies = [
    {
      id: 1,
      industry: 'Healthcare',
      title: 'Revolutionizing Clinical Assessments for Medical Manufacturers with AI-Driven Automation',
      summary: 'Our client is a leading manufacturer of medical stents, catering to diverse surgical and cardiovascular needs. They needed to process and analyze large amounts of clinical study documents efficiently.',
      challenge: 'The client faced significant challenges in managing and analyzing vast amounts of clinical study documents, which was time-consuming and prone to human error. Manual processing of these documents was taking too long and delaying critical decision-making processes.',
      solution: 'We developed an AI-powered document processing and analysis solution that extracts relevant data with precision from clinical study documents. The system automates the identification and extraction of key information, reducing processing time dramatically.',
      implementation: 'We implemented a custom NLP (Natural Language Processing) system that was trained on medical terminology and clinical study formats. The system was integrated with the client\'s existing document management system for seamless workflow.',
      results: [
        '85% reduction in document processing time',
        'Increased accuracy of data extraction to 96%',
        'Enabled real-time analysis of clinical study results',
        'Freed up medical staff to focus on interpretation rather than data collection'
      ],
      slug: 'clinical-assessments-automation',
      tags: ['Increase productivity', 'Healthcare'],
      icon: Building
    },
    {
      id: 2,
      industry: 'Manufacturing',
      title: 'Autonomous Email and Documents Processing with GPT-4 model for business automation',
      summary: 'The international manufacturing client needed Generative AI to streamline business operations by efficiently processing and responding to customer inquiries.',
      challenge: 'The client was struggling with inefficient processing of daily email inquiries with attached documents. The manual handling of these communications led to slow response times and inconsistent customer service quality.',
      solution: 'We developed an automated email and document processing system using GPT-4 technology to extract data, understand context, and integrate with existing business systems. The AI-powered solution automatically categorizes, prioritizes, and responds to routine inquiries.',
      implementation: 'The implementation involved custom integration with the client\'s email system and document management platform. We trained the AI model on company-specific data and created response templates that maintain the company\'s voice.',
      results: [
        'Response time reduced from 20 minutes to 16 seconds per inquiry',
        'Customer satisfaction increased by 35%',
        'Saved the equivalent of 1 full-time employee',
        'Consistent quality of responses regardless of volume'
      ],
      slug: 'manufacturing-email-automation',
      tags: ['Increase productivity', 'Manufacturing'],
      icon: Target
    },
    {
      id: 3,
      industry: 'Capital Markets',
      title: 'ESG-Focused Media Monitoring For Investment Professionals. AI-Powered.',
      summary: 'Our client, a subsidiary of a Swiss-based insurance group, was trying to improve ESG investment strategies while managing costs and ensuring regulatory compliance.',
      challenge: 'The client needed to enhance their ESG (Environmental, Social, and Governance) investment strategies while managing costs and ensuring regulatory compliance. Traditional methods of ESG analysis were time-consuming and often missed critical information hidden in vast amounts of media data.',
      solution: 'We developed an AI-powered ESG media monitoring solution that processes millions of documents to calculate reliable ESG scores. The system continuously monitors global news sources, regulatory filings, and social media to identify ESG-related events and trends.',
      implementation: 'We implemented a scalable cloud-based architecture with advanced natural language processing capabilities. The system was designed to learn from analyst feedback, continuously improving its accuracy and relevance.',
      results: [
        'Processed over 10 million documents daily for ESG analysis',
        'Improved ESG scoring accuracy by 40%',
        'Identified material ESG risks 2-3 days earlier than traditional methods',
        'Reduced research costs by 30% while increasing coverage'
      ],
      slug: 'esg-focused-media-monitoring',
      tags: ['Grow Revenue Profitably', 'Capital Markets'],
      icon: BarChart
    },
    {
      id: 4,
      industry: 'Manufacturing',
      title: '450x faster way to pick a supplier',
      summary: 'To deliver a proposal to a client, a manufacturing produce-to-order company needs to estimate costs for each order from 5000 suppliers.',
      challenge: 'The client, a manufacturing produce-to-order company, was struggling with estimating costs for each order from their pool of 5000 suppliers. The manual process was extremely time-consuming and often resulted in sub-optimal supplier selection, affecting both costs and delivery times.',
      solution: 'We developed AI-driven neural networks that predict prices and delivery dates for optimal supplier selection. The system analyzes historical data, current market conditions, and supplier performance metrics to recommend the best options for each specific order.',
      implementation: 'We integrated the solution with the client\'s ERP system and created a user-friendly dashboard for procurement teams. The AI models were trained on years of historical order data and continuously improve with each new transaction.',
      results: [
        'Supplier selection process 450 times faster than manual methods',
        'Cost savings of 15-20% on average per order',
        'Improved delivery time accuracy by 35%',
        'Enhanced ability to handle rush orders with optimal supplier matching'
      ],
      slug: 'supplier-selection-automation',
      tags: ['Grow Revenue Profitably', 'Manufacturing'],
      icon: Briefcase
    },
    {
      id: 5,
      industry: 'Capital Markets',
      title: 'SFDR Anti-Greenwashing Solution',
      summary: 'The client operates in a highly regulated capital markets environment. At the same time, they wanted to stay ahead of upcoming SFDR regulations to avoid anti-greenwashing penalties.',
      challenge: 'The client faced demanding regulations lacking clear guidelines for sustainable finance disclosure. They needed to ensure compliance with SFDR (Sustainable Finance Disclosure Regulation) and avoid potential greenwashing allegations, which could lead to significant penalties and reputational damage.',
      solution: 'We developed an enhanced ESG monitoring system with automated fund analysis and SFDR classification to reduce compliance risk. The solution provides transparent reporting on sustainability metrics and flags potential discrepancies between marketing claims and actual investments.',
      implementation: 'We created a dedicated compliance dashboard integrated with the client\'s portfolio management system. The solution included automatic document generation for regulatory filings and regular audit reports to ensure ongoing compliance.',
      results: [
        'Achieved 100% SFDR compliance across all managed funds',
        'Identified and corrected potential greenwashing issues in 15% of marketing materials',
        'Reduced compliance review time by 70%',
        'Positioned the client as an industry leader in sustainable finance transparency'
      ],
      slug: 'sfdr-anti-greenwashing',
      tags: ['Reduce Risk', 'Capital Markets'],
      icon: CheckCircle
    },
    {
      id: 6,
      industry: 'Legal',
      title: 'Saving 12 hours a week with Automated trademark protection and computer vision',
      summary: 'Our client, a mid-sized law firm has offered a "trademark protection service" to their clients, monitoring potential trademark infringements.',
      challenge: 'The law firm was performing manual repetitive work and was unable to automate with traditional methods. Their trademark protection service required hours of tedious visual comparison of logos and marks, resulting in high costs and limited scalability.',
      solution: 'We developed a computer vision solution that scans trademark databases and only requires human input when potential conflicts are detected. The AI-powered system automatically compares visual similarities between trademarks and can detect subtle variations that might constitute infringement.',
      implementation: 'We implemented a custom machine learning model trained on thousands of trademark pairs (both infringing and non-infringing). The system was integrated with global trademark databases and provides a simple interface for attorneys to review flagged cases.',
      results: [
        'Saved 12 hours per week of attorney time per client',
        'Increased monitoring coverage by 300%',
        'Identified 28% more potential infringements than manual methods',
        'Enabled the firm to offer the service at a more competitive price point'
      ],
      slug: 'trademark-protection-automation',
      tags: ['Legal & Compliance', 'Grow Revenue Profitably'],
      icon: Lightbulb
    }
  ];

  const selectedCaseStudy = caseStudies.find(study => study.slug === slug);
  
  const generateStructuredData = (study) => {
    if (!study) return null;
    
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": study.title,
      "description": study.summary,
      "articleSection": "Case Study",
      "articleBody": `Challenge: ${study.challenge} Solution: ${study.solution}`,
      "keywords": study.tags.join(", "),
      "publisher": {
        "@type": "Organization",
        "name": "Synthreo",
        "logo": {
          "@type": "ImageObject",
          "url": "https://synthreo.ai/lovable-uploads/8a7c589a-1508-4816-b1d6-ecefe1aa5add.png"
        }
      }
    };
  };

  if (!selectedCaseStudy) {
    return (
      <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
        <ParticleBackground />
        <Navbar />
        <main className="flex-grow flex items-center justify-center">
          <div className="text-center">
            <h1 className="text-4xl font-bold font-gilroy mb-4">Case Study Not Found</h1>
            <p className="text-lg text-gray-300 mb-8">
              Sorry, the case study you are looking for does not exist.
            </p>
            <Button asChild variant="outline">
              <Link to="/resources/case-studies">
                <ArrowLeft size={16} className="mr-2" />
                Back to Case Studies
              </Link>
            </Button>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  const structuredData = generateStructuredData(selectedCaseStudy);

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white resources-content">
      <SEOMetaTags 
        title={`${selectedCaseStudy.title} | Synthreo Case Study`}
        description={selectedCaseStudy.summary}
        url={`https://synthreo.ai/resources/case-studies/${selectedCaseStudy.slug}`}
        canonical={`https://synthreo.ai/resources/case-studies/${selectedCaseStudy.slug}`}
        keywords={`${selectedCaseStudy.industry}, case study, ${selectedCaseStudy.tags.join(', ')}, Synthreo AI`}
        schema={structuredData}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow pt-24">
        <section className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <div className="max-w-4xl mx-auto text-center">
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-6">
              <Badge variant="outline" className="text-synthreo-blue-medium border-synthreo-blue-dark px-3 py-1">
                {selectedCaseStudy.industry}
              </Badge>
              <div className="flex flex-wrap gap-2 justify-center">
                {selectedCaseStudy.tags.map((tag, index) => (
                  <Badge key={index} variant="secondary">{tag}</Badge>
                ))}
              </div>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-4 text-center mx-auto">{selectedCaseStudy.title}</h1>
            <p className="text-xl text-gray-300 mb-8 text-center max-w-3xl mx-auto">
              {selectedCaseStudy.summary}
            </p>
          </div>
        </section>

        <section className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto resource-detail-content">
          <div className="grid md:grid-cols-5 gap-8">
            <div className="md:col-span-1">
              <div className="sticky top-24 space-y-4">
                <div className="p-4 bg-synthreo-blue-darkest/30 rounded-lg border border-synthreo-blue-dark/20 text-center">
                  <div className="text-synthreo-blue-medium mb-3 flex justify-center">
                    <selectedCaseStudy.icon size={32} />
                  </div>
                  <h3 className="text-lg font-bold font-gilroy mb-2">{selectedCaseStudy.industry} Solution</h3>
                  <p className="text-sm text-gray-400">
                    AI-powered solution for {selectedCaseStudy.industry.toLowerCase()} industry challenges.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="md:col-span-4">
              <div className="space-y-12">
                <div>
                  <h2 className="text-3xl font-bold mb-4 font-gilroy flex items-center justify-center md:justify-start">
                    <span className="text-synthreo-blue-medium mr-3">
                      <Target size={24} />
                    </span>
                    Challenge
                  </h2>
                  <div className="bg-synthreo-black-light/30 p-6 rounded-lg border border-synthreo-blue-dark/10">
                    <p className="text-gray-300 text-lg text-center md:text-left">
                      {selectedCaseStudy.challenge}
                    </p>
                  </div>
                </div>
                
                <div>
                  <h2 className="text-3xl font-bold mb-4 font-gilroy flex items-center justify-center md:justify-start">
                    <span className="text-synthreo-blue-medium mr-3">
                      <Lightbulb size={24} />
                    </span>
                    Solution
                  </h2>
                  <div className="bg-synthreo-black-light/30 p-6 rounded-lg border border-synthreo-blue-dark/10">
                    <p className="text-gray-300 text-lg text-center md:text-left">
                      {selectedCaseStudy.solution}
                    </p>
                  </div>
                </div>
                
                <div>
                  <h2 className="text-3xl font-bold mb-4 font-gilroy flex items-center justify-center md:justify-start">
                    <span className="text-synthreo-blue-medium mr-3">
                      <Briefcase size={24} />
                    </span>
                    Implementation
                  </h2>
                  <div className="bg-synthreo-black-light/30 p-6 rounded-lg border border-synthreo-blue-dark/10">
                    <p className="text-gray-300 text-lg text-center md:text-left">
                      {selectedCaseStudy.implementation}
                    </p>
                  </div>
                </div>
                
                <div>
                  <h2 className="text-3xl font-bold mb-4 font-gilroy flex items-center justify-center md:justify-start">
                    <span className="text-synthreo-blue-medium mr-3">
                      <BarChart size={24} />
                    </span>
                    Results
                  </h2>
                  <div className="bg-synthreo-black-light/30 p-6 rounded-lg border border-synthreo-blue-dark/10">
                    <ul className="space-y-3">
                      {selectedCaseStudy.results.map((result, index) => (
                        <li key={index} className="flex items-start justify-center md:justify-start">
                          <span className="text-synthreo-blue-medium mr-3 mt-1">
                            <CheckCircle size={18} />
                          </span>
                          <span className="text-gray-300 text-lg">
                            {result}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 font-gilroy text-center">Related Case Studies</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {caseStudies
              .filter(study => study.slug !== selectedCaseStudy.slug && 
                (study.industry === selectedCaseStudy.industry || 
                 study.tags.some(tag => selectedCaseStudy.tags.includes(tag))))
              .slice(0, 3)
              .map((study, index) => (
                <article key={index} className="border border-gray-800 rounded-lg p-6 hover:border-synthreo-blue-medium transition-colors duration-300 text-center">
                  <div className="mb-2">
                    <span className="text-sm text-synthreo-blue-medium font-medium">{study.industry}</span>
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">{study.title}</h3>
                  <p className="text-gray-300 mb-4 line-clamp-3">{study.summary}</p>
                  <Link 
                    to={`/resources/case-studies/${study.slug}`}
                    className="text-synthreo-blue-medium font-medium inline-block hover:underline flex items-center justify-center"
                  >
                    Read Case Study
                    <ArrowRight size={14} className="ml-1" />
                  </Link>
                </article>
              ))}
          </div>
        </section>

        <section className="py-8 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto text-center">
          <Button asChild variant="outline">
            <Link to="/resources/case-studies">
              <ArrowLeft size={16} className="mr-2" />
              Back to Case Studies
            </Link>
          </Button>
        </section>

        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default CaseStudyDetail;
