
import React from 'react';
import { Button } from "@/components/ui/button";
import { Shield } from 'lucide-react';
import { saveCookieConsent } from '../../utils/cookieConsent';
import { initializeAnalytics } from '../../utils/analytics';
import { toast } from "sonner";

interface CookieBannerProps {
  showBanner: boolean;
  setShowBanner: (show: boolean) => void;
  handleOpenSettings: () => void;
  handleOpenPrivacyPolicy: (e: React.MouseEvent) => void;
}

const CookieBanner = ({ 
  showBanner, 
  setShowBanner, 
  handleOpenSettings, 
  handleOpenPrivacyPolicy 
}: CookieBannerProps) => {
  
  const handleAcceptAll = () => {
    saveCookieConsent('all');
    setShowBanner(false);
    initializeAnalytics();
    toast.success("All cookies accepted");
  };

  const handleRejectAll = () => {
    saveCookieConsent('rejected');
    setShowBanner(false);
    initializeAnalytics();
    toast.success("Cookies rejected, only essential cookies will be used");
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-4 left-4 z-50 max-w-md bg-background border rounded-lg shadow-lg p-4 animate-fade-in">
      <div className="flex items-start gap-3">
        <Shield className="h-5 w-5 text-primary mt-1 flex-shrink-0" />
        <div>
          <h3 className="font-medium text-sm">We value your privacy</h3>
          <p className="text-xs text-muted-foreground mt-1 mb-3">
            This website uses cookies to improve your experience and analyze site traffic. 
            By continuing to use our site, you consent to our use of cookies in accordance with our Privacy Policy.
          </p>
          <div className="flex flex-wrap gap-2">
            <Button size="sm" variant="default" onClick={handleAcceptAll} className="text-xs">
              Accept All
            </Button>
            <Button size="sm" variant="outline" onClick={handleOpenSettings} className="text-xs">
              Customize
            </Button>
            <Button size="sm" variant="outline" onClick={handleRejectAll} className="text-xs">
              Reject All
            </Button>
          </div>
          <p className="text-xs mt-2">
            <a href="#" onClick={handleOpenPrivacyPolicy} className="text-primary underline">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
