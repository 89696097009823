
import React from 'react';
import { Check, Minus, X } from 'lucide-react';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from '@/components/ui/table';

const FeatureComparison: React.FC = () => {
  const features = [
    {
      category: 'AI Capabilities',
      items: [
        { name: 'Fully Managed ThreoAgents', starter: '1 Fully Managed ThreoAgent', professional: 'Up to 3 Fully Managed ThreoAgents', enterprise: 'Unlimited Fully Managed ThreoAgents' },
        { name: 'Task Automations (ThreoAssistants)', starter: 'Up to 3 ThreoAssistants', professional: 'Up to 10 ThreoAssistants', enterprise: 'Unlimited' },
        { name: 'GPT-powered ThreoAI Experience', starter: 'Basic', professional: 'Advanced', enterprise: 'Enterprise-grade' },
        { name: 'Conversation Limit', starter: '1,000', professional: '5,000', enterprise: 'Unlimited' },
        { name: 'Workflow Coverage', starter: '1 function (e.g., Helpdesk or HR)', professional: 'Up to 3 functions (e.g., IT, HR, Finance)', enterprise: 'Unlimited cross-functional workflows' },
      ]
    },
    {
      category: 'Deployment & Workflows',
      items: [
        { name: 'Setup Type', starter: 'Prebuilt setup', professional: 'Prebuilt or light customization', enterprise: 'Fully customized agents, workflows, and data integrations' },
        { name: 'Workflow-embedded AI Agents', starter: 'Basic implementation', professional: 'Advanced implementation', enterprise: 'Fully customized implementation' },
        { name: 'Internal Service Desk Support', starter: 'Basic', professional: 'Advanced', enterprise: 'Enterprise-grade' },
        { name: 'HR Operations Support', starter: 'Limited', professional: 'Standard', enterprise: 'Comprehensive' },
        { name: 'Finance Workflow Support', starter: 'Limited', professional: 'Standard', enterprise: 'Comprehensive' },
      ]
    },
    {
      category: 'Agent Intelligence',
      items: [
        { name: 'Goal-driven Agent Capabilities', starter: 'Basic', professional: 'Advanced', enterprise: 'Enterprise-grade' },
        { name: 'Rigid Chatbot Replacement', starter: true, professional: true, enterprise: true },
        { name: 'Team Assistance Features', starter: 'Basic daily tasks', professional: 'Advanced workflow integration', enterprise: 'Complete team workflow automation' },
        { name: 'Chained Agent Automation', starter: false, professional: 'Optional add-on', enterprise: true },
        { name: 'Agent Training', starter: 'Basic', professional: 'Basic', enterprise: 'Advanced' },
      ]
    },
    {
      category: 'Analytics & Support',
      items: [
        { name: 'Performance Tracking', starter: 'Basic usage metrics', professional: 'Outcome-based analytics', enterprise: 'Custom dashboards & KPI tracking' },
        { name: 'Onboarding & Support', starter: 'Shared support + email', professional: 'Guided onboarding + chat support', enterprise: 'White-glove onboarding + dedicated success manager' },
        { name: 'Ongoing Agent Management', starter: 'Monthly monitoring & updates', professional: 'Ongoing tuning + health checks', enterprise: 'Proactive optimization & dedicated agent operations' },
        { name: 'Service Commitments', starter: 'Standard', professional: 'Enhanced', enterprise: 'Custom' },
        { name: 'Portal Branding', starter: false, professional: 'Basic branding options', enterprise: 'Full white-labeling capabilities' },
      ]
    }
  ];

  return (
    <div className="py-12 md:py-16 bg-synthreo-black-light">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center font-gilroy">
          Feature Comparison
        </h2>
        <p className="text-center text-sm text-gray-400 mb-8">
          Detailed breakdown of what's included in each plan.
        </p>
        
        <div className="overflow-x-auto rounded-lg border border-gray-800 shadow-lg">
          <Table className="w-full border-collapse">
            <TableHeader>
              <TableRow className="bg-synthreo-black border-b border-gray-800">
                <TableHead className="py-5 px-6 text-left font-medium text-lg text-synthreo-blue-light">Features</TableHead>
                <TableHead className="py-5 px-6 text-center font-medium text-lg text-synthreo-blue-light">Starter</TableHead>
                <TableHead className="py-5 px-6 text-center font-medium text-lg text-synthreo-blue-light">Professional</TableHead>
                <TableHead className="py-5 px-6 text-center font-medium text-lg text-synthreo-blue-light">Enterprise</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {features.map((category, idx) => (
                <React.Fragment key={`category-${idx}`}>
                  <TableRow className="bg-synthreo-blue-darkest/30 border-b border-gray-800">
                    <TableCell colSpan={4} className="py-4 px-6 font-semibold text-md text-white">
                      {category.category}
                    </TableCell>
                  </TableRow>
                  
                  {category.items.map((feature, featureIdx) => (
                    <TableRow 
                      key={`feature-${idx}-${featureIdx}`} 
                      className="border-b border-gray-800 hover:bg-synthreo-black-light/70 transition-colors"
                    >
                      <TableCell className="py-4 px-6 text-sm font-medium">{feature.name}</TableCell>
                      <TableCell className="text-center py-4 px-6 text-sm">
                        {typeof feature.starter === 'boolean' ? (
                          feature.starter ? 
                            <Check size={20} className="mx-auto text-synthreo-blue-light" /> : 
                            <X size={20} className="mx-auto text-gray-500" />
                        ) : (
                          feature.starter
                        )}
                      </TableCell>
                      <TableCell className="text-center py-4 px-6 text-sm">
                        {typeof feature.professional === 'boolean' ? (
                          feature.professional === true ? 
                            <Check size={20} className="mx-auto text-synthreo-blue-light" /> : 
                            <X size={20} className="mx-auto text-gray-500" />
                        ) : (
                          feature.professional
                        )}
                      </TableCell>
                      <TableCell className="text-center py-4 px-6 text-sm">
                        {typeof feature.enterprise === 'boolean' ? (
                          feature.enterprise ? 
                            <Check size={20} className="mx-auto text-synthreo-blue-light" /> : 
                            <Minus size={20} className="mx-auto text-gray-500" />
                        ) : (
                          feature.enterprise
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
        
        {/* SEO Text Box Section */}
        <div className="mt-16 max-w-4xl mx-auto border-l-4 border-synthreo-blue-light px-6 py-8 bg-synthreo-black/30 rounded-r-lg">
          <h3 className="text-2xl font-bold mb-6 text-synthreo-blue-light font-gilroy">
            What Makes Synthreo ThreoAgents Different?
          </h3>
          
          <div className="space-y-6 text-gray-200">
            <p className="leading-relaxed">
              Synthreo offers Fully Managed ThreoAgents—built, monitored, and maintained by our team—so your 
              business can focus on outcomes, not infrastructure. Our intelligent, goal-driven agents replace 
              rigid chatbots and support internal service desks, HR operations, and finance workflows.
            </p>
            
            <p className="leading-relaxed">
              Unlike traditional chatbots, Synthreo's GPT-powered ThreoAI experience handles everything. 
              Your ThreoAssistants are deployed as workflow-embedded AI agents that assist teams daily,
              delivering fast, accurate responses, automating tasks, and scaling with your needs.
            </p>
            
            <p className="leading-relaxed">
              With Synthreo, you can launch ThreoAgents for use cases like HR onboarding, FAQs, order tracking, 
              employee self-service, and more—all without hiring developers or managing infrastructure.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureComparison;
