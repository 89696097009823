
import React from 'react';
import { Zap } from 'lucide-react';

const RoadmapTimeline = () => {
  return (
    <section className="py-20 relative">
      <div className="absolute inset-0 z-0">
        <div className="absolute right-0 top-1/4 h-[400px] w-[400px] translate-x-1/2 rounded-full bg-synthreo-blue-darkest/5 blur-3xl" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 font-gilroy">
            AI Practice Implementation: <span className="text-gradient">90-Day Roadmap</span>
          </h2>
          <div className="mx-auto h-1 w-20 bg-gradient-to-r from-synthreo-blue-darkest to-synthreo-blue-medium mb-8" />
          <p className="text-lg text-gray-300 max-w-3xl mx-auto font-poppins">
            Our structured approach ensures you can quickly build and launch your AI practice.
          </p>
        </div>
        
        {/* Timeline style implementation */}
        <div className="relative">
          {/* Timeline connector */}
          <div className="hidden md:block absolute left-1/2 top-0 bottom-0 w-1 bg-gradient-to-b from-synthreo-blue-darkest/50 via-synthreo-blue-medium/30 to-synthreo-blue-darkest/50 transform -translate-x-1/2"></div>
          
          {/* Day 1-30 */}
          <div className="flex flex-col md:flex-row items-center mb-16">
            <div className="md:w-1/2 md:pr-12 mb-8 md:mb-0 md:text-right">
              <div className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light rounded-lg p-6 border-l-4 border-synthreo-blue-medium shadow-lg card-glow">
                <h3 className="text-xl font-bold mb-4 font-gilroy flex items-center justify-end">
                  <span>Days 1-30: Engage</span>
                  <div className="ml-4 bg-synthreo-blue-darkest/80 text-white rounded-full h-10 w-10 flex items-center justify-center font-bold z-10 blue-glow">1</div>
                </h3>
                <ul className="space-y-3 font-poppins">
                  <li className="flex items-center justify-end">
                    <span>Leadership engagement & strategy alignment</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                  <li className="flex items-center justify-end">
                    <span>AI readiness assessment</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                  <li className="flex items-center justify-end">
                    <span>Initial AI agent development</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                  <li className="flex items-center justify-end">
                    <span>Go-to-market strategy development</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="hidden md:flex flex-col items-center justify-center z-10">
              <div className="h-6 w-6 rounded-full bg-synthreo-blue-medium blue-glow"></div>
            </div>
            
            <div className="md:w-1/2 md:pl-12 md:opacity-0"></div>
          </div>
          
          {/* Day 31-60 */}
          <div className="flex flex-col md:flex-row items-center mb-16">
            <div className="md:w-1/2 md:pr-12 md:opacity-0 mb-8 md:mb-0"></div>
            
            <div className="hidden md:flex flex-col items-center justify-center z-10">
              <div className="h-6 w-6 rounded-full bg-synthreo-blue-medium blue-glow"></div>
            </div>
            
            <div className="md:w-1/2 md:pl-12">
              <div className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light rounded-lg p-6 border-r-4 border-synthreo-blue-medium shadow-lg card-glow">
                <h3 className="text-xl font-bold mb-4 font-gilroy flex items-center">
                  <div className="mr-4 bg-synthreo-blue-darkest/80 text-white rounded-full h-10 w-10 flex items-center justify-center font-bold z-10 blue-glow">2</div>
                  <span>Days 31-60: Grow</span>
                </h3>
                <ul className="space-y-3 font-poppins">
                  <li className="flex items-center">
                    <Zap className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                    <span>Team training & knowledge transfer</span>
                  </li>
                  <li className="flex items-center">
                    <Zap className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                    <span>Internal AI agent deployment</span>
                  </li>
                  <li className="flex items-center">
                    <Zap className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                    <span>Client use case exploration</span>
                  </li>
                  <li className="flex items-center">
                    <Zap className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                    <span>Marketing preparations</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          {/* Day 61-90 */}
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 md:pr-12 mb-8 md:mb-0 md:text-right">
              <div className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light rounded-lg p-6 border-l-4 border-synthreo-blue-medium shadow-lg card-glow">
                <h3 className="text-xl font-bold mb-4 font-gilroy flex items-center justify-end">
                  <span>Days 61-90: Deploy</span>
                  <div className="ml-4 bg-synthreo-blue-darkest/80 text-white rounded-full h-10 w-10 flex items-center justify-center font-bold z-10 blue-glow">3</div>
                </h3>
                <ul className="space-y-3 font-poppins">
                  <li className="flex items-center justify-end">
                    <span>First client deployments</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                  <li className="flex items-center justify-end">
                    <span>Refinement of solutions</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                  <li className="flex items-center justify-end">
                    <span>Customer education & onboarding</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                  <li className="flex items-center justify-end">
                    <span>Revenue tracking & optimization</span>
                    <Zap className="h-5 w-5 text-synthreo-blue-medium ml-3 flex-shrink-0" />
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="hidden md:flex flex-col items-center justify-center z-10">
              <div className="h-6 w-6 rounded-full bg-synthreo-blue-medium blue-glow"></div>
            </div>
            
            <div className="md:w-1/2 md:pl-12 md:opacity-0"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapTimeline;
