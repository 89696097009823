
import React from 'react';
import { Helmet } from 'react-helmet-async';

type SEOMetaTagsProps = {
  title: string;
  description: string;
  url: string;
  imageUrl?: string;
  type?: string;
  schema?: Record<string, any>;
  canonical?: string;
  keywords?: string;
  author?: string;
  robots?: string;
};

const SEOMetaTags: React.FC<SEOMetaTagsProps> = ({
  title,
  description,
  url,
  imageUrl = 'https://synthreo.ai/lovable-uploads/a8308147-3201-4990-a59f-dc654ad2ab8c.png',
  type = 'website',
  schema,
  canonical,
  keywords = 'Fully Managed ThreoAgents, ThreoAssistants, GPT-powered ThreoAI experience, workflow-embedded AI agents, internal service desks, HR ops, finance workflows, rigid chatbot alternative, goal-driven agents',
  author = 'Synthreo',
  robots = 'index, follow'
}) => {
  // Format title to follow a standardized pattern: "Page Title | Synthreo"
  // If title already contains "Synthreo", don't add it again
  const formattedTitle = title.includes('Synthreo') 
    ? title 
    : `${title} | Synthreo`;
  
  const siteName = 'Synthreo';
  
  // Default schema for organization if none provided
  const defaultSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Synthreo",
    "url": "https://synthreo.ai",
    "logo": "https://synthreo.ai/logo.png",
    "sameAs": [
      "https://twitter.com/synthreo",
      "https://www.linkedin.com/company/synthreo"
    ],
    "description": "Provider of Fully Managed ThreoAgents and ThreoAssistants for businesses to streamline operations, enhance customer interactions, and improve decision-making.",
    "keywords": [
      "Fully Managed ThreoAgents",
      "ThreoAssistants",
      "GPT-powered ThreoAI experience",
      "workflow-embedded AI agents",
      "intelligent agents for ITSM",
      "HR ops",
      "finance workflows",
      "chatbot alternative"
    ]
  };

  // Use provided schema or fallback to default
  const schemaData = schema || defaultSchema;
  
  // Ensure imageUrl is an absolute URL
  const absoluteImageUrl = imageUrl.startsWith('http') ? imageUrl : `https://synthreo.ai${imageUrl.startsWith('/') ? '' : '/'}${imageUrl}`;
  
  // Optimal image dimensions are 1200x630 for Open Graph (1.91:1 ratio)
  const ogImageWidth = 1200;
  const ogImageHeight = 630;
  
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{formattedTitle}</title>
      <meta name="title" content={formattedTitle} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="robots" content={robots} />
      <meta name="theme-color" content="#0A0A0A" />
      <meta name="application-name" content="Synthreo" />
      
      {/* Canonical URL */}
      {canonical && <link rel="canonical" href={canonical} />}
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={absoluteImageUrl} />
      <meta property="og:image:width" content={String(ogImageWidth)} />
      <meta property="og:image:height" content={String(ogImageHeight)} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="en_US" />
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={formattedTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={absoluteImageUrl} />
      <meta name="twitter:creator" content="@synthreo" />
      <meta name="twitter:site" content="@synthreo" />
      
      {/* Additional SEO category meta tags */}
      <meta name="category" content="AI Solutions" />
      <meta name="ai-solutions" content="Fully Managed ThreoAgents, ThreoAssistants" />
      <meta name="ai-experience" content="GPT-powered ThreoAI experience" />
      <meta name="ai-applications" content="internal service desks, HR ops, finance workflows" />
      <meta name="ai-advantages" content="replaces rigid chatbots with intelligent, goal-driven agents" />
      <meta name="ai-implementation" content="workflow-embedded AI agents that assist teams daily" />
      
      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default SEOMetaTags;
