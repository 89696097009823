
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import { Button } from '@/components/ui/button';
import { BookOpen, FileText, BookMarked, ArrowRight, PenTool, Video } from 'lucide-react';
import { useContentData } from '@/hooks/useContentData';
import SEOMetaTags from '@/components/SEOMetaTags';

const Resources = () => {
  useEffect(() => {
    document.title = 'Resources | Synthreo';
  }, []);

  // Fetch featured blog posts and case studies
  const { data: blogPosts } = useContentData('blog', { limit: 3 });
  const { data: caseStudies } = useContentData('case-study', { limit: 3 });

  // SEO metadata
  const resourcesSchema = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "AI Resources & Learning Center | Synthreo",
    "description": "Explore our collection of guides, tutorials, case studies, and insights to help you leverage AI effectively in your business.",
    "url": "https://synthreo.ai/resources",
    "hasPart": [
      {
        "@type": "Blog",
        "name": "Synthreo Blog",
        "url": "https://synthreo.ai/resources/blog"
      },
      {
        "@type": "WebPage",
        "name": "Case Studies",
        "url": "https://synthreo.ai/resources/case-studies"
      },
      {
        "@type": "WebPage",
        "name": "Documentation",
        "url": "https://synthreo.ai/resources/documentation"
      }
    ]
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <SEOMetaTags 
        title="AI Resources & Learning Center | Synthreo"
        description="Explore our collection of guides, tutorials, case studies, and insights to help you leverage AI effectively in your business."
        url="https://synthreo.ai/resources"
        canonical="https://synthreo.ai/resources"
        keywords="AI resources, ThreoAgents guides, AI tutorials, case studies, whitepapers, documentation"
        schema={resourcesSchema}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="relative py-32 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-6 text-gradient-blue">
              AI Resources & Learning Center
            </h1>
            <p className="text-xl mb-8 text-gray-300 max-w-3xl mx-auto font-poppins">
              Explore our collection of guides, tutorials, and insights to help you leverage AI effectively in your business.
            </p>
          </div>
        </section>

        {/* Resource Categories */}
        <section className="py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold font-gilroy mb-12 text-center">Browse Resources by Category</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Guides & Tutorials */}
              <div className="bg-synthreo-black-light p-8 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-6 text-synthreo-blue-medium">
                  <BookOpen size={40} />
                </div>
                <h3 className="text-2xl font-bold mb-4 font-gilroy">Guides & Tutorials</h3>
                <p className="text-gray-300 mb-6 font-poppins">
                  Step-by-step instructions and tutorials to help you get the most out of AI technologies.
                </p>
                <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                  <Link to="/resources/blog">
                    Browse Guides
                    <ArrowRight size={16} />
                  </Link>
                </Button>
              </div>
              
              {/* Whitepapers */}
              <div className="bg-synthreo-black-light p-8 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-6 text-synthreo-blue-medium">
                  <FileText size={40} />
                </div>
                <h3 className="text-2xl font-bold mb-4 font-gilroy">Whitepapers</h3>
                <p className="text-gray-300 mb-6 font-poppins">
                  In-depth research and analysis on AI trends, technologies, and implementation strategies.
                </p>
                <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                  <Link to="/resources/blog">
                    Download Whitepapers
                    <ArrowRight size={16} />
                  </Link>
                </Button>
              </div>
              
              {/* Case Studies */}
              <div className="bg-synthreo-black-light p-8 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-6 text-synthreo-blue-medium">
                  <BookMarked size={40} />
                </div>
                <h3 className="text-2xl font-bold mb-4 font-gilroy">Case Studies</h3>
                <p className="text-gray-300 mb-6 font-poppins">
                  Real-world examples of how businesses have successfully implemented AI solutions.
                </p>
                <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                  <Link to="/resources/case-studies">
                    View Case Studies
                    <ArrowRight size={16} />
                  </Link>
                </Button>
              </div>
              
              {/* Blog */}
              <div className="bg-synthreo-black-light p-8 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-6 text-synthreo-blue-medium">
                  <PenTool size={40} />
                </div>
                <h3 className="text-2xl font-bold mb-4 font-gilroy">Blog</h3>
                <p className="text-gray-300 mb-6 font-poppins">
                  Latest insights, news, and thought leadership on AI and its applications in business.
                </p>
                <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                  <Link to="/resources/blog">
                    Read Blog
                    <ArrowRight size={16} />
                  </Link>
                </Button>
              </div>
              
              {/* Webinars */}
              <div className="bg-synthreo-black-light p-8 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-6 text-synthreo-blue-medium">
                  <Video size={40} />
                </div>
                <h3 className="text-2xl font-bold mb-4 font-gilroy">Webinars</h3>
                <p className="text-gray-300 mb-6 font-poppins">
                  Educational webinars and presentations on AI topics, featuring industry experts.
                </p>
                <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                  <Link to="/resources/blog">
                    Watch Webinars
                    <ArrowRight size={16} />
                  </Link>
                </Button>
              </div>
              
              {/* Documentation */}
              <div className="bg-synthreo-black-light p-8 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-6 text-synthreo-blue-medium">
                  <FileText size={40} />
                </div>
                <h3 className="text-2xl font-bold mb-4 font-gilroy">Documentation</h3>
                <p className="text-gray-300 mb-6 font-poppins">
                  Technical documentation and API references for developers working with our AI products.
                </p>
                <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                  <Link to="/resources/documentation">
                    View Documentation
                    <ArrowRight size={16} />
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Featured Resources */}
        <section className="py-20 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold font-gilroy mb-12 text-center">Featured Resources</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {blogPosts.length > 0 ? (
                // Show actual blog posts
                blogPosts.map((post) => (
                  <div key={post.slug} className="bg-synthreo-black rounded-lg overflow-hidden border border-synthreo-blue-dark/20 hover:border-synthreo-blue-dark/50 transition-all">
                    <div className="h-48 bg-synthreo-blue-dark/20 relative">
                      {post.thumbnail ? (
                        <img 
                          src={post.thumbnail} 
                          alt={post.title} 
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="w-full h-full bg-synthreo-blue-dark/20 flex items-center justify-center">
                          <span className="text-synthreo-blue-medium text-lg">Synthreo</span>
                        </div>
                      )}
                    </div>
                    <div className="p-6">
                      <span className="text-xs font-medium text-synthreo-blue-medium uppercase tracking-wider">
                        {post.category || 'Blog'}
                      </span>
                      <h3 className="text-xl font-bold mt-2 mb-3 font-gilroy">{post.title}</h3>
                      <p className="text-gray-300 mb-4 font-poppins line-clamp-3">
                        {post.description}
                      </p>
                      <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                        <Link to={`/resources/blog/${post.slug}`}>
                          Read Article
                          <ArrowRight size={16} />
                        </Link>
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                // Fallback to the static content if no posts are available yet
                <>
                  {/* Resource 1 */}
                  <div className="bg-synthreo-black rounded-lg overflow-hidden border border-synthreo-blue-dark/20 hover:border-synthreo-blue-dark/50 transition-all">
                    <div className="h-48 bg-synthreo-blue-dark/20 relative">
                      <img 
                        src="https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80" 
                        alt="AI Implementation Guide" 
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="p-6">
                      <span className="text-xs font-medium text-synthreo-blue-medium uppercase tracking-wider">Guide</span>
                      <h3 className="text-xl font-bold mt-2 mb-3 font-gilroy">The Complete Guide to AI Implementation</h3>
                      <p className="text-gray-300 mb-4 font-poppins">
                        Learn how to successfully implement AI solutions in your organization with this comprehensive guide.
                      </p>
                      <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                        <Link to="/resources/blog">
                          Read Guide
                          <ArrowRight size={16} />
                        </Link>
                      </Button>
                    </div>
                  </div>
                  
                  {/* Resource 2 */}
                  <div className="bg-synthreo-black rounded-lg overflow-hidden border border-synthreo-blue-dark/20 hover:border-synthreo-blue-dark/50 transition-all">
                    <div className="h-48 bg-synthreo-blue-dark/20 relative">
                      <img 
                        src="https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80" 
                        alt="AI ROI Whitepaper" 
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="p-6">
                      <span className="text-xs font-medium text-synthreo-blue-medium uppercase tracking-wider">Whitepaper</span>
                      <h3 className="text-xl font-bold mt-2 mb-3 font-gilroy">Measuring ROI from AI Investments</h3>
                      <p className="text-gray-300 mb-4 font-poppins">
                        A detailed analysis of how to measure and maximize return on investment from AI initiatives.
                      </p>
                      <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                        <Link to="/resources/blog">
                          Download Whitepaper
                          <ArrowRight size={16} />
                        </Link>
                      </Button>
                    </div>
                  </div>
                  
                  {/* Resource 3 */}
                  <div className="bg-synthreo-black rounded-lg overflow-hidden border border-synthreo-blue-dark/20 hover:border-synthreo-blue-dark/50 transition-all">
                    <div className="h-48 bg-synthreo-blue-dark/20 relative">
                      <img 
                        src="https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80" 
                        alt="AI Case Study" 
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="p-6">
                      <span className="text-xs font-medium text-synthreo-blue-medium uppercase tracking-wider">Case Study</span>
                      <h3 className="text-xl font-bold mt-2 mb-3 font-gilroy">How Company X Increased Efficiency by 300%</h3>
                      <p className="text-gray-300 mb-4 font-poppins">
                        A case study on how a leading company transformed their operations with AI automation.
                      </p>
                      <Button asChild variant="link" className="text-synthreo-blue-medium p-0 flex items-center gap-2">
                        <Link to="/resources/case-studies">
                          Read Case Study
                          <ArrowRight size={16} />
                        </Link>
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
            
            <div className="mt-12 text-center">
              <Button asChild className="bg-synthreo-black-light text-white hover:bg-synthreo-black-dark">
                <Link to="/resources/blog">View All Resources</Link>
              </Button>
            </div>
          </div>
        </section>

        {/* Newsletter Section */}
        <section className="py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold font-gilroy mb-4">Stay Updated with AI Insights</h2>
            <p className="text-xl mb-8 text-gray-300 font-poppins">
              Subscribe to our newsletter to receive the latest AI resources, news, and updates.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <input 
                type="email" 
                placeholder="Enter your email" 
                className="px-4 py-3 bg-synthreo-black-light border border-synthreo-blue-dark/30 rounded-md focus:outline-none focus:ring-2 focus:ring-synthreo-blue-medium"
              />
              <Button className="btn-glow text-white">
                Subscribe
              </Button>
            </div>
          </div>
        </section>

        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default Resources;
