
import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const FinalCTA = () => {
  return (
    <section id="final-cta" className="py-24 bg-gradient-to-b from-synthreo-black-light to-synthreo-black relative">
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/2 top-1/2 h-[600px] w-[600px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-synthreo-blue-darkest/10 blur-3xl" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-8 font-gilroy">
            Ready to <span className="hero-gradient-full">Transform</span> Your Operations?
          </h2>
          <p className="text-xl md:text-2xl text-gray-300 mb-12 font-poppins">
            Join the growing number of businesses leveraging AI-powered digital labor to 
            achieve unprecedented efficiency, innovation, and growth.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-6">
            <Button
              className="btn-glow rounded-lg px-10 py-6 text-lg font-medium text-white shadow-lg h-auto w-full sm:w-auto font-gilroy"
              asChild
            >
              <Link to="/company/demo" className="flex items-center justify-center">
                Get Started Today
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </Button>
            <Button
              className="rounded-lg bg-synthreo-black-light px-10 py-6 text-lg font-medium text-white transition-colors hover:bg-synthreo-black-dark h-auto w-full sm:w-auto font-gilroy"
              asChild
            >
              <Link to="/solutions">Explore Solutions</Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinalCTA;
