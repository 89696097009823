
import React from 'react';
import { Building, Users, GraduationCap, LifeBuoy, Users2 } from 'lucide-react';
import MenuSection from './MenuSection';
import MenuLink from './MenuLink';

interface CompanySectionProps {
  onClose: () => void;
}

const CompanySection = ({ onClose }: CompanySectionProps) => {
  return (
    <MenuSection title="Company" route="/company" onClose={onClose}>
      <MenuLink 
        title="About Synthreo"
        route="/company/about" 
        icon={Building}
        description="Learn our story and mission"
        onClose={onClose}
      />
      <MenuLink 
        title="Leadership"
        route="/company/leadership" 
        icon={Users}
        description="Meet our leadership team"
        onClose={onClose}
      />
      <MenuLink 
        title="Careers"
        route="/company/careers" 
        icon={GraduationCap}
        description="Join our growing team"
        onClose={onClose}
      />
      <MenuLink 
        title="Partners"
        route="/partners" 
        icon={Users2}
        description="Our partner ecosystem"
        onClose={onClose}
      />
      <MenuLink 
        title="Support"
        route="/company/support" 
        icon={LifeBuoy}
        description="Get help with our products"
        onClose={onClose}
      />
    </MenuSection>
  );
};

export default CompanySection;
