
import React, { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import Contact from '@/components/Contact';
import SEOMetaTags from '@/components/SEOMetaTags';
import { useContentData } from '@/hooks/useContentData';
import { formatDistance, format } from 'date-fns';
import { Calendar, User, ArrowLeft, Tag, Share2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import ReactMarkdown from 'react-markdown';

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { item: post, loading, error } = useContentData('blog', { slug });
  
  // Handle 404
  useEffect(() => {
    if (!loading && !post && !error) {
      navigate('/resources/blog', { replace: true });
    }
  }, [loading, post, error, navigate]);
  
  // If loading or error
  if (loading) {
    return (
      <div className="min-h-screen bg-synthreo-black text-white flex flex-col">
        <Navbar />
        <main className="flex-grow pt-24 flex items-center justify-center">
          <p className="text-xl text-gray-300">Loading article...</p>
        </main>
        <Footer />
      </div>
    );
  }
  
  if (error || !post) {
    return (
      <div className="min-h-screen bg-synthreo-black text-white flex flex-col">
        <Navbar />
        <main className="flex-grow pt-24 flex items-center justify-center">
          <div className="text-center">
            <p className="text-xl text-red-400 mb-4">Article not found or error loading content.</p>
            <Button asChild variant="outline">
              <Link to="/resources/blog">
                <ArrowLeft size={16} className="mr-2" />
                Back to Blog
              </Link>
            </Button>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
  
  // Format dates
  const publishDate = post.date ? new Date(post.date) : new Date();
  const formattedDate = format(publishDate, 'MMMM dd, yyyy');
  const relativeDate = formatDistance(publishDate, new Date(), { addSuffix: true });
  
  // Prepare schema for SEO
  const blogPostSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post.title,
    "description": post.description,
    "image": post.thumbnail || "https://synthreo.ai/lovable-uploads/a8308147-3201-4990-a59f-dc654ad2ab8c.png",
    "datePublished": post.date,
    "dateModified": post.date,
    "author": {
      "@type": "Person",
      "name": post.author || "Synthreo Team"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Synthreo",
      "logo": {
        "@type": "ImageObject",
        "url": "https://synthreo.ai/favicon.svg"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://synthreo.ai/resources/blog/${slug}`
    },
    "keywords": post.tags?.join(", ") || "AI, ThreoAgents, Synthreo"
  };
  
  return (
    <div className="min-h-screen bg-synthreo-black text-white flex flex-col">
      <SEOMetaTags 
        title={`${post.title} | Synthreo Blog`}
        description={post.description}
        url={`https://synthreo.ai/resources/blog/${slug}`}
        canonical={`https://synthreo.ai/resources/blog/${slug}`}
        keywords={post.tags?.join(", ") || "AI, ThreoAgents, Synthreo"}
        schema={blogPostSchema}
        imageUrl={post.thumbnail}
      />
      <Navbar />
      <main className="flex-grow pt-24">
        {/* Article Header */}
        <section className="bg-synthreo-black-light/30 py-16 px-4">
          <div className="container mx-auto max-w-4xl">
            {/* Back to blog link */}
            <div className="mb-6">
              <Button asChild variant="ghost" size="sm" className="text-synthreo-blue-medium hover:text-synthreo-blue-light hover:bg-transparent p-0">
                <Link to="/resources/blog" className="flex items-center">
                  <ArrowLeft size={16} className="mr-2" />
                  Back to Blog
                </Link>
              </Button>
            </div>
            
            {/* Category */}
            {post.category && (
              <div className="mb-4">
                <span className="bg-synthreo-blue-dark/20 text-synthreo-blue-medium px-3 py-1 rounded-full text-xs font-medium">
                  {post.category}
                </span>
              </div>
            )}
            
            {/* Title */}
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6">{post.title}</h1>
            
            {/* Meta - Author, Date */}
            <div className="flex flex-wrap items-center gap-6 mb-8">
              <div className="flex items-center">
                {post.authorImage ? (
                  <img 
                    src={post.authorImage} 
                    alt={post.author || 'Author'} 
                    className="w-10 h-10 rounded-full mr-3"
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-synthreo-blue-dark/30 flex items-center justify-center mr-3">
                    <User size={20} className="text-synthreo-blue-medium" />
                  </div>
                )}
                <div>
                  <p className="font-medium">{post.author || 'Synthreo Team'}</p>
                  <div className="flex items-center text-sm text-gray-400">
                    <Calendar size={14} className="mr-1" />
                    <span title={formattedDate}>{relativeDate}</span>
                  </div>
                </div>
              </div>
              
              {/* Social Share */}
              <div className="ml-auto">
                <Button variant="outline" size="sm" className="text-gray-300 border-gray-600">
                  <Share2 size={16} className="mr-2" />
                  Share
                </Button>
              </div>
            </div>
            
            {/* Featured Image */}
            {post.thumbnail && (
              <div className="mb-8">
                <img 
                  src={post.thumbnail} 
                  alt={post.title} 
                  className="w-full h-auto rounded-lg"
                />
              </div>
            )}
          </div>
        </section>
        
        {/* Article Content */}
        <section className="py-12 px-4">
          <div className="container mx-auto max-w-3xl">
            <div className="prose prose-lg prose-invert max-w-none">
              <ReactMarkdown>{post.content}</ReactMarkdown>
            </div>
            
            {/* Tags */}
            {post.tags && post.tags.length > 0 && (
              <div className="mt-12 pt-8 border-t border-gray-800">
                <div className="flex flex-wrap items-center gap-2">
                  <Tag size={16} className="text-gray-400 mr-2" />
                  {post.tags.map(tag => (
                    <Link 
                      key={tag} 
                      to={`/resources/blog?tag=${tag}`}
                      className="bg-synthreo-black-light px-3 py-1 rounded-full text-xs text-gray-300 hover:bg-synthreo-blue-dark/20 hover:text-synthreo-blue-medium"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default BlogPost;
