
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { toast } from 'sonner';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
  onError?: (error: Error, errorInfo?: ErrorInfo) => void;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
  errorCount: number;
  lastErrorTimestamp: number;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null,
      errorCount: 0,
      lastErrorTimestamp: 0
    };
  }

  static getDerivedStateFromError(error: Error): Partial<State> {
    console.error('[ErrorBoundary] Caught error in getDerivedStateFromError:', error);
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const now = Date.now();
    const timeSinceLastError = now - this.state.lastErrorTimestamp;
    
    // Capture the error information for reporting
    this.setState(prevState => ({
      errorInfo,
      errorCount: prevState.errorCount + 1,
      lastErrorTimestamp: now
    }));
    
    // Enhanced error logging with more context
    console.error('[ErrorBoundary] React error boundary caught an error:', error);
    console.error('[ErrorBoundary] Component stack:', errorInfo.componentStack);
    console.error('[ErrorBoundary] Current route:', window.location.pathname);
    console.error('[ErrorBoundary] Error count in current session:', this.state.errorCount + 1);
    console.error('[ErrorBoundary] Time since last error:', timeSinceLastError, 'ms');
    console.error('[ErrorBoundary] User agent:', navigator.userAgent);
    console.error('[ErrorBoundary] Window dimensions:', `${window.innerWidth}x${window.innerHeight}`);
    console.error('[ErrorBoundary] Document readyState:', document.readyState);
    
    // Additional checks for blank screen errors
    if (!error.message || error.message === 'Script error.') {
      console.error('[ErrorBoundary] Detected generic script error, likely cross-origin or resource loading issue');
      // Try to recover from blank screen errors automatically
      setTimeout(() => {
        if (this.state.hasError) {
          console.log('[ErrorBoundary] Attempting auto-recovery from blank screen error');
          this.setState({ hasError: false });
        }
      }, 2000);
    }
    
    // Call onError prop if provided
    if (this.props.onError) {
      try {
        this.props.onError(error, errorInfo);
      } catch (callbackError) {
        console.error('[ErrorBoundary] Error in onError callback:', callbackError);
      }
    }
    
    // Show toast notification for less severe errors
    if (this.state.errorCount < 3) {
      toast.error('An error occurred. We\'re working on fixing it.');
    }
    
    // Send to analytics or monitoring service if available
    if (typeof window.gtag === 'function') {
      try {
        window.gtag('event', 'exception', {
          'description': `${error.name}: ${error.message}`,
          'fatal': true,
          'errorCount': this.state.errorCount + 1,
          'componentStack': errorInfo.componentStack.slice(0, 500), // Truncate for analytics
          'path': window.location.pathname,
          'timeSinceLastError': timeSinceLastError
        });
      } catch (e) {
        console.error('[ErrorBoundary] Failed to report error to analytics', e);
      }
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.state.hasError && prevProps !== this.props) {
      console.log('[ErrorBoundary] Error boundary reset after prop change');
      this.setState({ hasError: false, error: null, errorInfo: null });
    }
  }

  handleRecoveryClick = (): void => {
    console.log('[ErrorBoundary] User-initiated error recovery attempt');
    this.setState({ hasError: false, error: null, errorInfo: null });
    
    // Force a reload if we've had multiple errors in succession
    if (this.state.errorCount > 2) {
      console.log('[ErrorBoundary] Multiple errors detected, performing full page reload');
      window.location.href = '/'; // Navigate to home page for a fresh start
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Check if it's a blank screen or script error
      const isGenericError = !this.state.error?.message || this.state.error.message === 'Script error.';
      
      // You can render any custom fallback UI
      return this.props.fallback || (
        <div className="flex flex-col items-center justify-center min-h-screen bg-synthreo-black p-4">
          <div className="bg-synthreo-black-light p-6 rounded-lg max-w-md w-full">
            <h2 className="text-xl font-bold text-red-500 mb-4">Something went wrong</h2>
            <p className="text-white mb-4">
              {isGenericError 
                ? "We've encountered a loading error. This might be due to a temporary network issue."
                : "We've encountered an error. Please try refreshing the page."}
            </p>
            <div className="mb-4 p-3 bg-synthreo-black-dark rounded">
              <p className="text-sm text-gray-300 mb-1">Error details:</p>
              <pre className="bg-synthreo-black-dark rounded text-sm text-gray-300 overflow-auto max-h-40">
                {this.state.error?.toString() || 'Unknown error'}
              </pre>
            </div>
            {this.state.errorInfo && (
              <details className="mt-3 mb-4">
                <summary className="cursor-pointer text-sm text-gray-400 hover:text-gray-300">
                  Component Stack Details
                </summary>
                <pre className="bg-synthreo-black-dark p-3 mt-2 rounded text-xs text-gray-300 overflow-auto max-h-40">
                  {this.state.errorInfo.componentStack}
                </pre>
              </details>
            )}
            <div className="flex flex-wrap gap-3 mt-4">
              <button
                onClick={() => window.location.reload()}
                className="px-4 py-2 bg-synthreo-blue-medium text-white rounded hover:bg-synthreo-blue-dark transition-colors"
              >
                Refresh Page
              </button>
              <button
                onClick={this.handleRecoveryClick}
                className="px-4 py-2 bg-synthreo-black-dark text-white rounded hover:bg-synthreo-black border border-gray-700 transition-colors"
              >
                Try Again
              </button>
              <button
                onClick={() => { window.location.href = '/'; }}
                className="px-4 py-2 bg-synthreo-black-dark text-white rounded hover:bg-synthreo-black border border-gray-700 transition-colors"
              >
                Go Home
              </button>
              {isGenericError && (
                <button
                  onClick={() => {
                    // Force reload without cache for resource loading issues
                    window.location.reload();
                  }}
                  className="px-4 py-2 bg-synthreo-black-dark text-white rounded hover:bg-synthreo-black border border-gray-700 transition-colors"
                >
                  Hard Refresh
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
