
import React from 'react';
import { ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const Hero = () => {
  const scrollToProblemStatement = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const element = document.getElementById('problem-statement');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section 
      id="home" 
      className="relative min-h-screen overflow-hidden flex flex-col items-center justify-center"
    >
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/2 top-1/2 h-[500px] w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-synthreo-blue-darkest/5 blur-3xl" />
        <div className="absolute left-1/4 top-1/3 h-[300px] w-[300px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-synthreo-blue-dark/10 blur-2xl animate-pulse-glow" />
        <div className="absolute right-1/4 bottom-1/3 h-[350px] w-[350px] translate-x-1/4 translate-y-1/4 rounded-full bg-synthreo-blue-medium/10 blur-2xl animate-pulse-glow" />
      </div>
      
      <div className="container relative z-10 mx-auto flex flex-col items-center px-4 py-8">
        <h1 className="mb-6 text-6xl font-bold leading-tight md:text-7xl lg:text-8xl text-center font-gilroy">
          <span className="hero-gradient-full">
            Your AI Workforce
            <br />
            Partner
          </span>
        </h1>
        
        <p className="mb-10 max-w-3xl text-xl text-gray-300 md:text-2xl text-center font-poppins">
          Synthreo empowers businesses to streamline operations, reduce costs, and drive growth through intelligent AI agents that transform how you work.
        </p>
        
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0 mb-12">
          <Button
            className="btn-glow rounded-lg px-10 py-4 text-base font-medium text-white shadow-lg h-auto font-gilroy"
            asChild
          >
            <Link to="/solutions">Explore Solutions</Link>
          </Button>
          <Button
            className="rounded-lg bg-synthreo-black-light px-10 py-4 text-base font-medium text-white transition-colors hover:bg-synthreo-black-dark h-auto font-gilroy"
            asChild
          >
            <Link to="/company/demo">Book a Demo</Link>
          </Button>
        </div>
      </div>
      
      <div className="absolute bottom-8 flex justify-center w-full">
        <a href="#problem-statement" onClick={scrollToProblemStatement} className="flex flex-col items-center group">
          <span className="text-synthreo-blue-medium mb-2 text-sm font-medium transition-colors group-hover:text-synthreo-blue-light font-poppins">Discover More</span>
          <div className="bg-synthreo-blue-darkest/30 p-2 rounded-full transition-transform duration-300 group-hover:translate-y-1 blue-glow">
            <ChevronDown className="h-8 w-8 text-synthreo-blue-light" strokeWidth={1.5} />
          </div>
        </a>
      </div>
    </section>
  );
};

export default Hero;
