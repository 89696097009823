
import React from 'react';
import { Control } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { FormValues } from './contactFormSchema';

interface FormMessageFieldProps {
  control: Control<FormValues>;
}

const FormMessageField = ({ control }: FormMessageFieldProps) => {
  return (
    <FormField
      control={control}
      name="message"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-medium text-gray-300">Your Message</FormLabel>
          <FormControl>
            <Textarea
              {...field}
              name="message"
              rows={4}
              className="w-full rounded-md border border-synthreo-blue-dark/20 bg-synthreo-black-light p-3 text-white focus:border-synthreo-blue-medium focus:outline-none focus:ring-1 focus:ring-synthreo-blue-dark"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormMessageField;
