
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import Footer from '../../components/Footer';
import { Shield, Check } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Navbar />
      
      <main className="flex-grow pt-24 pb-16 w-full">
        <div className="container max-w-4xl mx-auto px-4 sm:px-6">
          <div className="flex items-center gap-2 mb-8">
            <Shield className="h-6 w-6 text-synthreo-blue" />
            <h1 className="text-3xl font-bold tracking-tight">Privacy Policy</h1>
          </div>
          
          <div className="prose prose-blue max-w-none">
            <p className="text-lg text-muted-foreground mb-8">
              Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
            </p>
            
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
              <p>
                At Synthreo, we value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website or use our services.
              </p>
              <p>
                We use various technologies to collect and store information when you visit, interact with, or use our services. This may include using cookies or similar technologies to identify your browser or device.
              </p>
            </section>
            
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
              <p>We collect several types of information from and about users of our website, including:</p>
              <ul className="list-disc pl-6 space-y-2 mt-4">
                <li>Information you provide to us directly (name, email, company information)</li>
                <li>Information we collect automatically when you visit our website</li>
                <li>Information we receive from third parties</li>
              </ul>
            </section>
            
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Analytics Tools We Use</h2>
              <p>
                We use various analytics tools to help us understand how visitors interact with our website. This helps us improve our services and provide a better user experience. The analytics tools we currently use include:
              </p>
              
              <div className="mt-6 space-y-4">
                <div className="flex items-start gap-3">
                  <div className="mt-1 bg-synthreo-blue-lightest p-1 rounded">
                    <Check className="h-4 w-4 text-synthreo-blue" />
                  </div>
                  <div>
                    <h3 className="font-medium">Google Analytics 4</h3>
                    <p className="text-muted-foreground">
                      We use Google Analytics 4 to collect anonymous information about how visitors use our website. Google Analytics uses cookies to track visitor interactions. 
                      <a 
                        href="https://policies.google.com/privacy" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-synthreo-blue hover:underline ml-1"
                      >
                        Google Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <div className="mt-1 bg-synthreo-blue-lightest p-1 rounded">
                    <Check className="h-4 w-4 text-synthreo-blue" />
                  </div>
                  <div>
                    <h3 className="font-medium">Google Tag Manager</h3>
                    <p className="text-muted-foreground">
                      We use Google Tag Manager to manage various JavaScript tags used for tracking and analytics.
                      <a 
                        href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-synthreo-blue hover:underline ml-1"
                      >
                        GTM Use Policy
                      </a>
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <div className="mt-1 bg-synthreo-blue-lightest p-1 rounded">
                    <Check className="h-4 w-4 text-synthreo-blue" />
                  </div>
                  <div>
                    <h3 className="font-medium">Microsoft Clarity</h3>
                    <p className="text-muted-foreground">
                      Microsoft Clarity helps us understand how users interact with our website through session recordings and heatmaps.
                      <a 
                        href="https://learn.microsoft.com/en-us/clarity/setup-and-installation/privacy-policy" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-synthreo-blue hover:underline ml-1"
                      >
                        Clarity Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <div className="mt-1 bg-synthreo-blue-lightest p-1 rounded">
                    <Check className="h-4 w-4 text-synthreo-blue" />
                  </div>
                  <div>
                    <h3 className="font-medium">Koala Analytics</h3>
                    <p className="text-muted-foreground">
                      We use Koala to help us understand user behavior and improve our website experience.
                      <a 
                        href="https://www.getkoala.com/legal/privacy" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-synthreo-blue hover:underline ml-1"
                      >
                        Koala Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Cookies</h2>
              <p>
                We use cookies and similar tracking technologies to track activity on our website and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website.
              </p>
              <p className="mt-4">
                Our website uses the following types of cookies:
              </p>
              <ul className="list-disc pl-6 space-y-2 mt-4">
                <li><strong>Essential Cookies:</strong> Required for the website to function and cannot be switched off.</li>
                <li><strong>Analytics Cookies:</strong> Help us understand how visitors interact with our website.</li>
                <li><strong>Marketing Cookies:</strong> Used to track visitors across websites to display relevant advertisements.</li>
              </ul>
              <p className="mt-4">
                You can manage your cookie preferences through our Cookie Consent banner or by accessing our 
                <span 
                  className="text-synthreo-blue hover:underline cursor-pointer ml-1"
                  onClick={() => {
                    const event = new CustomEvent('openCookieSettings');
                    window.dispatchEvent(event);
                  }}
                >
                  Cookie Settings
                </span>.
              </p>
            </section>
            
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
              <p>
                We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure.
              </p>
            </section>
            
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Your Data Protection Rights</h2>
              <p>Depending on your location, you may have the following rights:</p>
              <ul className="list-disc pl-6 space-y-2 mt-4">
                <li>The right to access information we hold about you</li>
                <li>The right to request correction of any inaccurate information</li>
                <li>The right to request deletion of your personal information</li>
                <li>The right to object to processing of your personal information</li>
                <li>The right to data portability</li>
                <li>The right to withdraw consent at any time</li>
              </ul>
            </section>
            
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
              </p>
            </section>
            
            <section>
              <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, please contact us at{" "}
                <a href="mailto:privacy@synthreo.ai" className="text-synthreo-blue hover:underline">
                  privacy@synthreo.ai
                </a>
              </p>
              <div className="mt-8">
                <Link to="/company/contact" className="text-synthreo-blue hover:underline">
                  Go to our contact page
                </Link>
              </div>
            </section>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
