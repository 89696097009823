
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { ensureClarityAvailable } from './utils/analytics/clarity';
import { initializeGTM } from './utils/analytics/gtm';
import { recoverFromBlankScreen, createFallbackUI } from './utils/botx/recovery';
import { initializeBotx } from './utils/botx';
import { patchJSONParse } from './utils/jsonParsePatch';
import { setupGlobalErrorHandlers } from './utils/errorHandling';
import { mountApp } from './utils/appMounting';

// Create a global error variable to track initialization errors
window.__synthreoInitErrors = [];

// Implement emergency recovery function
function emergencyRecover() {
  console.log("Emergency recovery triggered");
  
  try {
    // Force remove loading class first thing
    document.documentElement.classList.remove('js-loading');
    
    // Make sure the root is visible
    const root = document.getElementById('root');
    if (root) {
      root.style.visibility = 'visible';
      root.style.display = 'block';
    }
    
    // Make sure body and document are visible
    document.body.style.visibility = 'visible';
    document.documentElement.style.visibility = 'visible';
    
    // Create fallback UI if needed
    if (!root || !root.firstChild) {
      createFallbackUI();
    }
  } catch (e) {
    console.error("Emergency recovery failed:", e);
  }
}

// Add this to window for debugging
window.emergencyRecover = emergencyRecover;

// Setup global error handlers first to catch any initialization errors
try {
  setupGlobalErrorHandlers();
} catch (e) {
  console.error("Failed to setup global error handlers:", e);
  window.__synthreoInitErrors.push("ErrorHandlers");
}

// Safety timeout to ensure recovery from blank screens - these run no matter what
setTimeout(emergencyRecover, 1000);
setTimeout(emergencyRecover, 3000);
setTimeout(emergencyRecover, 6000);

// Initialize Clarity with proper protection before anything else
try {
  // Pre-initialize window.clarity to prevent 'v' property errors
  if (typeof window.clarity === 'undefined') {
    // Create a basic clarity stub
    window.clarity = function(...args: any[]) {
      console.log('Clarity stub called:', args);
    } as any;
    (window.clarity as any).v = {};
    (window.clarity as any).q = [];
    (window.clarity as any).push = function(args: any) {
      if ((window.clarity as any).q) (window.clarity as any).q.push(args);
    };
    console.log('Pre-initialized Clarity stub in main.tsx');
  }
  
  // Now do the full initialization
  ensureClarityAvailable();
} catch (e) {
  console.error('Failed to pre-initialize Clarity:', e);
  window.__synthreoInitErrors.push("Clarity");
  // Don't let this stop the app
}

// Try to init other services but don't let failures block rendering
try {
  initializeGTM();
} catch (e) {
  console.error('Error initializing GTM:', e);
  window.__synthreoInitErrors.push("GTM");
}

try {
  initializeBotx();
} catch (e) {
  console.error('Error initializing Botx:', e);
  window.__synthreoInitErrors.push("Botx");
}

// Patch JSON.parse in the main app to handle malformed JSON
try {
  patchJSONParse();
} catch (e) {
  console.error('Error patching JSON.parse:', e);
  window.__synthreoInitErrors.push("JSONParse");
}

// Force document and root to be visible before mounting
try {
  document.documentElement.classList.remove('js-loading');
  const root = document.getElementById('root');
  if (root) {
    root.style.visibility = 'visible';
    root.style.display = 'block';
  }
} catch (e) {
  console.error('Error making elements visible:', e);
}

// Mount the app with proper error handling
try {
  console.log("Mounting application...");
  mountApp();
  console.log("Application mounted successfully");
} catch (e) {
  console.error('Critical error mounting app:', e);
  window.__synthreoInitErrors.push("AppMount");
  // Force recovery on critical mount failure
  setTimeout(emergencyRecover, 100);
}

// Final safety timeout for recovery
setTimeout(recoverFromBlankScreen, 2000);
