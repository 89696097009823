
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  hasUserMadeConsentChoice, 
  getCurrentConsentStatus
} from '../utils/cookieConsent';
import { initializeAnalytics } from '../utils/analytics';
import CookieDialog from './cookies/CookieDialog';
import CookieBanner from './cookies/CookieBanner';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);
  const [analyticsChecked, setAnalyticsChecked] = useState(true);
  const [marketingChecked, setMarketingChecked] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasUserMadeConsentChoice()) {
      setShowBanner(true);
    } else {
      const { analyticsConsent, marketingConsent } = getCurrentConsentStatus();
      setAnalyticsChecked(analyticsConsent);
      setMarketingChecked(marketingConsent);
      initializeAnalytics();
    }

    const handleSettingsEvent = () => {
      const { analyticsConsent, marketingConsent } = getCurrentConsentStatus();
      setAnalyticsChecked(analyticsConsent);
      setMarketingChecked(marketingConsent);
      setOpen(true);
    };

    window.addEventListener('openCookieSettings', handleSettingsEvent);

    return () => {
      window.removeEventListener('openCookieSettings', handleSettingsEvent);
    };
  }, []);

  const handleOpenSettings = () => {
    setOpen(true);
    setShowBanner(false);
  };

  const handleOpenPrivacyPolicy = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpen(false);
    setShowBanner(false);
    navigate('/company/privacy-policy');
  };

  return (
    <>
      <CookieDialog 
        open={open}
        setOpen={setOpen}
        analyticsChecked={analyticsChecked}
        setAnalyticsChecked={setAnalyticsChecked}
        marketingChecked={marketingChecked}
        setMarketingChecked={setMarketingChecked}
        setShowBanner={setShowBanner}
      />

      <CookieBanner 
        showBanner={showBanner}
        setShowBanner={setShowBanner}
        handleOpenSettings={handleOpenSettings}
        handleOpenPrivacyPolicy={handleOpenPrivacyPolicy}
      />
    </>
  );
};

export default CookieConsent;
