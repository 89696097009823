import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from '../App';
import ErrorBoundary from '../components/ErrorBoundary';
import { Toaster } from '../components/ui/sonner';
import { toast } from 'sonner';
import { recoverFromBlankScreen, createFallbackUI } from './botx/recovery';

/**
 * Helper function to safely mount the application with retries
 */
export const mountApp = (retryCount = 0) => {
  const MAX_RETRIES = 3;
  const rootElement = document.getElementById('root');
  
  // Force root to be visible immediately
  if (rootElement) {
    rootElement.style.visibility = 'visible';
    rootElement.style.display = 'block';
  }
  
  // Remove loading class early
  document.documentElement.classList.remove('js-loading');
  
  if (!rootElement) {
    console.error('Root element not found, cannot mount React application');
    if (retryCount < MAX_RETRIES) {
      console.log(`Retrying mount in 500ms (attempt ${retryCount + 1} of ${MAX_RETRIES})...`);
      setTimeout(() => mountApp(retryCount + 1), 500);
    } else {
      createFallbackUI();
    }
    return;
  }
  
  try {
    // Create the React root
    const root = ReactDOM.createRoot(rootElement);
    
    // Create a Helmet context to avoid nested contexts
    const helmetContext = {};
    
    // Render first with minimal providers to ensure something shows on screen
    try {
      console.log('Rendering application with basic providers');
      root.render(<App />);
      
      // Then after a short delay, render with the full set of providers
      setTimeout(() => {
        try {
          console.log('Rendering application with all providers');
          root.render(
            <ErrorBoundary>
              <HelmetProvider context={helmetContext}>
                <App />
                <Toaster position="top-right" />
              </HelmetProvider>
            </ErrorBoundary>
          );
          console.log('React application successfully mounted with all providers');
        } catch (renderError) {
          console.error('Error during full render:', renderError);
          // Keep the basic render that already succeeded
        }
      }, 50);
      
      console.log('Initial React application mounted');
      
      // Still trigger recovery just to be safe
      setTimeout(() => recoverFromBlankScreen(), 1000);
    } catch (renderError) {
      console.error('Error during React rendering:', renderError);
      toast.error('Application encountered an error. Attempting recovery...');
      
      // Fall back to a simpler render without any providers if the basic render fails
      try {
        root.render(
          <ErrorBoundary>
            <div className="App">Something went wrong. Please reload the page.</div>
          </ErrorBoundary>
        );
        console.log('Application recovered with minimal rendering');
      } catch (fallbackError) {
        console.error('Critical error in fallback rendering:', fallbackError);
        createFallbackUI();
      }
    }
  } catch (error) {
    console.error('Failed to initialize React application:', error);
    
    if (retryCount < MAX_RETRIES) {
      console.log(`Retrying mount in 1000ms (attempt ${retryCount + 1} of ${MAX_RETRIES})...`);
      setTimeout(() => mountApp(retryCount + 1), 1000);
    } else {
      createFallbackUI();
    }
  }
};
