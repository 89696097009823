
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import AppRoutes from './routes/AppRoutes';
import { AuthProvider } from './context/AuthContext';
import { initializeBotx } from './utils/botx';
import { recoverFromBlankScreen } from './utils/botx/recovery';
import { ensureClarityAvailable } from './utils/analytics/clarity';
import ErrorBoundary from './components/ErrorBoundary';
import { Toaster } from 'sonner';
import './App.css';

function App() {
  const [appLoaded, setAppLoaded] = useState(false);

  // Initialize Botx as early as possible in the App lifecycle
  useEffect(() => {
    try {
      // Apply more aggressive recovery measures - making sure Clarity is properly initialized
      // before other scripts to prevent the v property error
      ensureClarityAvailable();
      initializeBotx();
      
      // Initial recovery check
      recoverFromBlankScreen(true);
      
      // Staggered recovery attempts to ensure UI is visible
      const initialRecovery = setTimeout(() => recoverFromBlankScreen(true), 300);
      const secondaryRecovery = setTimeout(() => recoverFromBlankScreen(true), 1000);
      const finalRecovery = setTimeout(() => recoverFromBlankScreen(true), 2500);
      
      // Mark app as loaded
      setAppLoaded(true);
      
      return () => {
        clearTimeout(initialRecovery);
        clearTimeout(secondaryRecovery);
        clearTimeout(finalRecovery);
      };
    } catch (e) {
      console.error("Error initializing external scripts in App component:", e);
      // Force recovery on initialization error and still mark app as loaded
      recoverFromBlankScreen(true);
      setAppLoaded(true);
    }
  }, []);

  // Add a second useEffect to ensure document root is visible
  useEffect(() => {
    // This helps ensure the app is visible even if there are errors in external scripts
    const makeVisible = () => {
      const root = document.getElementById('root');
      if (root) {
        root.style.visibility = 'visible';
        root.style.display = 'block';
      }
      
      // Force body and html to be visible too
      document.body.style.visibility = 'visible';
      document.documentElement.style.visibility = 'visible';
      document.documentElement.classList.remove('js-loading');
    };
    
    // Execute immediately and then after a short delay
    makeVisible();
    setTimeout(makeVisible, 500);
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </AuthProvider>
        <Toaster position="top-right" />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
