
import React from 'react';
import { Control } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { FormValues } from './signupFormSchema';

interface FormDesiredOutcomeFieldProps {
  control: Control<FormValues>;
}

const FormDesiredOutcomeField = ({ control }: FormDesiredOutcomeFieldProps) => {
  return (
    <FormField
      control={control}
      name="desiredOutcome"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-medium text-gray-300">What outcome are you trying to achieve with our product?</FormLabel>
          <FormControl>
            <Textarea
              {...field}
              name="desiredOutcome"
              placeholder="Tell us about your goals and what you hope to accomplish with Synthreo..."
              className="min-h-[120px] w-full rounded-md border border-synthreo-blue-dark/20 bg-synthreo-black-light p-3 text-white focus:border-synthreo-blue-medium focus:outline-none focus:ring-1 focus:ring-synthreo-blue-dark"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormDesiredOutcomeField;
