
import React from 'react';
import { Control } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FormValues } from './contactFormSchema';

interface FormNameFieldProps {
  control: Control<FormValues>;
}

const FormNameField = ({ control }: FormNameFieldProps) => {
  return (
    <FormField
      control={control}
      name="name"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-medium text-gray-300">Your Name</FormLabel>
          <FormControl>
            <Input
              {...field}
              name="name"
              className="w-full rounded-md border border-synthreo-blue-dark/20 bg-synthreo-black-light p-3 text-white focus:border-synthreo-blue-medium focus:outline-none focus:ring-1 focus:ring-synthreo-blue-dark"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormNameField;
