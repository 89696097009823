
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';

const Leadership = () => {
  useEffect(() => {
    document.title = 'Leadership | Synthreo';
  }, []);

  const openLinkedIn = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow">
        <section className="py-28 md:py-32 relative">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
                Our <span className="text-gradient">Leadership</span> Team
              </h1>
              <p className="text-lg text-gray-300 leading-relaxed">
                Meet the team driving Synthreo's mission to transform business through intelligent AI solutions.
              </p>
            </div>
          </div>
        </section>
        
        <section className="py-16 relative">
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Vince Kent */}
              <div 
                className="text-center card-glow p-6 rounded-lg transition-transform duration-300 hover:-translate-y-2 cursor-pointer"
                onClick={() => openLinkedIn('https://www.linkedin.com/in/vincekent/')}
              >
                <div className="w-32 h-32 mx-auto rounded-full overflow-hidden mb-4 border-2 border-synthreo-blue-medium/20">
                  <img 
                    src="/lovable-uploads/8b500154-19dc-4472-ba68-092fe9b9a8cc.png" 
                    alt="Vince Kent" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xl font-semibold">Vince Kent</h3>
                <p className="text-synthreo-blue-medium mb-2">Founder and Board Chair</p>
                <p className="text-gray-400 text-sm">
                  Vince leads innovation and growth, leveraging AI and automation to drive operational efficiency and create measurable business outcomes.
                </p>
              </div>
              
              {/* Callen Sapien */}
              <div 
                className="text-center card-glow p-6 rounded-lg transition-transform duration-300 hover:-translate-y-2 cursor-pointer"
                onClick={() => openLinkedIn('https://www.linkedin.com/in/callensapien/')}
              >
                <div className="w-32 h-32 mx-auto rounded-full overflow-hidden mb-4 border-2 border-synthreo-blue-medium/20">
                  <img 
                    src="/lovable-uploads/bd77b271-44e4-49d3-bb79-6d2125110722.png" 
                    alt="Callen Sapien" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xl font-semibold">Callen Sapien</h3>
                <p className="text-synthreo-blue-medium mb-2">Board Advisor</p>
                <p className="text-gray-400 text-sm">
                  Callen transforms customer feedback into actionable strategies, driving product adoption, satisfaction, and intuitive, effective AI solutions.
                </p>
              </div>
              
              {/* Gregg Lalle */}
              <div 
                className="text-center card-glow p-6 rounded-lg transition-transform duration-300 hover:-translate-y-2 cursor-pointer"
                onClick={() => openLinkedIn('https://www.linkedin.com/in/gregg-lalle-795889/')}
              >
                <div className="w-32 h-32 mx-auto rounded-full overflow-hidden mb-4 border-2 border-synthreo-blue-medium/20">
                  <img 
                    src="/lovable-uploads/8a7c589a-1508-4816-b1d6-ecefe1aa5add.png" 
                    alt="Gregg Lalle" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xl font-semibold">Gregg Lalle</h3>
                <p className="text-synthreo-blue-medium mb-2">President</p>
                <p className="text-gray-400 text-sm">
                  Gregg drives MSP growth with go-to-market strategies, helping partners scale AI services, boost efficiency, and strengthen market position.
                </p>
              </div>
              
              {/* Ivan Sivak */}
              <div 
                className="text-center card-glow p-6 rounded-lg transition-transform duration-300 hover:-translate-y-2 cursor-pointer"
                onClick={() => openLinkedIn('https://www.linkedin.com/in/ivan-sivak/')}
              >
                <div className="w-32 h-32 mx-auto rounded-full overflow-hidden mb-4 border-2 border-synthreo-blue-medium/20">
                  <img 
                    src="/lovable-uploads/e14dc970-1c12-4f5a-b671-92a85828e303.png" 
                    alt="Ivan Sivak" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xl font-semibold">Ivan Sivak</h3>
                <p className="text-synthreo-blue-medium mb-2">Co-Founder and Chief Technology Officer</p>
                <p className="text-gray-400 text-sm">
                  Ivan leads AI research and development, blending neuroscience insights with technology to create cutting-edge, scalable AI solutions.
                </p>
              </div>
              
              {/* Jeff Hirth */}
              <div 
                className="text-center card-glow p-6 rounded-lg transition-transform duration-300 hover:-translate-y-2 cursor-pointer"
                onClick={() => openLinkedIn('https://www.linkedin.com/in/jyhirth/')}
              >
                <div className="w-32 h-32 mx-auto rounded-full overflow-hidden mb-4 border-2 border-synthreo-blue-medium/20">
                  <img 
                    src="/lovable-uploads/7aac8cea-20cd-445d-abd0-a0e1110f4357.png" 
                    alt="Jeff Hirth" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xl font-semibold">Jeff Hirth</h3>
                <p className="text-synthreo-blue-medium mb-2">Chief Experience Officer</p>
                <p className="text-gray-400 text-sm">
                  Jeff streamlines MSP operations using AI, reducing complexity, boosting efficiency, and transforming service delivery for scalable growth.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Leadership;
