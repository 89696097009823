
import React from 'react';
import { useIsMobile } from '@/hooks/use-mobile';

const CompanyStory = () => {
  const isMobile = useIsMobile();
  
  return (
    <section className="py-12 md:py-16 relative">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-8 md:gap-12 items-center">
          <div>
            <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 font-gilroy">
              The <span className="text-synthreo-blue-medium">Synthreo</span> Story
            </h2>
            <p className="text-sm md:text-base text-gray-300 mb-4">
              Synthreo was born from a simple observation: despite rapid advancements in AI technology, 
              most businesses still struggled to effectively integrate these tools into their workflows.
            </p>
            <p className="text-sm md:text-base text-gray-300 mb-4">
              Our founders, with backgrounds spanning AI research, enterprise software, and business operations, 
              came together with a shared vision: to create AI solutions that truly understand how businesses 
              operate and seamlessly augment human capabilities rather than attempting to replace them.
            </p>
            <p className="text-sm md:text-base text-gray-300">
              The name "Synthreo" combines "synthesis" and "theo" (meaning divine or theoretical) - reflecting 
              our approach to blending cutting-edge AI theory with practical, real-world applications.
            </p>
          </div>
          <div className="card-glow p-4 md:p-6 rounded-lg mt-6 md:mt-0">
            <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden bg-synthreo-black-light">
              <img 
                src="/lovable-uploads/102549ce-bf71-430d-b3bd-210b82d7ea67.png" 
                alt="Synthreo Team" 
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyStory;
