
import React from 'react';
import CookieSettingsTooltip from './cookies/CookieSettingsTooltip';

/**
 * Component that renders the cookie settings button in the corner of the screen
 */
const CookieSettings = () => {
  return (
    <div className="fixed bottom-4 left-4 z-50">
      <CookieSettingsTooltip />
    </div>
  );
};

export default CookieSettings;
