import React from 'react';
import { CalendarDays, Code, Rocket, Users, Award, Zap, ChevronRight } from 'lucide-react';
import { useIsMobile } from '@/hooks/use-mobile';

interface TimelineItemProps {
  year: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  isMobile?: boolean;
}

const TimelineItem = ({ year, title, description, icon, isMobile }: TimelineItemProps) => {
  return (
    <div className={`timeline-item ${isMobile ? 'w-full' : 'flex-shrink-0 w-72 md:w-64 lg:w-56'} p-3`}>
      <div className="rounded-lg blue-glow p-4 bg-synthreo-black-light border border-synthreo-blue-dark/10 group hover:border-synthreo-blue-dark/40 transition-all duration-300 h-full flex flex-col">
        <div className="flex items-center mb-3">
          <div className="mr-3 flex h-7 w-7 md:h-8 md:w-8 items-center justify-center rounded-full bg-synthreo-blue-darkest/30 group-hover:bg-synthreo-blue-darkest/50 transition-colors duration-300">
            {icon}
          </div>
          <span className="text-synthreo-blue-light font-semibold text-sm md:text-base">{year}</span>
        </div>
        <h4 className="text-sm md:text-base font-semibold mb-2 group-hover:text-synthreo-blue-light transition-colors duration-300">{title}</h4>
        <p className="text-xs md:text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-300 flex-grow">{description}</p>
      </div>
    </div>
  );
};

const TimelineSection = () => {
  const isMobile = useIsMobile();

  const timelineEvents = [
    {
      year: '2018',
      title: 'Foundation',
      description: 'Synthreo was founded with a vision to transform business operations through AI.',
      icon: <CalendarDays className="h-3 w-3 md:h-4 md:w-4 text-synthreo-blue-light" />,
    },
    {
      year: '2019',
      title: 'First Product Launch',
      description: 'Released our initial AI assistant prototype, establishing our presence in the market.',
      icon: <Rocket className="h-3 w-3 md:h-4 md:w-4 text-synthreo-blue-light" />,
    },
    {
      year: '2020',
      title: 'Team Expansion',
      description: 'Grew our team with leading AI researchers and engineers from around the world.',
      icon: <Users className="h-3 w-3 md:h-4 md:w-4 text-synthreo-blue-light" />,
    },
    {
      year: '2021',
      title: 'ThreoPortal Development',
      description: 'Began development of our flagship product, an end-to-end AI workflow platform.',
      icon: <Code className="h-3 w-3 md:h-4 md:w-4 text-synthreo-blue-light" />,
    },
    {
      year: '2022',
      title: 'Industry Recognition',
      description: 'Received multiple awards for innovation in enterprise AI solutions.',
      icon: <Award className="h-3 w-3 md:h-4 md:w-4 text-synthreo-blue-light" />,
    },
    {
      year: '2023',
      title: 'ThreoAI Launch',
      description: 'Released our advanced generative AI platform for enterprises, setting new industry standards.',
      icon: <Zap className="h-3 w-3 md:h-4 md:w-4 text-synthreo-blue-light" />,
    }
  ];

  return (
    <div className="py-12 md:py-16">
      <div className="text-center mb-8 md:mb-12">
        <h2 className="mb-3 md:mb-4 text-2xl md:text-3xl lg:text-4xl font-bold leading-tight">
          Our <span className="text-gradient">Journey</span>
        </h2>
        <div className="mx-auto mb-4 md:mb-8 h-1 w-16 md:w-20 bg-gradient-to-r from-synthreo-blue-darkest to-synthreo-blue-medium" />
        <p className="mx-auto max-w-2xl text-sm md:text-lg text-gray-300 px-4">
          From humble beginnings to industry leadership, explore the key moments that have shaped Synthreo's evolution.
        </p>
      </div>

      <div className="relative overflow-hidden">
        <div className="absolute top-1/2 left-0 right-0 h-px bg-gradient-to-r from-synthreo-blue-dark/10 via-synthreo-blue-dark/30 to-synthreo-blue-dark/10 hidden md:block"></div>
        
        {isMobile ? (
          <div className="px-4 space-y-4">
            {timelineEvents.map((event, index) => (
              <TimelineItem
                key={index}
                year={event.year}
                title={event.title}
                description={event.description}
                icon={event.icon}
                isMobile={true}
              />
            ))}
          </div>
        ) : (
          <div className="relative grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            {timelineEvents.map((event, index) => (
              <div key={index} className="relative">
                <TimelineItem
                  year={event.year}
                  title={event.title}
                  description={event.description}
                  icon={event.icon}
                />
                {index < timelineEvents.length - 1 && (
                  <div className="absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2 z-10 hidden lg:block">
                    <div className="bg-synthreo-black-light blue-glow-sm p-1 rounded-full">
                      <ChevronRight className="h-4 w-4 text-synthreo-blue-light" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TimelineSection;
