
import React from 'react';
import ContactHeader from './contact/ContactHeader';
import ContactInfo from './contact/ContactInfo';
import ContactForm from './contact/ContactForm';

interface ContactProps {
  formName?: 'synthreo-contact' | 'synthreo-signup';
  showHeader?: boolean;
}

const Contact = ({ formName = 'synthreo-contact', showHeader = true }: ContactProps) => {
  return (
    <section className="py-16 px-4 md:px-8 relative z-10">
      <div className="container mx-auto max-w-6xl">
        {showHeader && (
          <>
            <ContactHeader />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-12">
              <ContactInfo />
              <ContactForm formName={formName} />
            </div>
          </>
        )}
        
        {!showHeader && (
          <ContactForm formName={formName} />
        )}
      </div>
    </section>
  );
};

export default Contact;
