
import React, { useState, useEffect } from 'react';
import { 
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from '@/components/ui/pagination';

const SocialProof = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const testimonials = [
    {
      quote: "We've been working with Synthreo for over a year. Everyone at Synthreo deserves praise for their quick response to our requests, excellent communication, and technological reliability. We're using the Synthreo solution for a secret project :-), which helps us price construction contracts almost as well as a human would. The solution is fast, efficient, available 24/7, and never calls in sick. We're not at the finish line yet, but even the 90% we've covered so far is incredibly valuable. Thanks, and we look forward to staying connected!",
      author: "Marek",
      title: "Navlacil",
      image: "/lovable-uploads/71dffb77-38a8-4429-a3e0-e9f468ced2f2.png"
    },
    {
      quote: "Working with the Synthreo implementation team has been a positive and flexible experience. They have consistently listened to and responded to our needs, tailoring the AI solution to our requirements as much as possible in the demo phase. While we're still in the early stages with only a demo version in place, the initial feedback has been overwhelmingly positive. The Synthreo AI platform has sparked significant interest within our company, and we are looking forward to experiencing the full impact of the complete implementation. We anticipate that Synthreo's AI platform will streamline our processes and enhance operational efficiency as we progress to the full version.",
      author: "Adam",
      title: "Ellacs",
      image: "/lovable-uploads/1b21d2d1-8da4-44fe-a539-56f5f33839fb.png"
    },
    {
      quote: "Synthreo has been incredibly valuable for our AI-driven recruiting business. Without it, we would have faced months of complex development and higher costs to build a similar system from scratch. Synthreo has saved us time and resources, providing the AI capabilities we need to grow. The team has been super supportive, always going the extra mile to make sure we're getting the best out of the platform. Highly recommend!",
      author: "Kevin",
      title: "Resio",
      image: "/lovable-uploads/88b38e3d-bd7f-4d2d-af87-c657f4622dbc.png"
    }
  ];

  const partnerLogos = [
    { name: "CS Ella", logo: "/lovable-uploads/407a261a-73e4-45cc-8ce5-d2f4c894543e.png" },
    { name: "Navlacil", logo: "/lovable-uploads/c34f1152-b628-40f7-99b5-4746a44c6125.png" },
    { name: "U+", logo: "/lovable-uploads/d2c063df-21c3-47ee-a734-ef7438bac620.png" },
    { name: "CloudIT", logo: "/lovable-uploads/95b73f98-1b65-4ef6-84e9-f11a9e0145dd.png" },
    { name: "Macek Legal", logo: "/lovable-uploads/e6538738-f72c-41ab-b69e-0d9122c416e8.png" }
  ];

  // Auto-rotate testimonials every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    
    return () => clearInterval(interval);
  }, [testimonials.length]);

  const handlePaginationClick = (index: number) => {
    setCurrentTestimonial(index);
  };

  return (
    <section id="social-proof" className="py-24 bg-gradient-to-b from-synthreo-black to-synthreo-black-light relative">
      <div className="absolute inset-0 z-0 bg-grid-pattern opacity-5" />
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
            Trusted by <span className="text-gradient">Forward-Thinking Organizations</span>
          </h2>
          <p className="text-xl text-gray-300 font-poppins">
            Join industry leaders who are already transforming their operations with Synthreo.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 mb-16">
          {partnerLogos.map((logo, index) => (
            <div key={index} className="flex items-center justify-center p-4 bg-transparent">
              <img 
                src={logo.logo} 
                alt={logo.name} 
                className="h-12 w-auto object-contain" 
              />
            </div>
          ))}
        </div>

        <div className="mb-16">
          <div className="card-glow rounded-lg p-6 relative mx-auto max-w-3xl">
            <div className="mb-6 pt-4 max-h-80 overflow-y-auto pr-2">
              <p className="text-gray-300 italic font-poppins text-sm">"{testimonials[currentTestimonial].quote}"</p>
            </div>
            <div className="flex items-center mt-auto">
              <img 
                src={testimonials[currentTestimonial].image} 
                alt={testimonials[currentTestimonial].author} 
                className="h-10 w-10 rounded-full mr-3 object-cover"
              />
              <div>
                <h4 className="font-semibold font-gilroy text-sm">{testimonials[currentTestimonial].author}</h4>
                <p className="text-xs text-gray-400 font-poppins">{testimonials[currentTestimonial].title}</p>
              </div>
            </div>
          </div>
          
          <Pagination className="mt-6">
            <PaginationContent>
              {testimonials.map((_, index) => (
                <PaginationItem key={index}>
                  <PaginationLink 
                    onClick={() => handlePaginationClick(index)}
                    isActive={currentTestimonial === index}
                    className={`w-3 h-3 rounded-full p-0 ${currentTestimonial === index ? 'bg-synthreo-blue-medium' : 'bg-gray-600'}`}
                    aria-label={`Testimonial ${index + 1}`}
                  />
                </PaginationItem>
              ))}
            </PaginationContent>
          </Pagination>
        </div>
      </div>
    </section>
  );
};

export default SocialProof;
