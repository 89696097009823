
import React from 'react';
import { CheckIcon, XIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

// Define pricing tiers
const pricingTiers = [
  {
    name: 'Starter',
    popular: false,
    price: '$499',
    period: '/month',
    discount: '',
    description: 'Perfect for small businesses looking to automate customer interactions.',
    detailedDescription: '"Get started with 1 fully managed AI agent to automate simple business tasks like responding to employee questions or handling basic customer inquiries. Perfect for teams looking to introduce an AI assistant into their workflow without complexity."',
    features: [
      '1 Fully Managed ThreoAgent',
      'Up to 3 ThreoAssistants (Workflow Tasks)',
      '1,000 conversations/month',
      '1 function (e.g., Helpdesk or HR)',
      'Prebuilt setup',
      'X Agent-to-Agent Workflows',
      'Basic usage metrics',
      'Shared support + email',
      'Monthly monitoring & updates',
      'Knowledge base integration',
      'Custom training',
    ],
    buttonText: 'Get Started',
    buttonLink: '/auth/signup',
  },
  {
    name: 'Professional',
    popular: true,
    price: '$1299',
    period: '/month',
    discount: 'Most Popular',
    description: 'Ideal for growing businesses with multiple automation needs.',
    detailedDescription: '"Expand your automation with up to 3 AI agents working across different departments. Ideal for growing teams that want to streamline operations like time-off requests, invoice processing, or HR onboarding with smart, GPT-powered assistants."',
    features: [
      'Up to 3 Fully Managed ThreoAgents',
      'Up to 10 ThreoAssistants',
      '5,000 conversations/month',
      'Up to 3 functions (e.g., IT, HR, Finance)',
      'Prebuilt or light customization',
      'Optional add-on: Agent-to-Agent Workflows',
      'Outcome-based analytics',
      'Guided onboarding + chat support',
      'Ongoing tuning + health checks',
      'Advanced integration options',
      'Enhanced SLA',
    ],
    buttonText: 'Get Started',
    buttonLink: '/auth/signup',
  },
  {
    name: 'Enterprise',
    popular: false,
    price: 'Custom Pricing',
    period: '',
    discount: '',
    description: 'For organizations with complex automation requirements at scale.',
    detailedDescription: '"For businesses ready to scale automation across the organization. Unlimited AI agents designed for custom workflows—from AI-powered help desks to cross-functional process automation. Includes advanced reporting and strategic support."',
    features: [
      'Unlimited Fully Managed ThreoAgents',
      'Unlimited ThreoAssistants',
      'Unlimited conversations',
      'Unlimited cross-functional workflows',
      'Fully customized agents, workflows, and data integrations',
      'Agent-to-Agent Workflows included',
      'Custom dashboards & KPI tracking',
      'White-glove onboarding + dedicated success manager',
      'Proactive optimization & dedicated agent operations',
      'Enterprise analytics',
      'Custom SLA',
    ],
    buttonText: 'Contact Sales',
    buttonLink: '/company/demo',
  },
];

const PricingTiers = () => {
  return (
    <section className="py-2 md:py-6">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8">
          {pricingTiers.map((tier, index) => (
            <div 
              key={index} 
              className={`bg-synthreo-black-light border ${
                tier.popular ? 'border-synthreo-blue-medium' : 'border-gray-700'
              } rounded-xl p-8 flex flex-col h-full relative`}
            >
              {tier.popular && (
                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-synthreo-blue-medium text-white text-xs font-medium px-3 py-1 rounded-full">
                  {tier.discount}
                </div>
              )}
              
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold mb-4 font-gilroy">{tier.name}</h3>
                <div className="mb-6">
                  <span className="text-3xl font-bold">{tier.price}</span>
                  <span className="text-gray-400">{tier.period}</span>
                </div>
                <p className="text-sm text-gray-300 mx-auto max-w-[280px]">{tier.description}</p>
              </div>
              
              <ul className="space-y-4 mb-8 flex-grow">
                {tier.features.map((feature, idx) => (
                  <li key={idx} className="flex items-start">
                    {feature.startsWith('X') ? (
                      <>
                        <XIcon size={18} className="text-gray-500 mt-0.5 mr-3 shrink-0" />
                        <span className="text-gray-500 text-sm text-left">{feature.substring(2)}</span>
                      </>
                    ) : (
                      <>
                        <CheckIcon size={18} className="text-synthreo-blue-light mt-0.5 mr-3 shrink-0" />
                        <span className="text-sm text-left">{feature}</span>
                      </>
                    )}
                  </li>
                ))}
              </ul>
              
              <div className="border-l-4 border-synthreo-blue-light pl-3 py-1 mb-6 bg-synthreo-black/50">
                <p className="text-sm text-gray-300 italic">{tier.detailedDescription}</p>
              </div>
              
              <Button 
                variant={tier.name === 'Enterprise' ? 'outline' : 'default'}
                className={`w-full mt-auto py-6 ${
                  tier.name !== 'Enterprise' ? 'btn-glow' : 'border-synthreo-blue-medium text-synthreo-blue-medium hover:bg-synthreo-blue-darkest'
                }`}
                asChild
              >
                <Link to={tier.buttonLink}>
                  {tier.buttonText}
                </Link>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingTiers;
