
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface MenuSectionProps {
  title: string;
  route: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const MenuSection = ({ title, route, onClose, children }: MenuSectionProps) => {
  const location = useLocation();
  
  return (
    <li className="mb-1">
      <Link 
        to={route}
        className={cn(
          "block text-lg font-medium transition-colors mb-2",
          location.pathname === route || location.pathname.includes(route)
            ? "text-synthreo-blue-medium" 
            : "text-gray-300 hover:text-synthreo-blue-medium"
        )}
        onClick={onClose}
      >
        {title}
      </Link>
      {children && (
        <ul className="pl-4 space-y-2.5 border-l border-gray-800">
          {children}
        </ul>
      )}
    </li>
  );
};

export default MenuSection;
