
import { toast } from 'sonner';
import { ensureClarityAvailable, updateClarityConsent } from './analytics/clarity';
import { recoverFromBlankScreen, createFallbackUI } from './botx/recovery';

// Global recovery flag to prevent loops
let isRecovering = false;

/**
 * Sets up global error handlers for the application
 */
export function setupGlobalErrorHandlers() {
  // Capture and log all unhandled errors
  window.addEventListener('error', (event) => {
    // Prevent handling the same error multiple times
    if (isRecovering) return;

    console.error('Global error caught:', event.error);
    console.error('Error details:', {
      message: event.message,
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      stack: event.error?.stack,
      timestamp: new Date().toISOString(),
      url: window.location.href
    });

    // Special handling for JSON parsing errors which often cause blank screens
    if (event.message && (event.message.includes('JSON') || event.message.includes('Unexpected end'))) {
      isRecovering = true;
      console.error('Detected JSON parsing error, attempting recovery');
      
      // Force root element visible
      const root = document.getElementById('root');
      if (root && root.style.visibility !== 'visible') {
        root.style.visibility = 'visible';
        console.log('Force-showing root element to recover from blank screen');
      }
      
      // Create schedule for multiple recovery attempts
      setTimeout(() => recoverFromBlankScreen(true), 0);
      setTimeout(() => recoverFromBlankScreen(true), 500);
      setTimeout(() => recoverFromBlankScreen(true), 1500);
      
      setTimeout(() => {
        isRecovering = false;
      }, 2000);
      
      // Prevent bubbling for external script errors
      if (event.filename && (
        event.filename.includes('botx') || 
        event.filename.includes('clarity') ||
        !event.filename.includes(window.location.origin)
      )) {
        event.preventDefault();
        return;
      }
    }

    // Special handling for cross-origin errors which are often from external scripts
    if (event.message === 'Script error.' || event.message === 'Script error') {
      isRecovering = true;
      console.error('Detected cross-origin script error, attempting recovery');
      
      // Try to recover third-party scripts
      try {
        ensureClarityAvailable();
        updateClarityConsent();
        
        // Make root element visible to prevent blank screen
        recoverFromBlankScreen(true);
        
        setTimeout(() => {
          isRecovering = false;
        }, 2000);
      } catch (e) {
        console.error('Failed to recover third-party scripts:', e);
      }
      
      // Prevent the default error handling for cross-origin errors
      event.preventDefault();
      return;
    }

    // Check if we need to create a recovery UI for critical errors
    if (isCriticalError(event)) {
      createRecoveryUI(event);
    }

    // Only show toast for less critical errors to avoid overwhelming the user
    if (!isCriticalError(event) && !event.message.includes('botx') && !event.message.includes('clarity')) {
      toast.error('An error occurred. We\'re working to fix it.');
    }
  });

  // Add unhandled promise rejection handler
  window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled Promise Rejection:', event.reason);
    if (event.reason && event.reason.stack) {
      console.error('Promise Rejection Stack:', event.reason.stack);
    }
    
    // Try recovery on severe promise rejections
    if (event.reason && 
        (event.reason.message?.includes('JSON') || 
         event.reason.message?.includes('undefined') || 
         event.reason.message?.includes('null'))) {
      recoverFromBlankScreen(true);
    }
    
    // Prevent the error from being reported to the console again
    event.preventDefault();
  });
  
  // Add a visibilitychange listener to check recovery on tab focus
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      // When user returns to the tab, check if we need recovery
      setTimeout(() => recoverFromBlankScreen(), 500);
    }
  });
}

/**
 * Determines if an error is critical enough to warrant UI recovery
 */
function isCriticalError(event: ErrorEvent): boolean {
  // Check if it's a render error
  const isRenderError = 
    event.message?.includes('render') || 
    event.message?.includes('React') || 
    event.message?.includes('Minified React error') ||
    event.error?.stack?.includes('react-dom');
    
  // Check if it's a failed to load critical resource
  const isResourceError = 
    event.message?.includes('chunk failed') || 
    event.message?.includes('failed to load') ||
    event.message?.includes('loading chunk');
    
  return isRenderError || isResourceError;
}

/**
 * Creates a minimal recovery UI if the screen is blank
 */
function createRecoveryUI(event: ErrorEvent) {
  // Try to provide user feedback for critical errors
  try {
    // Check if the screen appears to be blank
    const wasBlank = document.body.children.length === 0 || 
                    (document.getElementById('root') && !document.getElementById('root')?.firstChild);
  
    if (wasBlank) {
      createFallbackUI();
    }
  } catch (recoveryError) {
    console.error('Failed to create recovery UI:', recoveryError);
  }
}
