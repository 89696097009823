
import React from 'react';
import { Control } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { 
  FormControl, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';
import { FormValues } from './contactFormSchema';

interface FormTermsFieldProps {
  control: Control<FormValues>;
}

const FormTermsField = ({ control }: FormTermsFieldProps) => {
  return (
    <FormField
      control={control}
      name="agreeToTerms"
      render={({ field }) => (
        <FormItem className="flex flex-row items-start space-x-3 space-y-0 mt-4">
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={field.onChange}
              className="border-white data-[state=checked]:bg-synthreo-blue-medium data-[state=checked]:text-white"
              name="agreeToTerms"
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            <FormLabel className="text-sm text-white">
              I agree to the <Link to="/company/privacy-policy" className="text-synthreo-blue-medium hover:underline">terms and conditions and privacy policy</Link>
            </FormLabel>
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
};

export default FormTermsField;
