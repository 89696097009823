
import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Index from '../pages/Index';
import CompanyAbout from '../pages/company/About';
import Leadership from '../pages/company/Leadership';
import Careers from '../pages/company/Careers';
import ContactPage from '../pages/company/Contact';
import DemoSignUp from '../pages/company/DemoSignUp';
import Support from '../pages/company/Support';
import PrivacyPolicy from '../pages/company/PrivacyPolicy';
import Blog from '../pages/resources/Blog';
import CaseStudies from '../pages/resources/CaseStudies';
import Pricing from '../pages/Pricing';
import NotFound from '../pages/NotFound';
import ContactSuccess from '../pages/company/ContactSuccess';
import SignUp from '../pages/auth/SignUp';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Partners from '../pages/Partners';
import Solutions from '../pages/Solutions';
import Products from '../pages/Products';
import ThreoAI from '../pages/products/ThreoAI';
import ThreoBuilder from '../pages/products/ThreoBuilder';
import AIAgentsChatbots from '../pages/solutions/AIAgentsChatbots';
import DocumentProcessing from '../pages/solutions/DocumentProcessing';
import WorkflowAutomation from '../pages/solutions/WorkflowAutomation';
import Documentation from '../pages/resources/Documentation';
import BlogPost from '../pages/resources/BlogPost';
import CaseStudyDetail from '../pages/resources/CaseStudyDetail';
import Resources from '../pages/Resources';
import LoadingFallback from '../components/common/LoadingFallback';

const AppRoutes = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path="/" element={<Index />} />
        
        {/* Main Routes */}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/partners" element={<Partners />} />
        
        {/* Auth Routes */}
        <Route path="/auth">
          <Route path="signup" element={<SignUp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>
        
        {/* Solutions Routes */}
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/solutions/ai-agents-chatbots" element={<AIAgentsChatbots />} />
        <Route path="/solutions/document-processing" element={<DocumentProcessing />} />
        <Route path="/solutions/workflow-automation" element={<WorkflowAutomation />} />
        
        {/* Products Routes */}
        <Route path="/products" element={<Products />} />
        <Route path="/products/threoai" element={<ThreoAI />} />
        <Route path="/products/threobuilder" element={<ThreoBuilder />} />
        
        {/* Resources Routes */}
        <Route path="/resources" element={<Resources />} />
        <Route path="/resources">
          <Route index element={<Resources />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:slug" element={<BlogPost />} />
          <Route path="case-studies" element={<CaseStudies />} />
          <Route path="case-studies/:slug" element={<CaseStudyDetail />} />
          <Route path="documentation" element={<Documentation />} />
        </Route>
        
        {/* Company Routes */}
        <Route path="/company">
          <Route path="about" element={<CompanyAbout />} />
          <Route path="leadership" element={<Leadership />} />
          <Route path="careers" element={<Careers />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="contact-success" element={<ContactSuccess />} />
          <Route path="demo" element={<DemoSignUp />} />
          <Route path="support" element={<Support />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        
        {/* No Match Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
