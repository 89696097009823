
import React, { useEffect, useRef } from 'react';

interface BookingWidgetProps {
  url?: string;
  height?: string;
  prefill?: {
    email?: string;
    name?: string;
    customAnswers?: {
      [key: string]: string;
    };
  };
}

const BookingWidget: React.FC<BookingWidgetProps> = ({ 
  url = 'https://outlook.office365.com/owa/calendar/GreggLalle@synthreo.ai/bookings/',
  height = '2500px',
  prefill
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    // We can't directly access iframe content due to same-origin policy
    // The console error is expected and not harmful
    console.log('Note: Cannot access iframe content due to same-origin policy - this is normal');
  }, []);

  return (
    <div className="booking-container w-full h-full bg-synthreo-black">
      <div className="iframe-wrapper relative w-full" style={{ minHeight: '2500px', backgroundColor: '#0A0A0A' }}>
        <iframe 
          ref={iframeRef}
          src={url}
          width="100%" 
          height="100%"
          style={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            minHeight: '2500px',
            overflow: 'visible',
            backgroundColor: '#0A0A0A',
            filter: 'invert(1) hue-rotate(180deg)'
          }}
          title="Booking Calendar"
          allowFullScreen
          loading="lazy"
          sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
          referrerPolicy="origin"
        />
      </div>
    </div>
  );
};

export default BookingWidget;
