
/**
 * Patches JSON.parse with a safer version that handles malformed JSON
 */
export const patchJSONParse = (): void => {
  if (typeof window === 'undefined') return;
  
  try {
    // Store the original parse method
    const originalJSONParse = JSON.parse;
    
    // Create a safe JSON parser that handles errors
    const safeJSONParse = (text: string): any => {
      try {
        return originalJSONParse(text);
      } catch (e) {
        console.warn('Failed to parse JSON:', e);
        
        // Try to sanitize the input by fixing common issues
        try {
          // Replace unescaped quotes in string values
          const sanitized = text
            .replace(/:\s*'([^']*)'/g, ': "$1"')
            .replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2":');
          
          return originalJSONParse(sanitized);
        } catch (sanitizeError) {
          console.error('Failed to parse sanitized JSON:', sanitizeError);
          // Return a basic object to prevent undefined errors
          return {};
        }
      }
    };
    
    // Override the global JSON.parse
    JSON.parse = safeJSONParse;
    
    // Also make it available on the window for direct access
    if (window.safeBotxJSONParse === undefined) {
      window.safeBotxJSONParse = safeJSONParse;
    }
    
    console.log('Successfully patched JSON.parse');
  } catch (e) {
    console.error('Failed to patch JSON.parse:', e);
  }
};
