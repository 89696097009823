
import { hasCookieConsent } from '../cookieConsent';

let koalaInitialized = false;

/**
 * Initialize Koala Analytics
 */
export const initKoala = (): void => {
  // Only initialize once
  if (koalaInitialized) return;
  
  // Check if analytics cookies are accepted
  if (!hasCookieConsent('analytics')) return;
  
  // Initialize Koala with the provided script
  const koalaInit = (): void => {
    const k = "ko";
    const win = window as any;
    win.globalKoalaKey = win.globalKoalaKey || k;
    
    // Check if ko already exists on window
    if (win.ko) return;
    
    // Create ko array and attach to window
    win.ko = [];
    const ko = win.ko;
    
    // Define methods on ko
    ["identify", "track", "removeListeners", "on", "off", "qualify", "ready"].forEach(function(t) {
      ko[t] = function() {
        const n = [].slice.call(arguments);
        n.unshift(t);
        ko.push(n);
        return ko;
      };
    });
    
    // Create and append script
    const n = document.createElement("script");
    n.async = true;
    n.setAttribute("src", "https://cdn.getkoala.com/v1/pk_3131f85661ba8568cebbab2d15b7e0714a90/sdk.js");
    (document.body || document.head).appendChild(n);
  };
  
  koalaInit();
  koalaInitialized = true;
  console.log("Koala analytics initialized");
};

/**
 * Remove Koala Analytics
 */
export const removeKoala = (): void => {
  if (!koalaInitialized) return;
  
  // Remove Koala script tag
  const koalaScript = document.querySelector('script[src*="getkoala.com"]');
  if (koalaScript) {
    koalaScript.remove();
  }
  
  // Clean up global variables
  const win = window as any;
  if (win.ko) delete win.ko;
  if (win.globalKoalaKey) delete win.globalKoalaKey;
  
  koalaInitialized = false;
  console.log("Koala analytics removed");
};
