
import React from 'react';
import { Button } from "@/components/ui/button";
import { Cookie } from "lucide-react";
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { useCookieStatus } from '@/hooks/useCookieStatus';

const CookieSettingsTooltip = () => {
  const { status, openSettings } = useCookieStatus();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={openSettings}
            className="text-xs bg-background border border-border shadow-md hover:bg-muted"
          >
            <Cookie className="h-4 w-4 mr-1" />
            <span>Cookie Settings</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="text-xs">Current status: {status}</p>
          <p className="text-xs mt-1">Click to change preferences</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default CookieSettingsTooltip;
