
import React, { useState } from 'react';
import { useAuth } from '@/context/AuthContext';
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { LogIn, LogOut, User, Monitor, Terminal, ExternalLink } from 'lucide-react';
import { Button } from '@/components/ui/button';

const AuthButton = () => {
  const { isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth();
  const [loginHoverOpen, setLoginHoverOpen] = useState(false);

  if (isLoading) {
    return (
      <Button 
        variant="ghost" 
        size="sm" 
        className="text-white px-4 py-2 rounded-md text-sm font-medium opacity-70"
        disabled
      >
        <span className="animate-pulse">Loading...</span>
      </Button>
    );
  }

  if (isAuthenticated) {
    return (
      <HoverCard open={loginHoverOpen} onOpenChange={setLoginHoverOpen} openDelay={0} closeDelay={0}>
        <HoverCardTrigger asChild>
          <button
            className="bg-synthreo-black-light text-white hover:bg-synthreo-black-dark font-gilroy px-4 py-2 rounded-md text-sm font-medium transition-colors flex items-center gap-1"
            onMouseEnter={() => setLoginHoverOpen(true)}
            onClick={() => setLoginHoverOpen(!loginHoverOpen)}
          >
            {user?.picture ? (
              <img 
                src={user.picture} 
                alt={user.name || "User"} 
                className="w-6 h-6 rounded-full mr-1" 
              />
            ) : (
              <User size={18} className="mr-1" />
            )}
            {user?.name?.split(' ')[0] || 'Account'}
          </button>
        </HoverCardTrigger>
        <HoverCardContent 
          className="bg-synthreo-black-light border-synthreo-black-light w-80 p-0"
          align="end"
        >
          <div className="flex flex-col">
            <a 
              href="https://mygpt.synthreo.ai" 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
            >
              <Monitor size={16} className="text-blue-400" />
              <span>ThreoAI Portal</span>
              <ExternalLink size={14} className="ml-auto text-blue-400" />
            </a>
            <a 
              href="https://console.botx.cloud" 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
            >
              <Terminal size={16} className="text-blue-400" />
              <span>ThreoBuilder</span>
              <ExternalLink size={14} className="ml-auto text-blue-400" />
            </a>
            <button 
              onClick={() => logout()}
              className="flex items-center gap-2 p-3 hover:bg-synthreo-black-dark hover:text-red-400 text-white"
            >
              <LogOut size={16} className="text-red-400" />
              <span>Logout</span>
            </button>
          </div>
        </HoverCardContent>
      </HoverCard>
    );
  }

  return (
    <button
      onClick={() => loginWithRedirect()}
      className="bg-synthreo-black-light text-white hover:bg-synthreo-black-dark font-gilroy px-4 py-2 rounded-md text-sm font-medium transition-colors flex items-center gap-1"
    >
      <LogIn size={18} className="mr-1" />
      Login
    </button>
  );
};

export default AuthButton;
