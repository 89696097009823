
import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import SignUpForm from '@/components/signup/SignUpForm';
import SEOMetaTags from '@/components/SEOMetaTags';
import { toast } from 'sonner';

const SignUp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check for success parameter in URL
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('signup') === 'success') {
      toast.success('Account request submitted! You will receive an email with further instructions.');
    }
  }, []);

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white no-scrollbar">
      <SEOMetaTags 
        title="Sign Up | Create Your Synthreo Account"
        description="Join Synthreo and unlock the power of AI for your business. Create your account today to access our full range of AI solutions."
        url="https://synthreo.ai/auth/signup"
        imageUrl="https://synthreo.ai/og-image.png"
        canonical="https://synthreo.ai/auth/signup"
        keywords="Synthreo signup, create account, AI solutions account, register Synthreo"
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow pt-24">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-6 text-center">
              Create Your <span className="text-synthreo-blue-medium">Account</span>
            </h1>
            <p className="text-xl mb-10 text-gray-300 text-center">
              Join Synthreo and unlock the power of AI for your business.
            </p>

            <SignUpForm />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SignUp;
