
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Monitor, Terminal, ArrowRight } from 'lucide-react';
import SEOMetaTags from '@/components/SEOMetaTags';

const Products = () => {
  const productsSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Managed AI Agents & Solutions | Enterprise Software by Synthreo",
    "description": "Explore Synthreo's suite of managed AI agents and platforms designed to automate workflows, enhance decision-making, and create powerful AI-driven experiences for businesses.",
    "url": "https://synthreo.ai/products",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "ThreoAI Portal",
          "description": "A secure, multi-tenant GPT-like chat portal that simplifies managed AI agent deployment."
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "ThreoBuilder AI Builder",
          "description": "Build and manage powerful AI agents without coding expertise using our enterprise platform."
        }
      ]
    }
  };

  useEffect(() => {
    document.title = 'Managed AI Agents & Products | Synthreo';
  }, []);

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white overflow-hidden">
      <SEOMetaTags 
        title="Managed AI Agents & Solutions | Enterprise Software by Synthreo"
        description="Explore Synthreo's suite of managed AI agents and platforms designed to automate workflows, enhance decision-making, and create powerful AI-driven experiences for businesses."
        url="https://synthreo.ai/products"
        schema={productsSchema}
        canonical="https://synthreo.ai/products"
        keywords="Managed AI agents, enterprise AI software, AI solutions, ThreoAI, ThreoBuilder, workflow automation platform"
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow w-full">
        {/* Hero Section */}
        <section className="relative py-32 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center">
            <div className="mb-10">
              <img 
                src="/lovable-uploads/6c30f34e-1ba5-4c13-9435-d538badfad96.png" 
                alt="Synthreo Platform Interface" 
                className="w-full max-w-5xl mx-auto rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm"
              />
            </div>
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-6 text-gradient-blue">
              Cutting-Edge AI Products for Modern Businesses
            </h1>
            <p className="text-xl mb-8 text-gray-300 max-w-3xl mx-auto font-poppins">
              Our suite of AI products helps businesses automate workflows, enhance decision-making, 
              and create powerful AI-driven experiences.
            </p>
          </div>
        </section>

        {/* Products Showcase */}
        <section className="py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            {/* ThreoAI */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center mb-24">
              <div className="order-2 lg:order-1">
                <div className="inline-block text-synthreo-blue-medium mb-4 font-poppins">
                  <span className="flex items-center gap-2">
                    <Monitor size={20} />
                    <span className="text-sm font-medium">MULTI-TENANT PORTAL</span>
                  </span>
                </div>
                <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-4">ThreoAI Portal</h2>
                <p className="text-xl mb-6 text-gray-300 font-poppins">
                  A secure, multi-tenant GPT-like chat portal that simplifies AI management and deployment.
                </p>
                
                <ul className="mb-8 space-y-3 font-poppins">
                  <li className="flex items-start gap-2">
                    <div className="text-synthreo-blue-medium mt-1">
                      <ArrowRight size={16} />
                    </div>
                    <span>Fully customizable, white-label branding</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="text-synthreo-blue-medium mt-1">
                      <ArrowRight size={16} />
                    </div>
                    <span>Integration with any leading LLM</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="text-synthreo-blue-medium mt-1">
                      <ArrowRight size={16} />
                    </div>
                    <span>Analytics and seamless ITSM integration</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="text-synthreo-blue-medium mt-1">
                      <ArrowRight size={16} />
                    </div>
                    <span>Deliver AI Agents easily to clients</span>
                  </li>
                </ul>
                
                <Button asChild className="btn-glow text-white">
                  <Link to="/products/threoai">Learn More</Link>
                </Button>
              </div>
              <div className="order-1 lg:order-2">
                <div className="relative">
                  <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                    <img 
                      src="/lovable-uploads/d5f17c46-4083-4f7c-aefa-f7b1375ebfbc.png" 
                      alt="ThreoAI Portal Interface" 
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ThreoBuilder */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div className="order-2">
                <div className="inline-block text-synthreo-blue-medium mb-4 font-poppins">
                  <span className="flex items-center gap-2">
                    <Terminal size={20} />
                    <span className="text-sm font-medium">AI AGENT BUILDER</span>
                  </span>
                </div>
                <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-4">ThreoBuilder AI Builder</h2>
                <p className="text-xl mb-6 text-gray-300 font-poppins">
                  Build powerful AI agents without coding expertise using our intuitive no-code platform.
                </p>
                
                <ul className="mb-8 space-y-3 font-poppins">
                  <li className="flex items-start gap-2">
                    <div className="text-synthreo-blue-medium mt-1">
                      <ArrowRight size={16} />
                    </div>
                    <span>Advanced RAG data integration</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="text-synthreo-blue-medium mt-1">
                      <ArrowRight size={16} />
                    </div>
                    <span>Built-in cloud database</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="text-synthreo-blue-medium mt-1">
                      <ArrowRight size={16} />
                    </div>
                    <span>API integration via Cloud Data Studio</span>
                  </li>
                </ul>
                
                <Button asChild className="btn-glow text-white">
                  <Link to="/products/threobuilder">Learn More</Link>
                </Button>
              </div>
              <div className="order-1">
                <div className="relative">
                  <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                    <img 
                      src="/lovable-uploads/102549ce-bf71-430d-b3bd-210b82d7ea67.png" 
                      alt="ThreoBuilder Interface" 
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Additional Product Features */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto text-center">
            <h2 className="text-3xl font-bold font-gilroy mb-8">Advanced AI Platform Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                <img 
                  src="/lovable-uploads/11ee6a63-94eb-4270-8dfa-b6b38822f095.png" 
                  alt="AI Workflow Builder" 
                  className="w-full h-auto"
                />
                <div className="bg-synthreo-black-light p-4">
                  <h3 className="text-xl font-bold mb-2 font-gilroy">Visual AI Workflow Builder</h3>
                  <p className="text-gray-300 font-poppins">Design complex AI workflows with our intuitive node-based editor</p>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                <img 
                  src="/lovable-uploads/900c8221-2bca-42b5-8dfc-2aa7a1239ad6.png" 
                  alt="Deep Learning Editor" 
                  className="w-full h-auto"
                />
                <div className="bg-synthreo-black-light p-4">
                  <h3 className="text-xl font-bold mb-2 font-gilroy">Deep Learning Editor</h3>
                  <p className="text-gray-300 font-poppins">Fine-tune neural networks with our visual deep learning configuration tool</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section className="py-20 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto text-center">
            <h2 className="text-3xl font-bold font-gilroy mb-4">Flexible Pricing for Businesses of All Sizes</h2>
            <p className="text-xl mb-12 text-gray-300 max-w-3xl mx-auto font-poppins">
              Whether you're a startup or an enterprise, we have pricing plans that scale with your needs.
            </p>
            <Button asChild className="bg-synthreo-black-light text-white hover:bg-synthreo-black-dark py-6 px-8 text-lg">
              <Link to="/company/demo">Contact Sales</Link>
            </Button>
          </div>
        </section>

        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default Products;
