
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';

interface SignUpButtonProps {
  onClose: () => void;
}

const SignUpButton = ({ onClose }: SignUpButtonProps) => {
  return (
    <li className="pt-4">
      <Link to="/auth/signup">
        <Button 
          variant="default" 
          className="w-full btn-glow text-white hover:bg-synthreo-blue-darkest font-gilroy"
          onClick={onClose}
        >
          Sign Up
        </Button>
      </Link>
    </li>
  );
};

export default SignUpButton;
