
import React, { useEffect } from 'react';
import ErrorBoundary from '@/components/ErrorBoundary';

interface HeroSectionProps {
  onError: (error: Error) => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({ onError }) => {
  // Add enhanced error handling
  useEffect(() => {
    try {
      console.log('HeroSection component mounted');
      
      // Check if we were in an error state previously and log recovery
      const errorRecoveryData = sessionStorage.getItem('heroSectionErrorRecovery');
      if (errorRecoveryData) {
        console.log('HeroSection recovered from previous error:', errorRecoveryData);
        sessionStorage.removeItem('heroSectionErrorRecovery');
      }
    } catch (error) {
      console.error('Error in HeroSection useEffect:', error);
      // Save error information for recovery tracking
      try {
        sessionStorage.setItem('heroSectionErrorRecovery', String(error));
      } catch (storageError) {
        console.error('Failed to save error recovery data:', storageError);
      }
      onError(error instanceof Error ? error : new Error(String(error)));
    }
    
    return () => {
      try {
        console.log('HeroSection component unmounted');
      } catch (error) {
        console.error('Error in HeroSection cleanup:', error);
      }
    };
  }, [onError]);

  return (
    <ErrorBoundary onError={onError}>
      <section className="relative py-32 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center">
              Workflow <span className="text-synthreo-blue-medium">Automation</span>
            </h1>
            <p className="text-xl mb-8 text-gray-300 font-poppins text-center">
              Streamline your business processes and boost productivity with intelligent workflow automation solutions.
            </p>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default HeroSection;
