
import { useState, useEffect } from 'react';
import { toast } from 'sonner';

type ContentType = 'blog' | 'case-study';

interface ContentOptions {
  slug?: string;
  limit?: number;
  category?: string;
  tag?: string;
}

export interface ContentItem {
  title: string;
  date: string;
  slug: string;
  thumbnail?: string;
  description: string;
  content: string;
  author?: string;
  authorImage?: string;
  category?: string;
  tags?: string[];
  [key: string]: any;
}

const sampleBlogPosts: ContentItem[] = [
  {
    title: "Getting Started with ThreoAI",
    date: new Date().toISOString(),
    slug: "getting-started-with-threoai",
    thumbnail: "/lovable-uploads/a8308147-3201-4990-a59f-dc654ad2ab8c.png",
    author: "Synthreo Team",
    category: "Tutorials",
    tags: ["AI", "ThreoAI", "Getting Started"],
    description: "Learn how to get started with ThreoAI, our flagship AI assistant platform.",
    content: "# Getting Started with ThreoAI\n\nWelcome to ThreoAI, Synthreo's flagship AI assistant platform. This guide will help you get up and running with ThreoAI in no time.\n\n## What is ThreoAI?\n\nThreoAI is a fully managed AI assistant platform that helps businesses automate their workflows, improve customer service, and streamline operations.\n\n## Key Features\n\n- Natural language processing\n- Automated workflows\n- Integration with existing systems\n- Custom training\n- 24/7 support\n\n## How to Get Started\n\n1. **Sign up for an account** - Visit our website and sign up for a free trial.\n2. **Create your first assistant** - Use our intuitive interface to create your first AI assistant.\n3. **Train your assistant** - Upload your data and train your assistant to handle your specific use cases.\n4. **Deploy and monitor** - Deploy your assistant and monitor its performance in real-time."
  },
  {
    title: "The Future of AI in Customer Support",
    date: new Date(Date.now() - 86400000).toISOString(), // yesterday
    slug: "future-of-ai-in-customer-support",
    thumbnail: "/lovable-uploads/95b73f98-1b65-4ef6-84e9-f11a9e0145dd.png",
    author: "Jane Smith",
    category: "AI Trends",
    tags: ["AI", "Customer Support", "Future Technology"],
    description: "Explore how AI is transforming customer support experiences and what to expect in the coming years.",
    content: "# The Future of AI in Customer Support\n\nArtificial Intelligence is transforming how businesses handle customer support, creating more efficient and personalized experiences.\n\n## Current AI Implementations\n\n- Chatbots for 24/7 support\n- Automated ticket routing\n- Predictive issue resolution\n- Sentiment analysis\n\n## What's Coming Next\n\n1. **Hyper-personalization** - AI systems that remember customer preferences and history\n2. **Multimodal support** - Systems that can process text, voice, and visual inputs simultaneously\n3. **Proactive issue prevention** - AI that detects and addresses issues before customers notice them"
  },
  {
    title: "Case Study: How Company X Increased Efficiency with ThreoAI",
    date: new Date(Date.now() - 172800000).toISOString(), // 2 days ago
    slug: "case-study-company-x",
    thumbnail: "/lovable-uploads/11538f22-c1e5-417b-a69c-fb887406c2bb.png",
    author: "Michael Johnson",
    category: "Case Studies",
    tags: ["Case Study", "Success Story", "Workflow Automation"],
    description: "Learn how Company X achieved a 300% increase in operational efficiency after implementing ThreoAI.",
    content: "# Case Study: How Company X Increased Efficiency with ThreoAI\n\n## Background\n\nCompany X, a mid-sized financial services provider, was struggling with manual data processing and slow customer response times.\n\n## The Challenge\n\n- Processing over 1,000 customer documents daily\n- Average response time of 48 hours\n- High error rate in manual data entry\n- Customer satisfaction scores declining\n\n## The Solution\n\nImplementation of ThreoAI for:\n\n1. Automated document processing\n2. Intelligent customer query routing\n3. 24/7 AI-powered support assistants\n\n## The Results\n\n- 300% increase in document processing speed\n- Response times reduced to under 2 hours\n- 95% reduction in data entry errors\n- Customer satisfaction increased by 40%"
  }
];

const sampleCaseStudies: ContentItem[] = [
  {
    title: "How Company X Increased Efficiency by 300%",
    company: "Company X",
    industry: "Technology",
    date: new Date().toISOString(),
    slug: "company-x-case-study",
    thumbnail: "/lovable-uploads/95b73f98-1b65-4ef6-84e9-f11a9e0145dd.png",
    logo: "/lovable-uploads/83f63612-333d-4279-aba9-16c6ff921528.png",
    challenge: "Company X was struggling with inefficient workflows and customer service processes.",
    solution: "Implemented ThreoAI to automate workflows and improve customer interactions.",
    results: "300% increase in efficiency, 50% reduction in customer service costs.",
    testimonial: "ThreoAI has completely transformed our business. We can now handle more customers with fewer resources.",
    testimonialAuthor: "John Doe",
    testimonialRole: "CTO, Company X",
    description: "Learn how Company X achieved dramatic efficiency improvements with Synthreo's AI solutions.",
    content: "# How Company X Increased Efficiency by 300%\n\n## The Challenge\n\nCompany X, a leading technology company, was struggling with inefficient workflows and customer service processes. Their manual processes were time-consuming and error-prone, leading to delays and customer dissatisfaction.\n\n## The Solution\n\nSynthreo worked with Company X to implement ThreoAI, a fully managed AI assistant platform that automates workflows and improves customer interactions."
  }
];

/**
 * Custom hook to fetch content from the generated JSON files with better error handling and fallbacks
 */
export const useContentData = (
  contentType: ContentType,
  options: ContentOptions = {}
) => {
  const [data, setData] = useState<ContentItem[]>([]);
  const [item, setItem] = useState<ContentItem | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // If slug is provided, fetch a single item
        if (options.slug) {
          try {
            const response = await fetch(`/content/${contentType}-${options.slug}.json`);
            
            if (!response.ok) {
              throw new Error(`Failed to fetch ${contentType}: ${response.statusText}`);
            }
            
            const responseText = await response.text();
            
            // Check if response is valid JSON before parsing
            try {
              const content = JSON.parse(responseText);
              setItem(content);
              setData([]);
            } catch (parseError) {
              console.error('Invalid JSON response:', responseText.substring(0, 100) + '...');
              throw new Error('Invalid JSON response from server');
            }
          } catch (fetchError) {
            console.error(`Error fetching ${contentType} item:`, fetchError);
            
            // For blog posts, use fallback data if available
            if (contentType === 'blog') {
              const fallbackItem = sampleBlogPosts.find(post => post.slug === options.slug);
              if (fallbackItem) {
                console.log('Using fallback blog post data');
                setItem(fallbackItem);
              } else {
                throw fetchError;
              }
            } else if (contentType === 'case-study') {
              const fallbackItem = sampleCaseStudies.find(study => study.slug === options.slug);
              if (fallbackItem) {
                console.log('Using fallback case study data');
                setItem(fallbackItem);
              } else {
                throw fetchError;
              }
            } else {
              throw fetchError;
            }
          }
        } else {
          // Otherwise fetch the list
          try {
            const file = contentType === 'blog' ? 'blog-posts.json' : 'case-studies.json';
            const response = await fetch(`/content/${file}`);
            
            if (!response.ok) {
              throw new Error(`Failed to fetch ${contentType} list: ${response.statusText}`);
            }
            
            const responseText = await response.text();
            
            // Check if response is valid JSON before parsing
            try {
              let content = JSON.parse(responseText);
              
              // Ensure content is an array
              if (!Array.isArray(content)) {
                console.error('Expected array but got:', typeof content);
                content = [];
              }
              
              // Apply filters if provided
              if (options.category) {
                content = content.filter(
                  (item: ContentItem) => item.category === options.category
                );
              }
              
              if (options.tag) {
                content = content.filter(
                  (item: ContentItem) => item.tags && item.tags.includes(options.tag)
                );
              }
              
              // Apply limit if provided
              if (options.limit && options.limit > 0) {
                content = content.slice(0, options.limit);
              }
              
              setData(content);
              setItem(null);
            } catch (parseError) {
              console.error('Invalid JSON response:', responseText.substring(0, 100) + '...');
              throw new Error('Invalid JSON response from server');
            }
          } catch (fetchError) {
            console.error(`Error fetching ${contentType} list:`, fetchError);
            
            // Use fallback data
            if (contentType === 'blog') {
              console.log('Using fallback blog posts data');
              let fallbackData = [...sampleBlogPosts];
              
              // Apply filters to fallback data
              if (options.category) {
                fallbackData = fallbackData.filter(item => item.category === options.category);
              }
              
              if (options.tag) {
                fallbackData = fallbackData.filter(
                  item => item.tags && item.tags.includes(options.tag)
                );
              }
              
              // Apply limit if provided
              if (options.limit && options.limit > 0) {
                fallbackData = fallbackData.slice(0, options.limit);
              }
              
              setData(fallbackData);
            } else if (contentType === 'case-study') {
              console.log('Using fallback case studies data');
              setData(sampleCaseStudies);
            } else {
              setData([]);
            }
          }
        }
      } catch (err) {
        console.error('Error fetching content:', err);
        setError(err instanceof Error ? err : new Error(String(err)));
        
        // Provide fallback empty data
        if (options.slug) {
          setItem(null);
        } else {
          setData([]);
        }
        
        // Display a toast notification
        toast.error('Error loading content. Using sample data instead.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [contentType, options.slug, options.limit, options.category, options.tag]);

  return { data, item, loading, error };
};
