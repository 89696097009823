
import React from 'react';
import { Button } from '@/components/ui/button';

const SolutionOverview = () => {
  return (
    <section id="solution-overview" className="py-24 bg-synthreo-black relative">
      <div className="absolute inset-0 z-0">
        <div className="absolute right-1/3 top-1/2 h-[400px] w-[400px] -translate-y-1/2 rounded-full bg-synthreo-blue-darkest/10 blur-3xl" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <div className="lg:w-1/2">
            <h2 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
              Introducing Synthreo: <span className="text-gradient">Your AI Workforce Partner</span>
            </h2>
            <p className="text-xl text-gray-300 mb-8 font-poppins">
              We provide cutting-edge AI agent solutions that empower your business to automate routine tasks, 
              enhance decision-making, and create a seamless collaboration between human teams and digital labor.
            </p>
            <Button
              className="btn-glow rounded-lg px-8 py-4 text-base font-medium text-white shadow-lg h-auto font-gilroy"
              asChild
            >
              <a href="#use-cases">See AI in Action</a>
            </Button>
          </div>
          
          <div className="lg:w-1/2 relative">
            <div className="relative z-10 rounded-xl overflow-hidden border border-synthreo-blue-dark/20 shadow-lg">
              <img 
                src="/lovable-uploads/83f63612-333d-4279-aba9-16c6ff921528.png" 
                alt="Synthreo Platform Interface" 
                className="w-full h-auto"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-synthreo-black to-transparent opacity-30"></div>
            </div>
            <div className="absolute -bottom-6 -right-6 h-64 w-64 rounded-full bg-synthreo-blue-dark/10 blur-2xl z-0"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionOverview;
