
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Building, GraduationCap, LifeBuoy, Users, Users2 } from 'lucide-react';

const CompanyButton = () => {
  const [companyHoverOpen, setCompanyHoverOpen] = useState(false);

  return (
    <HoverCard open={companyHoverOpen} onOpenChange={setCompanyHoverOpen} openDelay={0} closeDelay={0}>
      <HoverCardTrigger asChild>
        <button
          onClick={() => setCompanyHoverOpen(prev => !prev)}
          className="bg-transparent border border-synthreo-blue-medium/20 text-white hover:bg-synthreo-black-light hover:border-synthreo-blue-medium hover:text-synthreo-blue-medium font-poppins px-4 py-2 rounded-md text-sm font-medium transition-colors"
        >
          Company
        </button>
      </HoverCardTrigger>
      <HoverCardContent 
        className="bg-synthreo-black-light border-synthreo-black-light w-80 p-0"
        align="end"
      >
        <div className="flex flex-col">
          <Link 
            to="/company/about" 
            className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
            onClick={() => setCompanyHoverOpen(false)}
          >
            <Building size={18} className="text-blue-400 mt-0.5" />
            <div className="flex flex-col items-start">
              <span className="font-medium">About Synthreo</span>
              <span className="text-xs text-gray-400">Learn our story and mission</span>
            </div>
          </Link>
          <Link 
            to="/company/leadership" 
            className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
            onClick={() => setCompanyHoverOpen(false)}
          >
            <Users size={18} className="text-blue-400 mt-0.5" />
            <div className="flex flex-col items-start">
              <span className="font-medium">Leadership</span>
              <span className="text-xs text-gray-400">Meet our leadership team</span>
            </div>
          </Link>
          <Link 
            to="/company/careers" 
            className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
            onClick={() => setCompanyHoverOpen(false)}
          >
            <GraduationCap size={18} className="text-blue-400 mt-0.5" />
            <div className="flex flex-col items-start">
              <span className="font-medium">Careers</span>
              <span className="text-xs text-gray-400">Join our growing team</span>
            </div>
          </Link>
          <Link 
            to="/partners" 
            className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
            onClick={() => setCompanyHoverOpen(false)}
          >
            <Users2 size={18} className="text-blue-400 mt-0.5" />
            <div className="flex flex-col items-start">
              <span className="font-medium">Partners</span>
              <span className="text-xs text-gray-400">Our partner ecosystem</span>
            </div>
          </Link>
          <Link 
            to="/company/support" 
            className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
            onClick={() => setCompanyHoverOpen(false)}
          >
            <LifeBuoy size={18} className="text-blue-400 mt-0.5" />
            <div className="flex flex-col items-start">
              <span className="font-medium">Support</span>
              <span className="text-xs text-gray-400">Get help with our products</span>
            </div>
          </Link>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

export default CompanyButton;
