
import React from 'react';
import ErrorBoundary from '@/components/ErrorBoundary';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import SuccessStoryCard from './SuccessStoryCard';

interface SuccessStoriesSectionProps {
  onError: (error: Error) => void;
}

const SuccessStoriesSection: React.FC<SuccessStoriesSectionProps> = ({ onError }) => {
  return (
    <ErrorBoundary onError={onError}>
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center">
              Success Stories
            </h2>
            <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto font-poppins text-center">
              Discover how organizations are transforming their operations with Synthreo Workflow Automation solutions.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <SuccessStoryCard 
                title="Leading Logistics Company"
                benefits={[
                  "40% reduction in order processing time",
                  "Automated shipping and delivery workflows, improving efficiency and reducing errors"
                ]}
              />

              <SuccessStoryCard 
                title="Global Retail Chain"
                benefits={[
                  "25% increase in employee productivity",
                  "Automated inventory management and supply chain processes, optimizing operations and reducing costs"
                ]}
              />
            </div>

            <div className="mt-10 text-center">
              <Button asChild className="btn-glow text-white">
                <Link to="/resources/case-studies">View All Case Studies</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default SuccessStoriesSection;
