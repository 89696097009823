
import React from 'react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Shield, ShieldCheck, Lock } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

const SecuritySection = () => {
  return (
    <section id="security-section" className="py-24 bg-synthreo-black relative overflow-hidden">
      <div className="absolute inset-0 z-0">
        <div className="absolute right-0 top-0 h-[500px] w-[500px] rounded-full bg-synthreo-blue-darkest/5 blur-3xl transform translate-x-1/4 -translate-y-1/4" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
            Enterprise-grade security and privacy
          </h2>
          <div className="w-24 h-1 bg-synthreo-blue-medium mx-auto mb-6"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {/* Security Card 1 */}
          <Card className="bg-synthreo-black border border-synthreo-blue-dark/20 shadow-md hover:shadow-synthreo-blue-dark/20 transition-all duration-300">
            <CardHeader className="pb-2">
              <div className="flex items-center mb-4">
                <Shield className="h-10 w-10 text-synthreo-blue-medium mr-3" />
              </div>
              <CardTitle className="text-2xl font-bold text-white font-gilroy">
                Robust Security Measures
              </CardTitle>
              <div className="w-16 h-0.5 bg-synthreo-blue-dark mt-4"></div>
            </CardHeader>
            <CardContent className="pt-6">
              <p className="text-gray-300 mb-6 font-poppins">
                Our platform implements cutting-edge security technologies and best practices
                to protect your data and AI models.
              </p>
              <Link to="/security" className="text-synthreo-blue-medium hover:text-synthreo-blue-light transition-colors underline font-medium">
                Learn more about our security here
              </Link>
            </CardContent>
          </Card>

          {/* Security Card 2 */}
          <Card className="bg-synthreo-black border border-synthreo-blue-dark/20 shadow-md hover:shadow-synthreo-blue-dark/20 transition-all duration-300">
            <CardHeader className="pb-2">
              <div className="flex items-center mb-4">
                <Lock className="h-10 w-10 text-synthreo-blue-medium mr-3" />
              </div>
              <CardTitle className="text-2xl font-bold text-white font-gilroy">
                Compliance with Industry Standards
              </CardTitle>
              <div className="w-16 h-0.5 bg-synthreo-blue-dark mt-4"></div>
            </CardHeader>
            <CardContent className="pt-6">
              <p className="text-gray-300 mb-6 font-poppins">
                We adhere to GDPR, CCPA, and other data privacy regulations, ensuring your AI
                models are built and maintained in a secure and compliant environment.
              </p>
            </CardContent>
          </Card>

          {/* Security Card 3 */}
          <Card className="bg-synthreo-black border border-synthreo-blue-dark/20 shadow-md hover:shadow-synthreo-blue-dark/20 transition-all duration-300">
            <CardHeader className="pb-2">
              <div className="flex items-center mb-4">
                <ShieldCheck className="h-10 w-10 text-synthreo-blue-medium mr-3" />
              </div>
              <CardTitle className="text-2xl font-bold text-white font-gilroy">
                Commitment to AI Ethics
              </CardTitle>
              <div className="w-16 h-0.5 bg-synthreo-blue-dark mt-4"></div>
            </CardHeader>
            <CardContent className="pt-6">
              <p className="text-gray-300 mb-6 font-poppins">
                Our commitment to ethical AI usage extends beyond just technological
                implementations. By partnering with us, you can trust that your AI solutions
                align with ethical principles and guidelines.
              </p>
            </CardContent>
          </Card>
        </div>

        <div className="text-center">
          <Button
            className="btn-glow rounded-lg px-8 py-4 text-base font-medium text-white shadow-lg h-auto font-gilroy"
            asChild
          >
            <Link to="/security">Learn More About Our Security Protocols</Link>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SecuritySection;
