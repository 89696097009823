
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { formSchema as contactFormSchema, FormValues as ContactFormValues } from './contactFormSchema';

// Define union type for all form types
export type FormType = 'synthreo-contact' | 'synthreo-signup';

// Define a type to map form names to their value types
export type FormValueMap = {
  'synthreo-contact': ContactFormValues;
  'synthreo-signup': ContactFormValues;
}

// Create a generic type to handle different form schemas
export function useNetlifyForm<T extends FormType>(formName: T) {
  type FormValues = FormValueMap[T];
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  
  // Select the appropriate schema based on form name
  const getFormConfig = () => {
    switch(formName) {
      case 'synthreo-signup':
      case 'synthreo-contact':
      default:
        return {
          schema: contactFormSchema,
          defaultValues: {
            name: "",
            email: "",
            message: "",
            agreeToTerms: false
          } as ContactFormValues
        };
    }
  };
  
  const { schema, defaultValues } = getFormConfig();
  
  // Create form with proper typing
  const form = useForm<FormValues>({
    resolver: zodResolver(schema as any),
    defaultValues: defaultValues as any
  });

  const onSubmit = async (data: FormValues) => {
    try {
      setIsSubmitting(true);
      console.log('Form data to be submitted directly to Netlify:', data);
      
      // Prepare the form data with proper encoding for Netlify
      const formData = new FormData();
      
      // Add the form name (required for Netlify)
      formData.append('form-name', formName);
      
      // Add all other form fields
      Object.entries(data).forEach(([key, value]) => {
        // Convert boolean values to strings for Netlify
        if (typeof value === 'boolean') {
          formData.append(key, value ? 'true' : 'false');
        } else if (value !== undefined && value !== null) {
          formData.append(key, value as string);
        }
      });
      
      // Submit directly to Netlify
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      console.log('Form submitted successfully to Netlify');
      toast.success("Form submitted successfully!");
      
      // Reset form
      form.reset();
      
      // Handle specific form redirects
      if (formName === 'synthreo-contact') {
        navigate("/company/contact-success");
      } else if (formName === 'synthreo-signup') {
        navigate("/auth/signup?signup=success");
      }
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    form,
    isSubmitting,
    onSubmit
  };
}
