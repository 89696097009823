
import React from 'react';
import { Toaster } from 'sonner';
import ErrorBoundary from '../ErrorBoundary';
import CookieConsent from '../CookieConsent';
import CookieSettings from '../CookieSettings';
import ScrollToTop from '../ScrollToTop';
import LoadingFallback from '../common/LoadingFallback';
import { useAppInitialization } from '../../hooks/useAppInitialization';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { isAppReady } = useAppInitialization();

  if (!isAppReady) {
    return <LoadingFallback />;
  }

  return (
    <ErrorBoundary>
      <div className="App">
        <ScrollToTop />
        <CookieConsent />
        <CookieSettings />
        {children}
        <Toaster position="top-right" />
      </div>
    </ErrorBoundary>
  );
};

export default AppLayout;
