
import React, { useState, useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import Contact from '@/components/Contact';
import { useContentData } from '@/hooks/useContentData';
import BlogCard from '@/components/blog/BlogCard';
import SEOMetaTags from '@/components/SEOMetaTags';
import { Search, Filter, ChevronRight, ChevronLeft, AlertCircle } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';

const POSTS_PER_PAGE = 9;
const ALL_CATEGORIES = ['All', 'AI Trends', 'Case Studies', 'Product Updates', 'Industry Insights', 'Tutorials'];

const Blog = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  
  const { data: posts = [], loading, error } = useContentData('blog');
  
  // Show toast on error
  useEffect(() => {
    if (error) {
      toast.error('Error loading articles. Showing sample content instead.');
    }
  }, [error]);
  
  // Filter posts based on search and category
  const filteredPosts = Array.isArray(posts) ? posts.filter(post => {
    if (!post) return false;
    const matchesSearch = post.title?.toLowerCase().includes(searchTerm.toLowerCase()) || 
                         post.description?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'All' || post.category === selectedCategory;
    return matchesSearch && matchesCategory;
  }) : [];
  
  // Calculate pagination
  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);
  const indexOfLastPost = currentPage * POSTS_PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - POSTS_PER_PAGE;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);
  
  // SEO metadata
  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "headline": "Synthreo AI Blog | Latest Insights on AI Technology",
    "description": "Explore the latest articles, insights, and tutorials on AI technology, ThreoAgents, and workflow automation.",
    "publisher": {
      "@type": "Organization",
      "name": "Synthreo",
      "logo": {
        "@type": "ImageObject",
        "url": "https://synthreo.ai/favicon.svg"
      }
    },
    "blogPost": Array.isArray(posts) ? posts.slice(0, 10).map(post => ({
      "@type": "BlogPosting",
      "headline": post?.title || "",
      "description": post?.description || "",
      "datePublished": post?.date || "",
      "author": {
        "@type": "Person",
        "name": post?.author || "Synthreo Team"
      },
      "url": `https://synthreo.ai/resources/blog/${post?.slug || ""}`
    })) : []
  };
  
  return (
    <div className="min-h-screen bg-synthreo-black text-white flex flex-col">
      <SEOMetaTags 
        title="Synthreo AI Blog | Latest Insights on AI Technology"
        description="Explore the latest articles, insights, and tutorials on AI technology, ThreoAgents, and workflow automation from Synthreo's experts."
        url="https://synthreo.ai/resources/blog"
        canonical="https://synthreo.ai/resources/blog"
        keywords="AI blog, ThreoAgents, AI insights, workflow automation, AI technology, Synthreo blog"
        schema={blogSchema}
      />
      <Navbar />
      <main className="flex-grow pt-24">
        {/* Hero Section */}
        <section className="bg-synthreo-black-light/30 py-16 px-4">
          <div className="container mx-auto max-w-5xl">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-center">
              <span className="text-gradient">Synthreo</span> Blog
            </h1>
            <p className="text-xl text-center text-gray-300 mb-8 max-w-3xl mx-auto">
              Explore the latest insights, tutorials, and news about AI technology and Synthreo products
            </p>
            
            {/* Search and Filter */}
            <div className="flex flex-col md:flex-row gap-4 items-center justify-center mb-8">
              <div className="relative w-full max-w-md">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
                <Input
                  type="text"
                  placeholder="Search articles..."
                  className="pl-10 bg-synthreo-black-dark border-synthreo-blue-dark/30 focus:border-synthreo-blue-medium"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
              <div className="flex items-center space-x-2">
                <Filter size={18} className="text-gray-400" />
                <div className="flex flex-wrap gap-2">
                  {ALL_CATEGORIES.map(category => (
                    <button
                      key={category}
                      className={`px-3 py-1 rounded-full text-sm ${
                        selectedCategory === category
                          ? 'bg-synthreo-blue-medium text-white'
                          : 'bg-synthreo-black-dark text-gray-300 hover:bg-synthreo-black-light'
                      }`}
                      onClick={() => {
                        setSelectedCategory(category);
                        setCurrentPage(1);
                      }}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Blog Posts */}
        <section className="py-16 px-4">
          <div className="container mx-auto max-w-6xl">
            {loading ? (
              <div className="text-center py-16">
                <div className="inline-block animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-synthreo-blue-medium mb-4"></div>
                <p className="text-xl text-gray-300">Loading articles...</p>
              </div>
            ) : error ? (
              <div className="text-center py-8 mb-8 bg-synthreo-black-light/50 rounded-lg border border-amber-500/20">
                <AlertCircle className="h-12 w-12 text-amber-500 mx-auto mb-2" />
                <p className="text-xl text-amber-200 mb-2">We're having trouble loading the blog content.</p>
                <p className="text-gray-300 mb-4">Showing sample articles instead.</p>
              </div>
            ) : filteredPosts.length === 0 ? (
              <div className="text-center py-16">
                <p className="text-xl text-gray-300">No articles found. Try adjusting your search.</p>
              </div>
            ) : (
              <>
                {/* Featured Post (first post) */}
                {currentPage === 1 && filteredPosts.length > 0 && (
                  <div className="mb-12">
                    <BlogCard post={filteredPosts[0]} featured />
                  </div>
                )}
                
                {/* Regular Posts Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {currentPosts.slice(currentPage === 1 && filteredPosts.length > 0 ? 1 : 0).map((post) => (
                    <BlogCard key={post.slug} post={post} />
                  ))}
                </div>
                
                {/* Pagination */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-12">
                    <div className="flex items-center space-x-2">
                      <Button 
                        variant="outline" 
                        size="sm"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        className="text-gray-300 border-gray-600"
                      >
                        <ChevronLeft size={16} className="mr-1" />
                        Prev
                      </Button>
                      
                      {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                        <Button
                          key={page}
                          variant={currentPage === page ? "default" : "outline"}
                          size="sm"
                          onClick={() => setCurrentPage(page)}
                          className={currentPage === page 
                            ? "bg-synthreo-blue-medium hover:bg-synthreo-blue-dark" 
                            : "text-gray-300 border-gray-600"}
                        >
                          {page}
                        </Button>
                      ))}
                      
                      <Button 
                        variant="outline" 
                        size="sm"
                        disabled={currentPage === totalPages}
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        className="text-gray-300 border-gray-600"
                      >
                        Next
                        <ChevronRight size={16} className="ml-1" />
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </section>
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default Blog;
