
import React from 'react';

interface SubmitButtonProps {
  isSubmitting: boolean;
}

const SubmitButton = ({ isSubmitting }: SubmitButtonProps) => {
  return (
    <button
      type="submit"
      className="btn-glow w-full rounded-md py-3 text-white transition-all duration-300"
      disabled={isSubmitting}
    >
      {isSubmitting ? "Sending..." : "Send Message"}
    </button>
  );
};

export default SubmitButton;
