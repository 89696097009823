
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useAppInitialization = () => {
  const [isAppReady, setIsAppReady] = useState(false);
  
  // Safely use location with a try/catch to prevent errors outside Router context
  let location;
  try {
    location = useLocation();
  } catch (e) {
    // Silently handle error when used outside Router context
    console.log('useAppInitialization used outside Router context');
  }

  useEffect(() => {
    // Set a timeout to ensure app is marked ready even if other initialization fails
    const readyTimeout = setTimeout(() => {
      if (!isAppReady) {
        console.log('Forcing app ready state after timeout');
        setIsAppReady(true);
      }
    }, 1000);

    // Initialize app state
    try {
      console.log('Initializing app...');
      
      // Force document and root to be visible
      document.documentElement.classList.remove('js-loading');
      const root = document.getElementById('root');
      if (root) {
        root.style.visibility = 'visible';
        root.style.display = 'block';
      }
      
      // Mark app as ready
      setIsAppReady(true);
    } catch (e) {
      console.error('Error in app initialization:', e);
      // Still mark app as ready to prevent blank screen
      setIsAppReady(true);
    }

    return () => {
      clearTimeout(readyTimeout);
    };
  }, []);

  // Also run effect when location changes (if inside Router)
  useEffect(() => {
    if (location && !isAppReady) {
      console.log('Location changed, ensuring app is ready');
      setIsAppReady(true);
    }
  }, [location, isAppReady]);

  return { isAppReady };
};
