
import React from 'react';
import { Link } from 'react-router-dom';
import { HoverCardContent } from "@/components/ui/hover-card";
import { MessageSquare, Workflow, FileText } from 'lucide-react';

interface SolutionsDropdownProps {
  onClose: () => void;
}

const SolutionsDropdown = ({ onClose }: SolutionsDropdownProps) => {
  return (
    <HoverCardContent 
      className="bg-synthreo-black-light border-synthreo-black-light w-80 p-0"
      align="start"
    >
      <div className="flex flex-col">
        <Link 
          to="/solutions/ai-agents-chatbots" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <MessageSquare size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">AI Agents & Chatbots</span>
            <span className="text-xs text-gray-400">Transform customer interactions</span>
          </div>
        </Link>
        <Link 
          to="/solutions/document-processing" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <FileText size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">Document Processing</span>
            <span className="text-xs text-gray-400">Extract insights from content</span>
          </div>
        </Link>
        <Link 
          to="/solutions/workflow-automation" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <Workflow size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">Workflow Automation</span>
            <span className="text-xs text-gray-400">Streamline business processes</span>
          </div>
        </Link>
      </div>
    </HoverCardContent>
  );
};

export default SolutionsDropdown;
