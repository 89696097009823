
/**
 * Google Tag Manager and Analytics initialization
 */

/**
 * Initialize Google Tag Manager
 */
export function initializeGTM() {
  try {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]){window.dataLayer.push(args);}
    gtag('js', new Date());
    gtag('config', 'G-SQVJXYC48Q');
    
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s) as HTMLScriptElement,dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      j.crossOrigin="anonymous";
      f.parentNode?.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MN47ZGKP');
  } catch(e) {
    console.error("Error initializing Google Tag Manager:", e);
    window.gtag = function() {};
  }
}

/**
 * Update consent for Google services
 */
export function updateGTMConsent(granted = false) {
  try {
    if (granted) {
      window.dataLayer.push(['consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      }]);
    } else {
      window.dataLayer.push(['consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      }]);
    }
  } catch(e) {
    console.error("Error updating GTM consent:", e);
  }
}
