
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import {
  HeroSection,
  MarketOpportunity,
  SynthreoWay,
  ProductsForMSPs,
  RoadmapTimeline,
  AIOpportunity,
  PartnerCTA
} from '@/components/partners';
import SEOMetaTags from '@/components/SEOMetaTags';

const Partners = () => {
  // Partners page structured data
  const partnersSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "MSP Partners | Synthreo",
    "description": "Join Synthreo's partner program for MSPs and leverage AI technology to drive growth for your clients. Learn about our partnership benefits.",
    "url": "https://synthreo.ai/partners",
    "mainEntity": {
      "@type": "Service",
      "name": "Synthreo Partner Program",
      "description": "Partnership program for Managed Service Providers",
      "provider": {
        "@type": "Organization",
        "name": "Synthreo"
      }
    }
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <SEOMetaTags 
        title="MSP Partners | Synthreo Partner Program"
        description="Join Synthreo's partner program for MSPs and leverage AI technology to drive growth for your clients. Learn about our partnership benefits."
        url="https://synthreo.ai/partners"
        canonical="https://synthreo.ai/partners"
        keywords="Synthreo partners, MSP partnership, AI technology partners, managed service providers"
        schema={partnersSchema}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow">
        {/* Hero Section */}
        <HeroSection />

        {/* Market Opportunity Section */}
        <MarketOpportunity />

        {/* The Synthreo Way Section */}
        <SynthreoWay />

        {/* Products for MSPs Section */}
        <ProductsForMSPs />
        
        {/* 90-Day Roadmap Section */}
        <RoadmapTimeline />
        
        {/* AI Opportunity Section */}
        <AIOpportunity />
        
        {/* Final CTA Section */}
        <PartnerCTA />
      </main>
      <Footer />
    </div>
  );
};

export default Partners;
