
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import { saveCookieConsent } from '../../utils/cookieConsent';
import { initializeAnalytics } from '../../utils/analytics';

interface CookieDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  analyticsChecked: boolean;
  setAnalyticsChecked: (checked: boolean) => void;
  marketingChecked: boolean;
  setMarketingChecked: (checked: boolean) => void;
  setShowBanner: (show: boolean) => void;
}

const CookieDialog = ({
  open,
  setOpen,
  analyticsChecked,
  setAnalyticsChecked,
  marketingChecked,
  setMarketingChecked,
  setShowBanner
}: CookieDialogProps) => {
  const navigate = useNavigate();

  const handleAcceptAll = () => {
    saveCookieConsent('all');
    setOpen(false);
    setShowBanner(false);
    initializeAnalytics();
    toast.success("All cookies accepted");
  };

  const handleAcceptSelected = () => {
    saveCookieConsent('selected', analyticsChecked, marketingChecked);
    setOpen(false);
    setShowBanner(false);
    initializeAnalytics();
    toast.success("Cookie preferences saved");
  };

  const handleRejectAll = () => {
    saveCookieConsent('rejected');
    setOpen(false);
    setShowBanner(false);
    initializeAnalytics();
    toast.success("Cookies rejected, only essential cookies will be used");
  };

  const handleOpenPrivacyPolicy = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpen(false);
    setShowBanner(false);
    navigate('/company/privacy-policy');
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="text-xl">Cookie Consent</DialogTitle>
          <DialogDescription className="pt-2">
            To provide you with the best online experience, we use cookies and similar tracking technologies in accordance with the GDPR and applicable laws.
          </DialogDescription>
        </DialogHeader>
        
        <div className="grid gap-4 py-4">
          <p className="text-sm text-muted-foreground">
            We respect your privacy rights. You can choose which cookies you want to allow. Click "Accept All" to consent to all cookies, "Accept Selected" for your chosen preferences, or "Reject All" to use only essential cookies.
          </p>
          
          <div className="flex items-start space-x-3 space-y-0">
            <Checkbox id="essential" checked disabled />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="essential"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Essential Cookies
              </label>
              <p className="text-sm text-muted-foreground">
                These cookies are necessary for the website to function and cannot be switched off. They are usually set in response to actions made by you such as logging in or filling in forms.
              </p>
            </div>
          </div>
          
          <div className="flex items-start space-x-3 space-y-0">
            <Checkbox 
              id="analytics" 
              checked={analyticsChecked} 
              onCheckedChange={(checked) => setAnalyticsChecked(checked === true)}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="analytics"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Analytics Cookies
              </label>
              <p className="text-sm text-muted-foreground">
                These cookies help us improve our website by collecting and reporting anonymous information on how you use it. They track which pages are visited and how visitors interact with the site.
              </p>
            </div>
          </div>
          
          <div className="flex items-start space-x-3 space-y-0">
            <Checkbox 
              id="marketing" 
              checked={marketingChecked} 
              onCheckedChange={(checked) => setMarketingChecked(checked === true)}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="marketing"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Marketing Cookies
              </label>
              <p className="text-sm text-muted-foreground">
                These cookies are set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites.
              </p>
            </div>
          </div>
          
          <div className="text-xs text-muted-foreground mt-2">
            By using our website, you acknowledge you have read our {' '}
            <a href="#" onClick={handleOpenPrivacyPolicy} className="text-primary underline">
              Privacy Policy
            </a> 
            {' '} which explains how we process your personal data.
          </div>
        </div>
        <DialogFooter className="sm:justify-between flex-col sm:flex-row gap-2">
          <Button variant="outline" onClick={handleRejectAll}>
            Reject All
          </Button>
          <div className="flex gap-2">
            <Button variant="outline" onClick={handleAcceptSelected}>
              Accept Selected
            </Button>
            <Button onClick={handleAcceptAll}>
              Accept All
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CookieDialog;
