
// Schema data for the Workflow Automation page
export const workflowSchema = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Workflow Automation | Synthreo",
  "description": "Streamline your business processes and boost productivity with intelligent workflow automation solutions from Synthreo.",
  "url": "https://synthreo.ai/solutions/workflow-automation",
  "mainEntity": {
    "@type": "Service",
    "name": "Workflow Automation",
    "provider": {
      "@type": "Organization",
      "name": "Synthreo"
    },
    "description": "AI-powered workflow automation solutions that streamline business processes.",
    "offers": {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Workflow Automation Solutions"
      }
    }
  }
};
