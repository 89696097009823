
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import { Button } from '@/components/ui/button';
import { Terminal, Database, Cpu, Shield, Code, ArrowRight, Maximize2, X } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Dialog, DialogContent, DialogClose, DialogTitle } from '@/components/ui/dialog';
import { Sheet, SheetContent, SheetClose } from '@/components/ui/sheet';
import SEOMetaTags from '@/components/SEOMetaTags';

const ThreoPortal = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const isMobile = useIsMobile();
  
  const threoBuilderSchema = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "ThreoBuilder: Managed AI Agents Builder Platform",
    "applicationCategory": "BusinessApplication",
    "description": "Enterprise-grade platform for building, deploying, and managing AI agents without coding expertise. Create sophisticated managed AI solutions with drag-and-drop simplicity.",
    "offers": {
      "@type": "Offer",
      "price": "499",
      "priceCurrency": "USD"
    },
    "operatingSystem": "Web-based",
    "screenshot": "https://synthreo.ai/lovable-uploads/11538f22-c1e5-417b-a69c-fb887406c2bb.png"
  };

  useEffect(() => {
    document.title = 'ThreoBuilder: Managed AI Agents Platform | Synthreo';
  }, []);

  const handleSignUp = () => {
    navigate('/auth/signup');
  };

  const handleDemoRequest = () => {
    navigate('/company/demo');
  };

  const handleViewPricing = () => {
    navigate('/pricing');
  };

  const openImageModal = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const renderImageViewer = () => {
    if (!selectedImage) return null;

    if (isMobile) {
      return (
        <Sheet open={!!selectedImage} onOpenChange={closeImageModal}>
          <SheetContent side="bottom" className="bg-synthreo-black border-synthreo-blue-dark/30 p-0 h-[90vh] max-h-[90vh]">
            <div className="relative h-full">
              <SheetClose className="absolute right-4 top-4 z-10 bg-synthreo-black/70 rounded-full p-2">
                <X className="h-6 w-6 text-synthreo-blue-medium" />
              </SheetClose>
              <div className="h-full overflow-auto flex items-center justify-center p-2">
                <img 
                  src={selectedImage} 
                  alt="Enlarged view" 
                  className="max-w-full max-h-[85vh] w-auto h-auto object-contain"
                />
              </div>
            </div>
          </SheetContent>
        </Sheet>
      );
    }
    
    return (
      <Dialog open={!!selectedImage} onOpenChange={closeImageModal}>
        <DialogContent className="bg-synthreo-black border-synthreo-blue-dark/30 max-w-[95vw] w-[95vw] max-h-[95vh] p-0">
          <DialogTitle className="sr-only">Image Preview</DialogTitle>
          <div className="relative">
            <DialogClose className="absolute right-4 top-4 z-10 bg-synthreo-black/70 rounded-full p-2">
              <X className="h-6 w-6 text-synthreo-blue-medium" />
            </DialogClose>
            <div className="overflow-auto max-h-[90vh] flex items-center justify-center p-2">
              <img 
                src={selectedImage || ''} 
                alt="Enlarged view" 
                className="max-w-full max-h-[90vh] w-auto object-contain"
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderImageThumbnail = (imageSrc: string, alt: string, className: string = "") => {
    return (
      <div 
        className={`rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm cursor-pointer relative ${className}`}
        onClick={() => openImageModal(imageSrc)}
      >
        <div className="absolute top-2 right-2 bg-synthreo-black/50 p-1.5 rounded-full flex items-center justify-center">
          <Maximize2 size={18} className="text-synthreo-blue-medium" />
        </div>
        <img 
          src={imageSrc} 
          alt={alt} 
          className="w-full h-auto"
        />
      </div>
    );
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <ParticleBackground />
      <Navbar />
      <SEOMetaTags 
        title="ThreoBuilder: Enterprise Managed AI Agents Platform | No-Code Builder"
        description="Build, deploy and manage enterprise-grade AI agents without coding expertise. Our managed AI agent platform provides security, scalability, and seamless integration for businesses."
        url="https://synthreo.ai/products/threoportal"
        imageUrl="https://synthreo.ai/lovable-uploads/11538f22-c1e5-417b-a69c-fb887406c2bb.png"
        canonical="https://synthreo.ai/products/threoportal"
        keywords="managed AI agents, no-code AI builder, enterprise AI platform, AI agent management, chatbot builder, AI workflow automation"
        schema={threoBuilderSchema}
      />
      <main className="flex-grow">
        <section className="relative py-32 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div>
                <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-6 text-gradient-blue">
                  ThreoBuilder: Managed AI Agents Builder
                </h1>
                <p className="text-xl mb-8 text-gray-300 font-poppins">
                  Build powerful AI agents without coding expertise, backed by enterprise-grade security and flexibility.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Button 
                    className="btn-glow text-white py-6 px-8 text-lg"
                    onClick={handleSignUp}
                  >
                    Start Building
                  </Button>
                  <Button 
                    className="bg-synthreo-black-light text-white hover:bg-synthreo-black-dark py-6 px-8 text-lg"
                    onClick={handleViewPricing}
                  >
                    View Pricing
                  </Button>
                </div>
              </div>
              <div className="relative">
                {renderImageThumbnail(
                  "/lovable-uploads/11538f22-c1e5-417b-a69c-fb887406c2bb.png", 
                  "ThreoBuilder Interface", 
                  "card-glow"
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">
                Key <span className="text-synthreo-blue-medium">Features</span>
              </h2>
              <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto font-poppins">
                ThreoBuilder empowers you to create sophisticated AI agents with drag-and-drop simplicity.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Terminal size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">No-Code Interface</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Build complex AI agents without writing a single line of code using our intuitive interface.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Database size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Document Connection</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Easily connect your AI agents to your knowledge base, documents, and data sources.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Cpu size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Advanced AI Capabilities</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Leverage cutting-edge AI models with enterprise-grade performance and accuracy.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Shield size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Enterprise Security</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Built with security and compliance in mind, ensuring your data is protected at all times.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <Code size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Developer Extensions</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      For advanced users, extend your AI agents with custom code and integrations.
                    </p>
                  </CardContent>
                </Card>

                <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                  <CardContent className="p-6">
                    <div className="mb-4 text-synthreo-blue-medium">
                      <ArrowRight size={28} />
                    </div>
                    <h3 className="text-xl font-bold mb-3 font-gilroy">Rapid Deployment</h3>
                    <p className="text-gray-300 mb-4 font-poppins">
                      Deploy your AI agents to web, mobile, and messaging platforms in minutes, not months.
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">
                Visual <span className="text-synthreo-blue-medium">Configuration</span>
              </h2>
              <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto font-poppins">
                Create complex AI workflows with our intuitive visual editors
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                {renderImageThumbnail(
                  "/lovable-uploads/06130482-83c2-4f04-9e58-a49fc72ad734.png", 
                  "AI Workflow Builder"
                )}
                <div className="bg-synthreo-black-light p-4">
                  <h3 className="text-xl font-bold mb-2 font-gilroy">Visual AI Workflow Builder</h3>
                  <p className="text-gray-300 font-poppins">Design complex AI workflows with our node-based editor</p>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                {renderImageThumbnail(
                  "/lovable-uploads/6475bb08-a870-4455-b88a-acea8ed7ef14.png", 
                  "Deep Learning Editor"
                )}
                <div className="bg-synthreo-black-light p-4">
                  <h3 className="text-xl font-bold mb-2 font-gilroy">Deep Learning Editor</h3>
                  <p className="text-gray-300 font-poppins">Fine-tune neural networks with our visual deep learning configuration tool</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">
                How It <span className="text-synthreo-blue-medium">Works</span>
              </h2>
              <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto font-poppins">
                Building powerful AI agents has never been easier. Follow these simple steps:
              </p>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-6 card-glow rounded-lg">
                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-synthreo-blue-medium mb-4 mx-auto">
                    <span className="text-xl font-bold">1</span>
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Connect Your Data</h3>
                  <p className="text-gray-300 font-poppins">
                    Upload documents, connect to APIs, or integrate with your existing systems.
                  </p>
                </div>

                <div className="p-6 card-glow rounded-lg">
                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-synthreo-blue-medium mb-4 mx-auto">
                    <span className="text-xl font-bold">2</span>
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Configure Your Agent</h3>
                  <p className="text-gray-300 font-poppins">
                    Customize behavior, appearance, and responses using our drag-and-drop interface.
                  </p>
                </div>

                <div className="p-6 card-glow rounded-lg">
                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-synthreo-blue-medium mb-4 mx-auto">
                    <span className="text-xl font-bold">3</span>
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Deploy and Scale</h3>
                  <p className="text-gray-300 font-poppins">
                    Launch your agent on your website, internal systems, or customer-facing applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-blue-darkest/30">
          <div className="max-w-5xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">
              Ready to Build Your <span className="text-synthreo-blue-medium">AI Agent</span>?
            </h2>
            <p className="text-xl mb-10 text-gray-300 font-poppins">
              Start creating powerful AI agents today with ThreoBuilder's no-code platform.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button 
                className="btn-glow text-white py-6 px-8 text-lg"
                onClick={handleSignUp}
              >
                Sign Up Free
              </Button>
              <Button 
                variant="outline"
                className="border-synthreo-blue-medium/40 text-synthreo-blue-medium py-6 px-8 text-lg"
                onClick={handleDemoRequest}
              >
                Request Demo
              </Button>
            </div>
          </div>
        </section>

        <Contact />
      </main>
      <Footer />

      {renderImageViewer()}
    </div>
  );
};

export default ThreoPortal;
