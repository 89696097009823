import React from 'react';
import { MessageCircle } from 'lucide-react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

const PricingFAQ = () => {
  const faqs = [
    {
      question: "What is a Managed AI Agent?",
      answer: "A Managed AI Agent is a fully supported digital assistant built, deployed, and maintained by Synthreo. Our team handles setup, training, integration, and ongoing optimization — so your business can benefit from automation without technical overhead."
    },
    {
      question: "What's the difference between a ThreoAgent and a ThreoAssistant?",
      answer: "ThreoAgents handle full conversations and decision-making, like answering questions, routing tasks, or collecting inputs. ThreoAssistants power specific workflow tasks behind the scenes — such as submitting forms, checking systems, or triggering actions."
    },
    {
      question: 'How do you define a "conversation"?',
      answer: "A conversation is any single, continuous interaction between a user and a ThreoAgent. Conversations may include multiple messages, but are counted per session or completed interaction."
    },
    {
      question: "What happens if I exceed my monthly conversation limit?",
      answer: "You'll receive a notification if you're approaching your plan's limit. If you go over, we'll continue service without interruption and reach out to discuss plan scaling or flexible overage pricing."
    },
    {
      question: "Can I upgrade or downgrade my plan?",
      answer: "Yes, you can switch plans at any time. Whether you're growing into new workflows or scaling back, we'll help transition your AI agents smoothly with no data loss."
    },
    {
      question: "Do you offer a free trial?",
      answer: "We offer personalized demos and proof-of-concept evaluations to help you see agent value in action before committing to a plan. Contact us to explore trial options."
    },
    {
      question: "What types of business tasks can Synthreo AI agents automate?",
      answer: "Common use cases include handling employee requests, customer support, HR onboarding, order status updates, policy lookups, and more. We work with you to define and deploy automation where it matters most."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards and ACH transfers. Custom billing or invoicing can be arranged for Enterprise accounts."
    },
    {
      question: "Is there a setup fee?",
      answer: "No, all setup and onboarding is included in your monthly plan pricing. Enterprise customers with advanced needs may require a scoped implementation — we'll outline that clearly in advance."
    }
  ];

  return (
    <section className="py-12 md:py-16 bg-synthreo-black">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center gap-3 mb-8">
          <MessageCircle size={24} className="text-synthreo-blue-light" />
          <h2 className="text-2xl md:text-3xl font-bold font-gilroy text-center">
            FAQs
          </h2>
        </div>
        
        <div className="max-w-4xl mx-auto">
          <Accordion type="single" collapsible className="space-y-4">
            {faqs.map((faq, index) => (
              <AccordionItem 
                key={index} 
                value={`item-${index}`}
                className="border-b border-gray-700 pb-4 last:border-b-0"
              >
                <AccordionTrigger className="text-lg font-semibold hover:no-underline">
                  <span className="text-left">
                    {faq.question}
                  </span>
                </AccordionTrigger>
                <AccordionContent className="text-gray-300 leading-relaxed pt-2">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default PricingFAQ;
