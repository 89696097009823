
import React, { useEffect } from 'react';
import SolutionLayout from '@/components/layouts/SolutionLayout';
import ErrorBoundary from '@/components/ErrorBoundary';
import HeroSection from '@/components/workflow/HeroSection';
import WorkflowBuildingSection from '@/components/workflow/WorkflowBuildingSection';
import SuccessStoriesSection from '@/components/workflow/SuccessStoriesSection';
import { workflowSchema } from '@/components/workflow/schemaData';

const WorkflowAutomation = () => {
  // Error handler for the whole component
  const handleComponentError = (error: Error) => {
    console.error('WorkflowAutomation component error:', error);
    // Additional error handling logic if needed
  };

  // Create helper function to log and detect errors
  const createErrorBoundaryLogger = (sectionName: string) => {
    return (error: Error) => {
      console.error(`WorkflowAutomation ${sectionName} section error:`, error);
    };
  };

  // Add logging to help debug the blank screen issue
  useEffect(() => {
    console.log('WorkflowAutomation component mounted');
    
    // Log initial render state
    console.log('DOM ready state:', document.readyState);
    console.log('Current location:', window.location.pathname);
    
    return () => {
      console.log('WorkflowAutomation component unmounted');
    };
  }, []);

  return (
    <ErrorBoundary onError={handleComponentError}>
      <SolutionLayout 
        title="Workflow Automation | Synthreo"
        description="Streamline your business processes and boost productivity with intelligent workflow automation solutions from Synthreo."
        url="https://synthreo.ai/solutions/workflow-automation"
        canonical="https://synthreo.ai/solutions/workflow-automation"
        keywords="workflow automation, process optimization, business automation, document automation, AI workflow"
        schema={workflowSchema}
      >
        <div className="workflow-automation-page">
          {/* Each section wrapped in its own ErrorBoundary for fault isolation */}
          <ErrorBoundary onError={createErrorBoundaryLogger("Hero")}>
            <HeroSection onError={createErrorBoundaryLogger("Hero")} />
          </ErrorBoundary>
          
          <ErrorBoundary onError={createErrorBoundaryLogger("Building Workflow")}>
            <WorkflowBuildingSection onError={createErrorBoundaryLogger("Building Workflow")} />
          </ErrorBoundary>
          
          <ErrorBoundary onError={createErrorBoundaryLogger("Success Stories")}>
            <SuccessStoriesSection onError={createErrorBoundaryLogger("Success Stories")} />
          </ErrorBoundary>
        </div>
      </SolutionLayout>
    </ErrorBoundary>
  );
};

export default WorkflowAutomation;
