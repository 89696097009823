
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { ArrowLeft, RefreshCw } from "lucide-react";

const NotFound = () => {
  const location = useLocation();
  const [diagnosticInfo, setDiagnosticInfo] = useState<string>("");

  useEffect(() => {
    // Collect diagnostic information
    const routeInfo = `Current route: ${location.pathname}${location.search}`;
    const referrerInfo = `Referrer: ${document.referrer || "Unknown"}`;
    const browserInfo = `Browser: ${navigator.userAgent}`;
    
    const diagnostics = [routeInfo, referrerInfo, browserInfo].join('\n');
    setDiagnosticInfo(diagnostics);
    
    // Log detailed error for monitoring
    console.error(
      "404 Error: User attempted to access non-existent route:",
      {
        path: location.pathname,
        search: location.search,
        referrer: document.referrer,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent
      }
    );
    
    // Show toast notification
    toast.error("Page not found. Redirecting to home page in 10 seconds...");
    
    // Auto-redirect after 10 seconds
    const redirectTimer = setTimeout(() => {
      window.location.href = "/";
    }, 10000);
    
    return () => clearTimeout(redirectTimer);
  }, [location.pathname, location.search]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-synthreo-black text-white p-4">
      <div className="max-w-md w-full bg-synthreo-black-light rounded-lg shadow-lg p-8 text-center">
        <h1 className="text-6xl font-bold text-synthreo-blue-medium mb-4">404</h1>
        <h2 className="text-2xl font-semibold mb-6">Page Not Found</h2>
        <p className="text-gray-300 mb-8">
          The page you are looking for doesn't exist or has been moved.
        </p>
        
        <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8">
          <Link 
            to="/"
            className="flex items-center justify-center gap-2 px-6 py-3 bg-synthreo-blue-medium text-white rounded-md hover:bg-synthreo-blue-dark transition-colors"
          >
            <ArrowLeft size={18} />
            <span>Return Home</span>
          </Link>
          
          <button
            onClick={() => window.location.reload()}
            className="flex items-center justify-center gap-2 px-6 py-3 bg-synthreo-black-dark border border-gray-700 text-white rounded-md hover:bg-gray-800 transition-colors"
          >
            <RefreshCw size={18} />
            <span>Refresh Page</span>
          </button>
        </div>
        
        <details className="text-left mt-6">
          <summary className="cursor-pointer text-sm text-gray-400 hover:text-synthreo-blue-light transition-colors">
            Technical Information
          </summary>
          <pre className="mt-2 p-4 bg-synthreo-black-dark rounded text-xs text-gray-300 overflow-auto whitespace-pre-wrap">
            {diagnosticInfo}
          </pre>
        </details>
      </div>
    </div>
  );
};

export default NotFound;
