
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Hero from '@/components/Hero';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import ProblemStatement from '@/components/home/ProblemStatement';
import SolutionOverview from '@/components/home/SolutionOverview';
import KeyBenefits from '@/components/home/KeyBenefits';
import UseCases from '@/components/home/UseCases';
import SocialProof from '@/components/home/SocialProof';
import SecuritySection from '@/components/home/SecuritySection';
import FinalCTA from '@/components/home/FinalCTA';
import SEOMetaTags from '@/components/SEOMetaTags';

const Index = () => {
  // Homepage structured data
  const homePageSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Managed AI Agents for Business | Synthreo",
    "description": "Managed AI Agents for businesses to streamline operations, enhance customer interactions, and improve decision-making.",
    "url": "https://synthreo.ai",
    "mainEntity": {
      "@type": "Organization",
      "name": "Synthreo",
      "url": "https://synthreo.ai",
      "logo": "https://synthreo.ai/favicon.svg",
      "description": "Managed AI Agents for businesses to streamline operations, enhance customer interactions, and improve decision-making.",
      "offers": {
        "@type": "AggregateOffer",
        "offers": [
          {
            "@type": "Offer",
            "name": "AI Agents & Chatbots",
            "description": "Custom AI agents and chatbots that understand your business context and workflows.",
            "url": "https://synthreo.ai/solutions/ai-agents-chatbots"
          },
          {
            "@type": "Offer",
            "name": "Document Processing",
            "description": "Intelligent document processing systems for automated data extraction and analysis.",
            "url": "https://synthreo.ai/solutions/document-processing"
          },
          {
            "@type": "Offer",
            "name": "Workflow Automation",
            "description": "End-to-end workflow automation with AI-powered decision support.",
            "url": "https://synthreo.ai/solutions/workflow-automation"
          }
        ]
      }
    }
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white overflow-hidden">
      <SEOMetaTags 
        title="Managed AI Agents for Business"
        description="Managed AI Agents for businesses to streamline operations, enhance customer interactions, and improve decision-making."
        url="https://synthreo.ai"
        schema={homePageSchema}
        canonical="https://synthreo.ai"
        keywords="AI workforce, chatbots, document processing, workflow automation, artificial intelligence solutions"
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow w-full">
        <Hero />
        <ProblemStatement />
        <SolutionOverview />
        <KeyBenefits />
        <UseCases />
        <SocialProof />
        <SecuritySection />
        <FinalCTA />
      </main>
      <Footer />
    </div>
  );
};

export default Index;
