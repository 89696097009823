
import React from 'react';
import { TrendingUp, Users, Clock } from 'lucide-react';

const ProblemStatement = () => {
  return (
    <section id="problem-statement" className="py-24 bg-gradient-to-b from-synthreo-black to-synthreo-black-light relative">
      <div className="absolute inset-0 z-0 bg-grid-pattern opacity-5" />
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
            The Future of Work is <span className="text-gradient">Changing</span>
          </h2>
          <p className="text-xl text-gray-300 font-poppins">
            AI is rapidly transforming how businesses operate, creating both challenges and 
            unprecedented opportunities for growth and innovation.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="card-glow rounded-lg p-8 flex flex-col items-center text-center">
            <div className="h-16 w-16 rounded-full bg-synthreo-blue-darkest/30 flex items-center justify-center mb-6 blue-glow">
              <TrendingUp className="h-8 w-8 text-synthreo-blue-light" />
            </div>
            <h3 className="text-2xl font-semibold mb-4 font-gilroy">Rapid Transformation</h3>
            <p className="text-gray-300 font-poppins">
              87% of executives report accelerating their AI investments to stay competitive
            </p>
          </div>

          <div className="card-glow rounded-lg p-8 flex flex-col items-center text-center">
            <div className="h-16 w-16 rounded-full bg-synthreo-blue-darkest/30 flex items-center justify-center mb-6 blue-glow">
              <Users className="h-8 w-8 text-synthreo-blue-light" />
            </div>
            <h3 className="text-2xl font-semibold mb-4 font-gilroy">Workforce Evolution</h3>
            <p className="text-gray-300 font-poppins">
              12 million jobs could be displaced by 2027, creating the need for new operational strategies
            </p>
          </div>

          <div className="card-glow rounded-lg p-8 flex flex-col items-center text-center">
            <div className="h-16 w-16 rounded-full bg-synthreo-blue-darkest/30 flex items-center justify-center mb-6 blue-glow">
              <Clock className="h-8 w-8 text-synthreo-blue-light" />
            </div>
            <h3 className="text-2xl font-semibold mb-4 font-gilroy">Time Pressure</h3>
            <p className="text-gray-300 font-poppins">
              Companies who delay AI adoption risk falling behind competitors by up to 20% in productivity
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProblemStatement;
