
import React from 'react';
import { Zap, DollarSign, LineChart, Maximize } from 'lucide-react';
import { Button } from '@/components/ui/button';

const KeyBenefits = () => {
  const benefits = [
    {
      icon: <Zap className="h-10 w-10 text-synthreo-blue-light" />,
      title: "Increased Productivity",
      description: "Automate routine tasks and workflows, freeing your team to focus on higher-value activities that drive innovation."
    },
    {
      icon: <DollarSign className="h-10 w-10 text-synthreo-blue-light" />,
      title: "Reduced Operational Costs",
      description: "Lower expenses through efficient AI automation that works 24/7 with consistent quality and zero downtime."
    },
    {
      icon: <LineChart className="h-10 w-10 text-synthreo-blue-light" />,
      title: "Enhanced Decision Making",
      description: "Leverage advanced analytics and AI insights to make faster, data-driven decisions that improve business outcomes."
    },
    {
      icon: <Maximize className="h-10 w-10 text-synthreo-blue-light" />,
      title: "Scalable Operations",
      description: "Easily scale your AI workforce up or down based on demand, without the constraints of traditional hiring processes."
    }
  ];

  return (
    <section id="key-benefits" className="py-24 bg-gradient-to-b from-synthreo-black-light to-synthreo-black relative">
      <div className="absolute inset-0 z-0 bg-grid-pattern opacity-5" />
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
            Transform Your Business with <span className="text-gradient">AI Agents</span>
          </h2>
          <p className="text-xl text-gray-300 font-poppins">
            Our AI solutions deliver measurable results across your organization, creating immediate and long-term value.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          {benefits.map((benefit, index) => (
            <div key={index} className="card-glow rounded-lg p-8 group hover:border-synthreo-blue-dark/40 transition-all duration-300">
              <div className="flex items-start">
                <div className="mr-6 p-3 rounded-lg bg-synthreo-blue-darkest/30 blue-glow">
                  {benefit.icon}
                </div>
                <div>
                  <h3 className="text-2xl font-semibold mb-3 font-gilroy">{benefit.title}</h3>
                  <p className="text-gray-300 font-poppins">{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center">
          <Button
            className="btn-glow rounded-lg px-10 py-4 text-base font-medium text-white shadow-lg h-auto font-gilroy"
            asChild
          >
            <a href="#solutions">Explore Solutions</a>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default KeyBenefits;
