
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import Contact from '@/components/Contact';
import ParticleBackground from '@/components/ParticleBackground';
import SEOMetaTags from '@/components/SEOMetaTags';

const ContactPage = () => {
  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white overflow-hidden">
      <SEOMetaTags 
        title="Contact Synthreo | Get in Touch with Our Team"
        description="Have questions about our AI solutions? Contact Synthreo today to discuss how we can help transform your business with artificial intelligence."
        url="https://synthreo.ai/company/contact"
        canonical="https://synthreo.ai/company/contact"
        keywords="contact Synthreo, AI solutions contact, artificial intelligence support, Synthreo help"
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow w-full">
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;
