
import React from 'react';
import SolutionLayout from '@/components/layouts/SolutionLayout';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { 
  ArrowRight, 
  Bot, 
  MessageSquare, 
  BookOpen
} from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';

const AIAgentsChatbots = () => {
  // Schema for AI Agents & Chatbots page
  const agentsChatbotsSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "AI Agents & Chatbots | Synthreo",
    "description": "Transform how your business interacts with customers and manages internal knowledge with intelligent AI assistants from Synthreo.",
    "url": "https://synthreo.ai/solutions/ai-agents-chatbots",
    "mainEntity": {
      "@type": "Service",
      "name": "AI Agents & Chatbots",
      "provider": {
        "@type": "Organization",
        "name": "Synthreo"
      },
      "description": "Custom AI assistants that transform how your business interacts with customers and manages internal knowledge.",
      "offers": {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Custom AI Assistants"
        }
      }
    }
  };

  return (
    <SolutionLayout 
      title="AI Agents & Chatbots | Synthreo"
      description="Transform how your business interacts with customers and manages internal knowledge with intelligent AI assistants from Synthreo."
      url="https://synthreo.ai/solutions/ai-agents-chatbots"
      canonical="https://synthreo.ai/solutions/ai-agents-chatbots"
      keywords="AI agents, chatbots, customer interaction, knowledge management, artificial intelligence, AI assistants"
      schema={agentsChatbotsSchema}
    >
      {/* Hero Section */}
      <section className="relative py-32 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-4 text-center">
              AI Agents & <span className="text-synthreo-blue-medium">Chatbots</span>
            </h1>
            <p className="text-xl mb-8 text-gray-300 font-poppins text-center">
              Transform how your business interacts with customers and manages internal knowledge
              with intelligent AI assistants.
            </p>
          </div>
        </div>
      </section>

      {/* Building AI Agents and Chatbots */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
        <div className="max-w-7xl mx-auto">
          <div className="mb-16">
            <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6 text-center">
              Building AI Agents and Chatbots
            </h2>
            <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto text-center font-poppins">
              Create custom AI assistants that transform how your business interacts with customers and manages internal knowledge.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <div className="mb-4 text-synthreo-blue-medium text-center">
                    <Bot size={28} className="mx-auto" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Custom AI Assistants</h3>
                  <p className="text-gray-300 mb-4 font-poppins text-center">
                    Build specialized AI agents for specific business functions, from sales support to technical assistance.
                  </p>
                </CardContent>
              </Card>

              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <div className="mb-4 text-synthreo-blue-medium text-center">
                    <MessageSquare size={28} className="mx-auto" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Customer Service Automation</h3>
                  <p className="text-gray-300 mb-4 font-poppins text-center">
                    Create AI agents that handle customer inquiries 24/7, reducing response times and improving satisfaction.
                  </p>
                </CardContent>
              </Card>

              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <div className="mb-4 text-synthreo-blue-medium text-center">
                    <BookOpen size={28} className="mx-auto" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Internal Knowledge Management</h3>
                  <p className="text-gray-300 mb-4 font-poppins text-center">
                    Create AI-powered knowledge bases that make organizational expertise accessible to all team members.
                  </p>
                </CardContent>
              </Card>
            </div>

            <div className="mt-10 bg-synthreo-blue-darkest/30 rounded-lg p-6 border border-synthreo-blue-dark/20">
              <h4 className="text-lg font-bold mb-2 font-gilroy text-center">Use Case Example</h4>
              <p className="text-gray-300 mb-4 font-poppins text-center">
                An international manufacturing group implemented Synthreo's autonomous email processing AI agent, reducing processing time from 20 minutes per inquiry to just 16 seconds, while saving the equivalent of a full-time employee who can now focus on strategic tasks.
              </p>
              <div className="text-center">
                <Button asChild variant="outline" className="bg-transparent text-synthreo-blue-medium border-synthreo-blue-dark/50 hover:bg-synthreo-blue-darkest/50">
                  <Link to="/resources/case-studies/manufacturing-email-automation">Learn More</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Stories */}
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6 text-center">
              Success Stories
            </h2>
            <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto text-center font-poppins">
              See how organizations are achieving remarkable results with Synthreo AI Agents and Chatbots.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">Global Financial Services Firm</h3>
                  <div className="space-y-3 text-gray-300 font-poppins">
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>75% reduction in customer service response time</span>
                    </p>
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>Deployed AI agents to handle routine customer inquiries, freeing advisors to focus on complex client needs</span>
                    </p>
                  </div>
                </CardContent>
              </Card>

              <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
                <CardContent className="p-6">
                  <h3 className="text-xl font-bold mb-3 font-gilroy text-center">E-commerce Platform</h3>
                  <div className="space-y-3 text-gray-300 font-poppins">
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>35% increase in customer satisfaction scores</span>
                    </p>
                    <p className="flex items-start">
                      <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
                      <span>Implemented intelligent chatbots for order status, returns, and product recommendations</span>
                    </p>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="mt-10 text-center">
              <Button asChild className="btn-glow text-white">
                <Link to="/resources/case-studies">View All Case Studies</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </SolutionLayout>
  );
};

export default AIAgentsChatbots;
