
import React from 'react';

const ContactHeader = () => {
  return (
    <div className="mx-auto max-w-4xl text-center">
      <h2 className="mb-4 text-4xl font-bold leading-tight md:text-5xl">
        Get in <span className="text-gradient">Touch</span>
      </h2>
      <div className="mx-auto mb-8 h-1 w-24 bg-gradient-to-r from-synthreo-blue-darkest to-synthreo-blue-medium" />
      <p className="mb-16 text-xl text-gray-300">
        Have a question or interested in our services? Reach out to us and we'll get back to you promptly.
      </p>
    </div>
  );
};

export default ContactHeader;
