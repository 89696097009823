
import { initKoala, removeKoala } from './koala';
import { updateClarityConsent, ensureClarityAvailable } from './clarity';
import { updateGTMConsent } from './gtm';
import { hasCookieConsent } from '../cookieConsent';
import { trackFormSubmission, submitToNetlify } from './forms';

// Enable form submission debugging
if (typeof window !== 'undefined') {
  window.netlifyFormSubmissionDebug = true;
}

/**
 * Initialize all analytics tools based on current consent
 */
export const initializeAnalytics = (): void => {
  // Initialize all analytics tools that we use
  initKoala();
  updateClarityConsent();
};

/**
 * Event listener for consent changes
 */
export const setupAnalyticsConsentListeners = (): void => {
  // Listen for consent change events
  window.addEventListener('consentUpdated', () => {
    updateGTMConsent();
    
    if (hasCookieConsent('analytics')) {
      initKoala();
      updateClarityConsent(true);
    } else {
      removeKoala();
      updateClarityConsent(false);
    }
  });
};

// Re-export from individual modules
export { 
  updateGTMConsent, 
  initKoala, 
  removeKoala, 
  updateClarityConsent,
  ensureClarityAvailable,
  trackFormSubmission,
  submitToNetlify
};
