import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import { Button } from '@/components/ui/button';
import { Monitor, Shield, BarChart, Layers, Users, X, Maximize2 } from 'lucide-react';
import { Dialog, DialogContent, DialogClose, DialogTitle } from '@/components/ui/dialog';
import { Sheet, SheetContent, SheetClose } from '@/components/ui/sheet';
import ErrorBoundary from '@/components/ErrorBoundary';
import { toast } from 'sonner';

const ThreoGPT = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  
  useEffect(() => {
    document.title = 'ThreoAI Portal | Synthreo';
    
    const handleImageError = (e: ErrorEvent) => {
      if (e.target instanceof HTMLImageElement) {
        console.error('Image loading error:', e.target.src);
        toast.error('Failed to load an image. Please try refreshing the page.');
      }
    };
    
    window.addEventListener('error', handleImageError, true);
    
    return () => {
      window.removeEventListener('error', handleImageError, true);
    };
  }, []);

  const handleDemoRequest = () => {
    navigate('/company/demo');
  };

  const handleViewPricing = () => {
    navigate('/pricing');
  };

  const openImageModal = (imageSrc: string) => {
    try {
      console.log('Opening image modal for:', imageSrc);
      setSelectedImage(imageSrc);
    } catch (error) {
      console.error('Error opening image modal:', error);
      toast.error('Failed to open image preview');
    }
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const renderImageViewer = () => {
    if (!selectedImage) return null;

    try {
      if (isMobile) {
        return (
          <Sheet open={!!selectedImage} onOpenChange={closeImageModal}>
            <SheetContent side="bottom" className="bg-synthreo-black border-synthreo-blue-dark/30 p-0 h-[90vh] max-h-[90vh]">
              <div className="relative h-full">
                <SheetClose className="absolute right-4 top-4 z-10 bg-synthreo-black/70 rounded-full p-2">
                  <X className="h-6 w-6 text-synthreo-blue-medium" />
                </SheetClose>
                <div className="h-full overflow-auto flex items-center justify-center p-2">
                  <img 
                    src={selectedImage} 
                    alt="Enlarged view" 
                    className="max-w-full max-h-[85vh] w-auto h-auto object-contain"
                    onError={(e) => {
                      console.error('Image failed to load:', selectedImage);
                      (e.target as HTMLImageElement).src = '/placeholder.svg';
                    }}
                  />
                </div>
              </div>
            </SheetContent>
          </Sheet>
        );
      }
      
      return (
        <Dialog open={!!selectedImage} onOpenChange={closeImageModal}>
          <DialogContent className="bg-synthreo-black border-synthreo-blue-dark/30 max-w-[95vw] w-[95vw] max-h-[95vh] p-0">
            <DialogTitle className="sr-only">Image Preview</DialogTitle>
            <div className="relative">
              <DialogClose className="absolute right-4 top-4 z-10 bg-synthreo-black/70 rounded-full p-2">
                <X className="h-6 w-6 text-synthreo-blue-medium" />
              </DialogClose>
              <div className="overflow-auto max-h-[90vh] flex items-center justify-center p-2">
                <img 
                  src={selectedImage || ''} 
                  alt="Enlarged view" 
                  className="max-w-full max-h-[90vh] w-auto object-contain"
                  onError={(e) => {
                    console.error('Image failed to load:', selectedImage);
                    (e.target as HTMLImageElement).src = '/placeholder.svg';
                  }}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );
    } catch (error) {
      console.error('Error rendering image viewer:', error);
      closeImageModal();
      toast.error('Failed to display image preview');
      return null;
    }
  };

  const renderImageThumbnail = (imageSrc: string, alt: string, className: string = "") => {
    try {
      return (
        <div 
          className={`rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm cursor-pointer relative ${className}`}
          onClick={() => openImageModal(imageSrc)}
        >
          <div className="absolute top-2 right-2 bg-synthreo-black/50 p-1.5 rounded-full flex items-center justify-center">
            <Maximize2 size={18} className="text-synthreo-blue-medium" />
          </div>
          <img 
            src={imageSrc} 
            alt={alt} 
            className="w-full h-auto"
            onError={(e) => {
              console.error('Thumbnail image failed to load:', imageSrc);
              (e.target as HTMLImageElement).src = '/placeholder.svg';
            }}
          />
        </div>
      );
    } catch (error) {
      console.error('Error rendering image thumbnail:', error);
      return (
        <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 bg-synthreo-black-light p-4 text-center">
          <p>Image preview unavailable</p>
        </div>
      );
    }
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow">
        <section className="relative py-32 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div>
                <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-6 text-gradient-blue">
                  ThreoAI: Secure GPT-like Chat Portal
                </h1>
                <p className="text-xl mb-8 text-gray-300 font-poppins">
                  Simplified AI management and deployment with enterprise-grade security and customization.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Button 
                    className="btn-glow text-white py-6 px-8 text-lg"
                    onClick={handleDemoRequest}
                  >
                    Request a Demo
                  </Button>
                  <Button 
                    className="bg-synthreo-black-light text-white hover:bg-synthreo-black-dark py-6 px-8 text-lg"
                    onClick={handleViewPricing}
                  >
                    View Pricing
                  </Button>
                </div>
              </div>
              <ErrorBoundary>
                <div className="relative">
                  {renderImageThumbnail(
                    "/lovable-uploads/cc1ea608-dd14-48c7-b8ee-f5028b19fde4.png", 
                    "ThreoAI Portal Interface", 
                    "card-glow"
                  )}
                </div>
              </ErrorBoundary>
            </div>
          </div>
        </section>

        <section className="py-20 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold font-gilroy mb-12 text-center">Key Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
              <div className="bg-synthreo-black-light p-6 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-4 text-synthreo-blue-medium">
                  <Layers size={36} />
                </div>
                <h3 className="text-xl font-bold mb-2 font-gilroy">Fully Customizable</h3>
                <p className="text-gray-300 font-poppins">
                  White-label branding and complete UI customization to match your company's identity.
                </p>
              </div>
              <div className="bg-synthreo-black-light p-6 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-4 text-synthreo-blue-medium">
                  <Monitor size={36} />
                </div>
                <h3 className="text-xl font-bold mb-2 font-gilroy">LLM Integration</h3>
                <p className="text-gray-300 font-poppins">
                  Seamless integration with leading LLMs including GPT-4, Claude, Grok, and Hugging Face models.
                </p>
              </div>
              <div className="bg-synthreo-black-light p-6 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-4 text-synthreo-blue-medium">
                  <BarChart size={36} />
                </div>
                <h3 className="text-xl font-bold mb-2 font-gilroy">Analytics</h3>
                <p className="text-gray-300 font-poppins">
                  Comprehensive usage analytics and performance metrics to optimize your AI operations.
                </p>
              </div>
              <div className="bg-synthreo-black-light p-6 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-4 text-synthreo-blue-medium">
                  <Shield size={36} />
                </div>
                <h3 className="text-xl font-bold mb-2 font-gilroy">Enterprise Security</h3>
                <p className="text-gray-300 font-poppins">
                  SOC 2 compliant with end-to-end encryption and granular access controls.
                </p>
              </div>
              <div className="bg-synthreo-black-light p-6 rounded-lg border border-synthreo-black-light/50 hover:border-synthreo-blue-dark/30 transition-all">
                <div className="mb-4 text-synthreo-blue-medium">
                  <Users size={36} />
                </div>
                <h3 className="text-xl font-bold mb-2 font-gilroy">AI Agent Delivery</h3>
                <p className="text-gray-300 font-poppins">
                  Easily deliver and manage AI Agents for your clients through a unified interface.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-synthreo-black to-synthreo-black-light/40">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold font-gilroy mb-10 text-center text-gradient-blue">Enterprise-Grade Conversation AI for Business</h2>
            <div className="bg-synthreo-black-light/20 p-8 md:p-10 rounded-xl border border-synthreo-blue-dark/30 shadow-glow-sm">
              <p className="text-lg text-gray-300 leading-relaxed font-poppins max-w-5xl mx-auto">
                ThreoAI works just like the ChatGPT portal but is built for IT service providers and businesses that need security, flexibility, and control over their AI. 
              </p>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-gilroy font-semibold text-synthreo-blue-medium">Flexible LLM Integration</h3>
                  <p className="text-gray-300 font-poppins">
                    Unlike ChatGPT, ThreoAI lets you choose your own LLM—whether it's GPT-4, Claude, Grok, Hugging Face models, or other open-source options—so you get the right AI for your specific use case and budget.
                  </p>
                </div>
                
                <div className="space-y-4">
                  <h3 className="text-xl font-gilroy font-semibold text-synthreo-blue-medium">White-Label Customization</h3>
                  <p className="text-gray-300 font-poppins">
                    It's fully customizable with white-label branding, giving you a tailored experience that fits your business.
                  </p>
                </div>
                
                <div className="space-y-4">
                  <h3 className="text-xl font-gilroy font-semibold text-synthreo-blue-medium">Enterprise Security</h3>
                  <p className="text-gray-300 font-poppins">
                    You get enterprise-grade security with SOC 2 compliance, end-to-end encryption, and customizable access controls to ensure your sensitive data stays protected.
                  </p>
                </div>
                
                <div className="space-y-4">
                  <h3 className="text-xl font-gilroy font-semibold text-synthreo-blue-medium">Secure RAG Training</h3>
                  <p className="text-gray-300 font-poppins">
                    Train your AI securely with our Retrieval Augmented Generation technology that keeps your proprietary data encrypted and contained within your own environment, ensuring both powerful AI capabilities and complete data sovereignty.
                  </p>
                </div>
              </div>
              
              <div className="mt-8 border-t border-synthreo-blue-dark/20 pt-8">
                <p className="text-lg text-gray-300 leading-relaxed font-poppins">
                  Deploy AI agents effortlessly across multiple clients, scale without headaches, and integrate directly with ITSM tools to automate ticketing and support workflows. If you're looking for a secure, flexible AI platform that fits into your business—not the other way around—ThreoAI delivers.
                </p>
                
                <div className="mt-8 text-center">
                  <Button 
                    className="btn-glow text-white py-5 px-6"
                    onClick={handleDemoRequest}
                  >
                    See How It Works
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ErrorBoundary>
          <section className="py-20 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <h2 className="text-3xl font-bold font-gilroy mb-4 text-center">See ThreoAI in Action</h2>
              <p className="text-xl text-gray-300 mb-12 text-center max-w-3xl mx-auto font-poppins">
                Discover how ThreoAI streamlines AI management for enterprises and MSPs.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
                <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                  {renderImageThumbnail(
                    "/lovable-uploads/fa289d2d-a034-4694-bb87-18031af1ed01.png", 
                    "Contract Review GPT Interface"
                  )}
                  <div className="bg-synthreo-black-light p-4">
                    <h3 className="text-xl font-bold mb-2 font-gilroy">Custom GPT Creation</h3>
                    <p className="text-gray-300 font-poppins">Build specialized AI assistants with action-triggered function calls that automate complex tasks like legal document analysis.</p>
                  </div>
                </div>
                <div className="rounded-lg overflow-hidden border border-synthreo-blue-dark/30 shadow-glow-sm">
                  {renderImageThumbnail(
                    "/lovable-uploads/e34ba9a3-63b0-482d-a636-81c4351871d4.png", 
                    "User Profile Interface"
                  )}
                  <div className="bg-synthreo-black-light p-4">
                    <h3 className="text-xl font-bold mb-2 font-gilroy">RAG-Based Memory</h3>
                    <p className="text-gray-300 font-poppins">Enhance AI interactions with retrieval augmented generation that learns from user conversations and maintains contextual awareness.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ErrorBoundary>

        <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-synthreo-black-light to-synthreo-black">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold font-gilroy mb-6">Ready to Transform Your AI Operations?</h2>
            <p className="text-xl mb-8 text-gray-300 font-poppins">
              Get started with ThreoAI today and experience the future of AI management.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button 
                className="btn-glow text-white py-6 px-8 text-lg"
                onClick={handleDemoRequest}
              >
                Request a Demo
              </Button>
              <Button 
                className="bg-synthreo-black-light text-white hover:bg-synthreo-black-dark py-6 px-8 text-lg"
                onClick={handleViewPricing}
              >
                View Pricing
              </Button>
            </div>
            <p className="mt-6 text-gray-300">
              <span className="cursor-pointer text-synthreo-blue-medium hover:underline" onClick={handleViewPricing}>
                Check our pricing plans
              </span> to find the right solution for your business needs.
            </p>
          </div>
        </section>

        <Contact />
      </main>
      <Footer />
      
      <ErrorBoundary>
        {renderImageViewer()}
      </ErrorBoundary>
    </div>
  );
};

export default ThreoGPT;
