
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { LucideIcon } from 'lucide-react';

interface WorkflowCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({ icon: Icon, title, description }) => {
  return (
    <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
      <CardContent className="p-6">
        <div className="mb-4 text-synthreo-blue-medium text-center">
          <Icon size={28} className="mx-auto" />
        </div>
        <h3 className="text-xl font-bold mb-3 text-center">{title}</h3>
        <p className="text-gray-300 mb-4 font-poppins text-center">
          {description}
        </p>
      </CardContent>
    </Card>
  );
};

export default WorkflowCard;
