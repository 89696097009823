
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import SEOMetaTags from '@/components/SEOMetaTags';

interface SolutionLayoutProps {
  children: React.ReactNode;
  title: string;
  description: string;
  url: string;
  canonical?: string;
  keywords?: string;
  schema?: Record<string, any>;
}

const SolutionLayout: React.FC<SolutionLayoutProps> = ({
  children,
  title,
  description,
  url,
  canonical,
  keywords,
  schema
}) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white resources-content">
      <SEOMetaTags 
        title={title}
        description={description}
        url={url}
        canonical={canonical || url}
        keywords={keywords}
        schema={schema}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow pt-16">
        {children}
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default SolutionLayout;
