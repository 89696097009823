
import React from 'react';
import { Link } from 'react-router-dom';
import { HoverCardContent } from "@/components/ui/hover-card";
import { Terminal, Monitor } from 'lucide-react';

interface ProductsDropdownProps {
  onClose: () => void;
}

const ProductsDropdown = ({ onClose }: ProductsDropdownProps) => {
  return (
    <HoverCardContent 
      className="bg-synthreo-black-light border-synthreo-black-light w-80 p-0"
      align="start"
    >
      <div className="flex flex-col">
        <Link 
          to="/products/threoai" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <Monitor size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">ThreoAI</span>
            <span className="text-xs text-gray-400">Enterprise-grade AI assistant platform</span>
          </div>
        </Link>
        
        <Link 
          to="/products/threobuilder" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <Terminal size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">ThreoBuilder</span>
            <span className="text-xs text-gray-400">No-code AI solution platform</span>
          </div>
        </Link>
      </div>
    </HoverCardContent>
  );
};

export default ProductsDropdown;
