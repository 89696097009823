
import React from 'react';
import { Form } from "@/components/ui/form";
import FormNameField from './FormNameField';
import FormEmailField from './FormEmailField';
import FormMessageField from './FormMessageField';
import FormTermsField from './FormTermsField';
import SubmitButton from './SubmitButton';
import { useNetlifyForm } from './useNetlifyForm';

interface ContactFormProps {
  formName?: 'synthreo-contact' | 'synthreo-signup';
}

const ContactForm = ({ formName = 'synthreo-contact' }: ContactFormProps) => {
  const { form, isSubmitting, onSubmit } = useNetlifyForm(formName);

  return (
    <div className="rounded-lg bg-synthreo-black-light p-8">
      <Form {...form}>
        <form 
          className="space-y-6"
          onSubmit={form.handleSubmit(onSubmit)}
          data-netlify="true"
          name={formName}
        >
          {/* Hidden Netlify form fields */}
          <input type="hidden" name="form-name" value={formName} />
          <input type="hidden" name="bot-field" />
          
          <FormNameField control={form.control} />
          <FormEmailField control={form.control} />
          <FormMessageField control={form.control} />
          <FormTermsField control={form.control} />
          <SubmitButton isSubmitting={isSubmitting} />
        </form>
      </Form>
    </div>
  );
};

export default ContactForm;
