
import React from 'react';
import { TrendingUp, LineChart, BarChart2, Zap, CheckCircle, ShieldCheck } from 'lucide-react';

const AIOpportunity = () => {
  return (
    <section className="py-20 bg-synthreo-black-light relative overflow-hidden">
      <div className="absolute inset-0 z-0 bg-grid-pattern opacity-5" />
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 font-gilroy">
            The AI <span className="text-gradient">Opportunity</span> for MSPs
          </h2>
          <div className="mx-auto h-1 w-20 bg-gradient-to-r from-synthreo-blue-darkest to-synthreo-blue-medium mb-8" />
          <p className="text-lg text-gray-300 max-w-3xl mx-auto font-poppins">
            Why now is the perfect time to build your AI practice and capitalize on this market shift.
          </p>
        </div>
        
        {/* Hexagonal-inspired layout */}
        <div className="flex flex-wrap justify-center gap-4 md:gap-6 mx-auto max-w-5xl">
          <div className="w-full md:w-5/12 lg:w-5/12 transform hover:scale-105 transition-all duration-300">
            <div className="bg-gradient-to-br from-synthreo-black-dark to-synthreo-blue-darkest/30 p-6 rounded-lg border border-synthreo-blue-dark/20 card-glow h-full">
              <div className="flex items-start">
                <div className="bg-synthreo-blue-darkest/30 rounded-full p-3 inline-flex items-center justify-center mb-4 blue-glow mr-4">
                  <TrendingUp className="h-8 w-8 text-synthreo-blue-medium" />
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Rapid Market Growth</h3>
                  <p className="text-gray-300 font-poppins">The global AI market is projected to reach $1,339 billion by 2030, with a CAGR of 35.7%.</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="w-full md:w-5/12 lg:w-5/12 transform hover:scale-105 transition-all duration-300">
            <div className="bg-gradient-to-br from-synthreo-black-dark to-synthreo-blue-darkest/30 p-6 rounded-lg border border-synthreo-blue-dark/20 card-glow h-full">
              <div className="flex items-start">
                <div className="bg-synthreo-blue-darkest/30 rounded-full p-3 inline-flex items-center justify-center mb-4 blue-glow mr-4">
                  <LineChart className="h-8 w-8 text-synthreo-blue-medium" />
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Increased Adoption</h3>
                  <p className="text-gray-300 font-poppins">72% of companies now integrate AI into at least one business function, up from 55% in the previous year.</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="w-full md:w-5/12 lg:w-5/12 transform hover:scale-105 transition-all duration-300">
            <div className="bg-gradient-to-br from-synthreo-black-dark to-synthreo-blue-darkest/30 p-6 rounded-lg border border-synthreo-blue-dark/20 card-glow h-full">
              <div className="flex items-start">
                <div className="bg-synthreo-blue-darkest/30 rounded-full p-3 inline-flex items-center justify-center mb-4 blue-glow mr-4">
                  <BarChart2 className="h-8 w-8 text-synthreo-blue-medium" />
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Corporate Investment</h3>
                  <p className="text-gray-300 font-poppins">Tech giants plan to invest over $320 billion in AI infrastructure by 2025, creating massive ecosystem opportunities.</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="w-full md:w-5/12 lg:w-5/12 transform hover:scale-105 transition-all duration-300">
            <div className="bg-gradient-to-br from-synthreo-black-dark to-synthreo-blue-darkest/30 p-6 rounded-lg border border-synthreo-blue-dark/20 card-glow h-full">
              <div className="flex items-start">
                <div className="bg-synthreo-blue-darkest/30 rounded-full p-3 inline-flex items-center justify-center mb-4 blue-glow mr-4">
                  <Zap className="h-8 w-8 text-synthreo-blue-medium" />
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Generative AI Uptake</h3>
                  <p className="text-gray-300 font-poppins">65% of organizations have already adopted generative AI technologies, indicating rapid mainstream acceptance.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Strategic Recommendations - Overlapping cards design */}
        <div className="mt-20">
          <h3 className="text-2xl font-bold mb-8 text-center font-gilroy">Strategic Recommendations</h3>
          <div className="flex flex-col md:flex-row gap-6 max-w-5xl mx-auto">
            <div className="md:w-1/3 transform hover:-translate-y-2 transition-all duration-300">
              <div className="bg-gradient-to-r from-synthreo-blue-darkest/20 to-synthreo-black rounded-xl p-6 border border-synthreo-blue-dark/30 shadow-xl h-full relative card-glow overflow-hidden">
                <div className="absolute top-0 right-0 w-24 h-24 bg-synthreo-blue-darkest/10 rounded-full -mr-8 -mt-8 blur-xl"></div>
                <div className="relative z-10">
                  <div className="bg-synthreo-blue-darkest/50 rounded-full p-3 inline-flex items-center justify-center mb-4 blue-glow">
                    <LineChart className="h-8 w-8 text-synthreo-blue-medium" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Start Small, Scale Strategically</h3>
                  <p className="text-gray-300 font-poppins">Begin with internal use cases to build experience before expanding to client-facing solutions.</p>
                </div>
              </div>
            </div>
            
            <div className="md:w-1/3 md:-mt-4 transform hover:-translate-y-2 transition-all duration-300 z-20">
              <div className="bg-gradient-to-r from-synthreo-blue-medium/20 to-synthreo-black rounded-xl p-6 border border-synthreo-blue-medium/30 shadow-xl h-full relative card-glow overflow-hidden">
                <div className="absolute top-0 right-0 w-24 h-24 bg-synthreo-blue-medium/10 rounded-full -mr-8 -mt-8 blur-xl"></div>
                <div className="relative z-10">
                  <div className="bg-synthreo-blue-darkest/50 rounded-full p-3 inline-flex items-center justify-center mb-4 blue-glow">
                    <CheckCircle className="h-8 w-8 text-synthreo-blue-medium" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Use Proven Deployment Strategies</h3>
                  <p className="text-gray-300 font-poppins">Follow established methodologies and best practices to minimize risk and accelerate time-to-value.</p>
                </div>
              </div>
            </div>
            
            <div className="md:w-1/3 transform hover:-translate-y-2 transition-all duration-300">
              <div className="bg-gradient-to-r from-synthreo-blue-darkest/20 to-synthreo-black rounded-xl p-6 border border-synthreo-blue-dark/30 shadow-xl h-full relative card-glow overflow-hidden">
                <div className="absolute top-0 right-0 w-24 h-24 bg-synthreo-blue-darkest/10 rounded-full -mr-8 -mt-8 blur-xl"></div>
                <div className="relative z-10">
                  <div className="bg-synthreo-blue-darkest/50 rounded-full p-3 inline-flex items-center justify-center mb-4 blue-glow">
                    <ShieldCheck className="h-8 w-8 text-synthreo-blue-medium" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 font-gilroy">Ensure Robust Security</h3>
                  <p className="text-gray-300 font-poppins">Maintain stringent security and compliance standards in all AI deployments.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AIOpportunity;
