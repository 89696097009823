
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import TimelineSection from '@/components/about/TimelineSection';
import MissionHeader from '@/components/about/company/MissionHeader';
import CompanyStory from '@/components/about/company/CompanyStory';
import ValueCards from '@/components/about/company/ValueCards';
import LeadershipCTA from '@/components/about/company/LeadershipCTA';
import SEOMetaTags from '@/components/SEOMetaTags';

const CompanyAbout = () => {
  // Company about page structured data
  const companyAboutSchema = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "name": "About Synthreo | Our Story & Mission",
    "description": "Discover Synthreo's mission to transform business operations through innovative AI solutions. Learn about our company values and journey.",
    "mainEntity": {
      "@type": "Organization",
      "name": "Synthreo",
      "description": "AI-powered solutions provider for business transformation",
      "foundingDate": "2020"
    }
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <SEOMetaTags 
        title="About Synthreo | Our Story & Mission"
        description="Discover Synthreo's mission to transform business operations through innovative AI solutions. Learn about our company values and journey."
        url="https://synthreo.ai/company/about"
        canonical="https://synthreo.ai/company/about"
        keywords="Synthreo mission, AI company values, artificial intelligence business, Synthreo story"
        schema={companyAboutSchema}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow">
        <MissionHeader />
        <CompanyStory />
        <ValueCards />
        <section className="py-16 relative">
          <div className="container mx-auto px-4">
            <TimelineSection />
          </div>
        </section>
        <LeadershipCTA />
      </main>
      <Footer />
    </div>
  );
};

export default CompanyAbout;
