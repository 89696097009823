
import React from 'react';
import { MessageSquare, Workflow, FileText } from 'lucide-react';
import MenuSection from './MenuSection';
import MenuLink from './MenuLink';

interface SolutionsSectionProps {
  onClose: () => void;
}

const SolutionsSection = ({ onClose }: SolutionsSectionProps) => {
  return (
    <MenuSection title="Solutions" route="/solutions" onClose={onClose}>
      <MenuLink 
        title="AI Agents & Chatbots"
        route="/solutions/ai-agents-chatbots" 
        icon={MessageSquare}
        onClose={onClose}
      />
      <MenuLink 
        title="Document Processing"
        route="/solutions/document-processing" 
        icon={FileText}
        onClose={onClose}
      />
      <MenuLink 
        title="Workflow Automation"
        route="/solutions/workflow-automation" 
        icon={Workflow}
        onClose={onClose}
      />
    </MenuSection>
  );
};

export default SolutionsSection;
