
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import BookingWidget from '@/components/CalendlyEmbed';
import { Card } from '@/components/ui/card';
import SEOMetaTags from '@/components/SEOMetaTags';

const DemoSignUp = () => {
  // Demo signup page structured data
  const demoSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Schedule a Demo | Synthreo",
    "description": "Book a personalized demo with Synthreo's experts to see how our AI solutions can transform your business operations.",
    "mainEntity": {
      "@type": "Service",
      "name": "Synthreo Product Demo",
      "description": "Interactive demonstration of Synthreo's AI solutions",
      "provider": {
        "@type": "Organization",
        "name": "Synthreo"
      }
    }
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <SEOMetaTags 
        title="Schedule a Demo | Synthreo AI Solutions"
        description="Book a personalized demo with Synthreo's experts to see how our AI solutions can transform your business operations."
        url="https://synthreo.ai/company/demo"
        canonical="https://synthreo.ai/company/demo"
        keywords="Synthreo demo, AI solution demonstration, book AI demo, artificial intelligence preview"
        schema={demoSchema}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow pt-16 pb-20">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto">
            <h1 className="text-3xl md:text-4xl font-bold font-gilroy mb-4 text-center">
              Book Your <span className="text-synthreo-blue-medium">Demo</span>
            </h1>
            <p className="text-lg mb-6 text-gray-300 text-center">
              Please select a convenient time for your personalized demo with our team.
            </p>

            <div className="mt-8">
              <Card className="bg-synthreo-black border-synthreo-blue-dark/20 p-0 shadow-lg overflow-visible">
                <div style={{ height: '2500px', backgroundColor: '#0A0A0A', position: 'relative' }}>
                  <BookingWidget />
                </div>
              </Card>
              
              <div className="text-sm text-gray-400 mt-4 text-center">
                <p>If you're having trouble viewing the booking calendar, please try using a different browser or device.</p>
                <p>Alternatively, you can email us at <a href="mailto:demo@synthreo.ai" className="text-synthreo-blue-medium hover:underline">demo@synthreo.ai</a> to schedule your demo.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DemoSignUp;
