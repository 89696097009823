
import React from 'react';

const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen w-full bg-synthreo-black">
    <div className="text-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-synthreo-blue-medium mx-auto mb-4"></div>
      <p className="text-synthreo-blue-light">Loading...</p>
    </div>
  </div>
);

export default LoadingFallback;
