
import React from 'react';
import { Building, Users, Globe, Award } from 'lucide-react';
import { useIsMobile } from '@/hooks/use-mobile';

interface ValueCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const ValueCard = ({ icon, title, description }: ValueCardProps) => {
  const isMobile = useIsMobile();
  
  return (
    <div className="p-4 md:p-6 rounded-lg card-glow bg-synthreo-black-dark transition-transform duration-300 hover:-translate-y-2">
      <div className="mb-3 md:mb-4 flex h-10 md:h-12 w-10 md:w-12 items-center justify-center rounded-full bg-synthreo-blue-darkest/30 blue-glow">
        {icon}
      </div>
      <h3 className="text-lg md:text-xl font-semibold mb-2 md:mb-3">{title}</h3>
      <p className="text-sm md:text-base text-gray-400">
        {description}
      </p>
    </div>
  );
};

const ValueCards = () => {
  const isMobile = useIsMobile();
  
  const values = [
    {
      icon: <Users className="h-5 md:h-6 w-5 md:w-6 text-synthreo-blue-light" />,
      title: "Human-Centered",
      description: "We design for people first, ensuring our AI enhances human capabilities rather than replacing them."
    },
    {
      icon: <Building className="h-5 md:h-6 w-5 md:w-6 text-synthreo-blue-light" />,
      title: "Integrity",
      description: "We build AI solutions responsibly, with transparency and ethical principles at their core."
    },
    {
      icon: <Globe className="h-5 md:h-6 w-5 md:w-6 text-synthreo-blue-light" />,
      title: "Innovation",
      description: "We constantly push boundaries, exploring new ways AI can solve complex business challenges."
    },
    {
      icon: <Award className="h-5 md:h-6 w-5 md:w-6 text-synthreo-blue-light" />,
      title: "Excellence",
      description: "We're committed to quality in every aspect of our work, striving to exceed expectations."
    }
  ];

  return (
    <section className="py-12 md:py-16 relative bg-synthreo-black-light">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-10 md:mb-16">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 font-gilroy">
            Our <span className="text-synthreo-blue-medium">Values</span>
          </h2>
          <p className="text-sm md:text-base text-gray-300">
            At Synthreo, our values guide everything we do, from how we build our products to how we interact 
            with our clients and each other.
          </p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8">
          {values.map((value, index) => (
            <ValueCard
              key={index}
              icon={value.icon}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ValueCards;
