
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import PricingHeader from '@/components/pricing/PricingHeader';
import PricingTiers from '@/components/pricing/PricingTiers';
import FeatureComparison from '@/components/pricing/FeatureComparison';
import PricingFAQ from '@/components/pricing/PricingFAQ';
import SEOMetaTags from '@/components/SEOMetaTags';
import { recoverFromBlankScreen } from '@/utils/botx/recovery';

const Pricing = () => {
  // Add error recovery
  useEffect(() => {
    try {
      // This will ensure the page is visible even if there's an error with SEO tags
      setTimeout(() => recoverFromBlankScreen(true), 100);
    } catch (error) {
      console.error('Error in Pricing page useEffect:', error);
    }
  }, []);

  // Updated structured data with Synthreo-specific terminology and SEO categories
  const pricingSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "ThreoAgents & ThreoAssistants Pricing | Synthreo AI Solutions",
    "description": "Explore Synthreo's flexible pricing plans for Fully Managed ThreoAgents and AI workflow automation solutions. Find the right package for your organization's needs.",
    "url": "https://synthreo.ai/pricing",
    "mainEntity": {
      "@type": "Product",
      "name": "Synthreo Managed AI Platform",
      "description": "Enterprise-grade ThreoAgents and goal-driven AI solutions",
      "offers": {
        "@type": "AggregateOffer",
        "priceCurrency": "USD",
        "lowPrice": "0",
        "highPrice": "999",
        "offerCount": "3"
      },
      "category": "AI Solutions",
      "keywords": [
        "Fully Managed ThreoAgents", 
        "ThreoAssistants", 
        "GPT-powered ThreoAI experience",
        "internal service desks", 
        "HR ops", 
        "finance workflows",
        "intelligent goal-driven agents",
        "workflow-embedded AI agents"
      ]
    }
  };

  const keywordsList = "Fully Managed ThreoAgents, ThreoAssistants, GPT-powered ThreoAI experience, workflow-embedded AI agents, intelligent agents for ITSM, HR ops, finance workflows, rigid chatbot alternative, goal-driven agents, internal service desks";

  return (
    <div className="min-h-screen bg-synthreo-black text-white flex flex-col overflow-hidden">
      {/* Wrap SEOMetaTags with error handling */}
      {(() => {
        try {
          return (
            <SEOMetaTags 
              title="ThreoAgents & ThreoAssistants Pricing | Synthreo AI Solutions"
              description="Explore Synthreo's flexible pricing plans for Fully Managed ThreoAgents and workflow-embedded AI assistants. Replace rigid chatbots with intelligent, goal-driven agents for internal service desks, HR ops, and finance workflows."
              url="https://synthreo.ai/pricing"
              canonical="https://synthreo.ai/pricing"
              keywords={keywordsList}
              schema={pricingSchema}
            />
          );
        } catch (error) {
          console.error('Error rendering SEO tags:', error);
          return null;
        }
      })()}
      <Navbar />
      <main className="flex-grow w-full pt-24">
        <PricingHeader />
        <PricingTiers />
        <FeatureComparison />
        <PricingFAQ />
      </main>
      <Footer />
    </div>
  );
};

export default Pricing;
