
import React from 'react';
import ErrorBoundary from '@/components/ErrorBoundary';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Workflow, Settings, FileText } from 'lucide-react';
import WorkflowCard from './WorkflowCard';

interface WorkflowBuildingSectionProps {
  onError: (error: Error) => void;
}

const WorkflowBuildingSection: React.FC<WorkflowBuildingSectionProps> = ({ onError }) => {
  return (
    <ErrorBoundary onError={onError}>
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-synthreo-black-light/30">
        <div className="max-w-7xl mx-auto">
          <div className="mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center">
              Building Workflow Automation
            </h2>
            <p className="text-xl mb-10 text-gray-300 max-w-3xl mx-auto font-poppins text-center">
              Automate repetitive tasks, reduce errors, and improve efficiency with custom workflow solutions tailored to your business needs.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <WorkflowCard 
                icon={Workflow}
                title="Custom Workflow Design"
                description="Design and implement custom workflows that automate your unique business processes, from approvals to data processing."
              />

              <WorkflowCard 
                icon={Settings}
                title="Process Optimization"
                description="Identify bottlenecks and inefficiencies in your existing workflows, and optimize them for maximum performance."
              />

              <WorkflowCard 
                icon={FileText}
                title="Document Automation"
                description="Automate the creation, processing, and management of documents, reducing manual effort and improving accuracy."
              />
            </div>

            <div className="mt-10 bg-synthreo-blue-darkest/30 rounded-lg p-6 border border-synthreo-blue-dark/20 text-center">
              <h4 className="text-lg font-bold mb-2 text-center">Use Case Example</h4>
              <p className="text-gray-300 mb-4 font-poppins text-center">
                A healthcare provider automated patient onboarding, reducing processing time by 60% and improving patient satisfaction.
              </p>
              <div className="text-center">
                <Button asChild variant="outline" className="bg-transparent text-synthreo-blue-medium border-synthreo-blue-dark/50 hover:bg-synthreo-blue-darkest/50">
                  <Link to="/resources/case-studies/healthcare-automation">Learn More</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default WorkflowBuildingSection;
