
import React, { useEffect, useState } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import { useIsMobile } from '@/hooks/use-mobile';
import SEOMetaTags from '@/components/SEOMetaTags';

const Documentation = () => {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  const [iframeHeight, setIframeHeight] = useState('calc(100vh - 128px)');

  useEffect(() => {
    // Calculate iframe height considering navbar and footer
    const calculateHeight = () => {
      const navbarHeight = 64; // Navbar is 64px high
      const footerHeight = 64; // Approximate footer height
      const newHeight = `calc(100vh - ${navbarHeight + footerHeight}px)`;
      setIframeHeight(newHeight);
    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);
    
    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  // Handle iframe load event
  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white resources-content">
      <SEOMetaTags 
        title="Documentation | Synthreo AI Platform"
        description="Comprehensive documentation for the Synthreo AI platform. Learn how to implement and optimize our AI solutions for your business."
        url="https://synthreo.ai/resources/documentation"
        canonical="https://synthreo.ai/resources/documentation"
        keywords="Synthreo documentation, AI platform docs, artificial intelligence guides, technical documentation"
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow overflow-hidden">
        {/* Embedded Documentation */}
        <div className="relative w-full h-full">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-synthreo-black-light bg-opacity-80 z-10">
              <div className="text-synthreo-blue-medium text-xl font-semibold text-center">Loading documentation...</div>
            </div>
          )}
          <iframe 
            src="https://docs.botx.cloud" 
            title="Synthreo Documentation" 
            className="w-full border-0"
            style={{ height: iframeHeight }}
            onLoad={handleIframeLoad}
            seamless
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Documentation;
