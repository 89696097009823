
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import SEOMetaTags from '@/components/SEOMetaTags';

const ContactSuccess = () => {
  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <SEOMetaTags 
        title="Message Sent | Synthreo"
        description="Thank you for contacting Synthreo. Your message has been received and we will get back to you soon."
        url="https://synthreo.ai/company/contact-success"
        canonical="https://synthreo.ai/company/contact-success"
        keywords="Synthreo contact confirmation, message sent, contact success"
        robots="noindex, follow"
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow flex items-center justify-center py-20">
        <div className="container max-w-4xl mx-auto px-4 text-center">
          <div className="flex justify-center mb-6">
            <div className="w-24 h-24 rounded-full bg-green-500/20 flex items-center justify-center">
              <Check className="h-12 w-12 text-green-500" />
            </div>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Message Received!</h1>
          <p className="text-xl text-gray-300 mb-10">
            Thank you for reaching out to us. We've received your message and will get back to you as soon as possible.
          </p>
          <div className="flex justify-center">
            <Link 
              to="/" 
              className="px-8 py-3 rounded-lg bg-synthreo-blue-medium hover:bg-synthreo-blue-dark text-white font-medium transition-colors"
            >
              Return to Home
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactSuccess;
