
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import Contact from '@/components/Contact';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { Search } from 'lucide-react';
import SEOMetaTags from '@/components/SEOMetaTags';

const CaseStudies = () => {
  const [selectedIndustry, setSelectedIndustry] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');

  // Case studies structured data
  const caseStudiesSchema = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "Case Studies | Synthreo AI Success Stories",
    "description": "Explore real-world success stories demonstrating how Synthreo's AI solutions have transformed businesses across various industries.",
    "url": "https://synthreo.ai/resources/case-studies",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "Article",
          "position": 1,
          "url": "https://synthreo.ai/resources/case-studies/clinical-assessments-automation",
          "name": "Revolutionizing Clinical Assessments for Medical Manufacturers with AI-Driven Automation",
          "description": "Healthcare case study: AI-powered document processing and analysis solution that extracts relevant data with precision"
        },
        {
          "@type": "Article",
          "position": 2,
          "url": "https://synthreo.ai/resources/case-studies/manufacturing-email-automation",
          "name": "Autonomous Email and Documents Processing with GPT-4 model for business automation",
          "description": "Manufacturing case study: Automated email and document processing system using GPT-4 technology"
        }
      ]
    }
  };

  // Case studies data matching CaseStudyDetail.tsx
  const caseStudies = [
    {
      industry: 'Healthcare',
      title: 'Revolutionizing Clinical Assessments for Medical Manufacturers with AI-Driven Automation',
      challenge: 'Difficulty managing and analyzing vast amounts of clinical study documents',
      solution: 'AI-powered document processing and analysis solution that extracts relevant data with precision',
      slug: 'clinical-assessments-automation',
      tags: ['Increase productivity']
    },
    {
      industry: 'Manufacturing',
      title: 'Autonomous Email and Documents Processing with GPT-4 model for business automation',
      challenge: 'Inefficient processing of daily email inquiries with attached documents',
      solution: 'Automated email and document processing system using GPT-4 technology to extract data and integrate with existing systems',
      slug: 'manufacturing-email-automation',
      tags: ['Increase productivity']
    },
    {
      industry: 'Capital Markets',
      title: 'ESG-Focused Media Monitoring For Investment Professionals. AI-Powered.',
      challenge: 'Need to improve ESG investment strategies while managing costs and regulatory compliance',
      solution: 'AI-powered ESG media monitoring solution that processes millions of documents to calculate reliable ESG scores',
      slug: 'esg-focused-media-monitoring',
      tags: ['Grow Revenue Profitably']
    },
    {
      industry: 'Manufacturing',
      title: '450x faster way to pick a supplier',
      challenge: 'Estimate costs for each order from 5000 suppliers',
      solution: 'AI-driven neural networks that predict prices and delivery dates for optimal supplier selection',
      slug: 'supplier-selection-automation',
      tags: ['Grow Revenue Profitably']
    },
    {
      industry: 'Capital Markets',
      title: 'SFDR Anti-Greenwashing Solution',
      challenge: 'Demanding regulations lacking clear guidelines for sustainable finance disclosure',
      solution: 'Enhanced ESG monitoring system with automated fund analysis and SFDR classification to reduce compliance risk',
      slug: 'sfdr-anti-greenwashing',
      tags: ['Reduce Risk']
    },
    {
      industry: 'Legal',
      title: 'Saving 12 hours a week with Automated trademark protection and computer vision',
      challenge: 'Manual repetitive work, unable to automate with traditional methods',
      solution: 'Computer vision solution that scans trademark databases and only requires human input when potential conflicts are detected',
      slug: 'trademark-protection-automation',
      tags: ['Legal & Compliance', 'Grow Revenue Profitably']
    }
  ];

  // Get all unique industries from case studies
  const industries = ['All', ...Array.from(new Set(caseStudies.map(study => study.industry)))];

  // Filter case studies based on selected industry and search query
  const filteredCaseStudies = caseStudies.filter(study => {
    const industryMatches = selectedIndustry === 'All' || study.industry === selectedIndustry;
    const searchMatches = searchQuery === '' || 
      study.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
      study.challenge.toLowerCase().includes(searchQuery.toLowerCase()) || 
      study.solution.toLowerCase().includes(searchQuery.toLowerCase());
    
    return industryMatches && searchMatches;
  });

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white resources-content">
      <SEOMetaTags 
        title="Case Studies | Synthreo AI Success Stories"
        description="Explore real-world success stories demonstrating how Synthreo's AI solutions have transformed businesses across various industries."
        url="https://synthreo.ai/resources/case-studies"
        canonical="https://synthreo.ai/resources/case-studies"
        keywords="Synthreo case studies, AI success stories, artificial intelligence implementations, business transformation examples"
        schema={caseStudiesSchema}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow pt-24">
        {/* Hero Section */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-4">Case Studies</h1>
            <p className="text-lg text-gray-300 mb-8">
              Success stories from our clients demonstrating real-world impact with Synthreo AI solutions.
            </p>

            {/* Search & Filter Section */}
            <div className="flex flex-col md:flex-row md:items-start gap-4 mb-6">
              <div className="relative w-full md:w-64 flex-shrink-0">
                <Input
                  type="text"
                  placeholder="Search case studies..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="bg-synthreo-black-light border-gray-700 text-white pr-10"
                />
                <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </div>

              {/* Industry Filter */}
              <div className="flex flex-wrap gap-2 my-2 md:my-0 justify-center md:justify-start">
                {industries.map((industry, index) => (
                  <Badge 
                    key={index}
                    variant={industry === selectedIndustry ? "default" : "outline"}
                    className={`cursor-pointer ${industry === selectedIndustry 
                      ? "bg-synthreo-blue-medium hover:bg-synthreo-blue-dark" 
                      : "border-gray-700 hover:bg-synthreo-black-light"}`}
                    onClick={() => setSelectedIndustry(industry)}
                  >
                    {industry}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Case Studies */}
        <section className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          {filteredCaseStudies.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-lg text-gray-300">No case studies found matching your search.</p>
              <Button 
                variant="default" 
                className="mt-4 bg-synthreo-black-light hover:bg-synthreo-black-dark"
                onClick={() => {
                  setSearchQuery('');
                  setSelectedIndustry('All');
                }}
              >
                Clear Filters
              </Button>
            </div>
          ) : (
            <div className="grid md:grid-cols-2 gap-10 mb-10">
              {filteredCaseStudies.map((study, index) => (
                <article key={index} className="border border-gray-800 rounded-lg p-6 hover:border-synthreo-blue-medium transition-colors duration-300">
                  <div className="mb-2 text-center md:text-left">
                    <span className="text-sm text-synthreo-blue-medium font-medium">{study.industry}</span>
                  </div>
                  <h3 className="text-2xl font-bold mb-3 font-gilroy text-center md:text-left">{study.title}</h3>
                  <p className="text-gray-400 mb-2 text-center md:text-left"><strong>Challenge:</strong> {study.challenge}</p>
                  <p className="text-gray-300 mb-3 text-center md:text-left">{study.solution}</p>
                  <div className="text-center md:text-left">
                    <Link 
                      to={`/resources/case-studies/${study.slug}`}
                      className="text-synthreo-blue-medium font-medium inline-block hover:underline"
                    >
                      Read Case Study
                    </Link>
                  </div>
                </article>
              ))}
            </div>
          )}
        </section>

        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default CaseStudies;
