
import React from 'react';
import { Rss, BookOpen, Briefcase } from 'lucide-react';
import MenuSection from './MenuSection';
import MenuLink from './MenuLink';

interface ResourcesSectionProps {
  onClose: () => void;
}

const ResourcesSection = ({ onClose }: ResourcesSectionProps) => {
  return (
    <MenuSection title="Resources" route="/resources" onClose={onClose}>
      <MenuLink 
        title="Blog"
        route="/resources/blog" 
        icon={Rss}
        onClose={onClose}
      />
      <MenuLink 
        title="Case Studies"
        route="/resources/case-studies" 
        icon={Briefcase}
        onClose={onClose}
      />
      <MenuLink 
        title="Documentation"
        route="/resources/documentation" 
        icon={BookOpen}
        onClose={onClose}
      />
    </MenuSection>
  );
};

export default ResourcesSection;
