
import React from 'react';
import { ArrowRight, CheckCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter } from '@/components/ui/card';

const ProductsForMSPs = () => {
  return (
    <section className="py-20 bg-synthreo-black-light relative">
      <div className="absolute inset-0 z-0 bg-grid-pattern opacity-5" />
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 font-gilroy">
            Purpose-Built <span className="text-gradient">Products for MSPs</span>
          </h2>
          <div className="mx-auto h-1 w-20 bg-gradient-to-r from-synthreo-blue-darkest to-synthreo-blue-medium mb-8" />
          <p className="text-lg text-gray-300 max-w-3xl mx-auto font-poppins">
            White-label solutions designed specifically for MSPs to quickly and securely build and scale AI offerings.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {/* ThreoGPT */}
          <Card className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light border-synthreo-blue-dark/20 card-glow h-full flex flex-col">
            <CardContent className="p-6 flex-grow">
              <h3 className="text-2xl font-bold mb-3 font-gilroy">ThreoGPT</h3>
              <p className="text-lg text-gray-300 mb-4 font-poppins">Secure Multi-tenant GPT-like Chat Portal</p>
              <p className="text-gray-400 mb-6 font-poppins">Simplified AI management and deployment with your branding.</p>
              
              <ul className="space-y-3 mb-8 font-poppins">
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Fully customizable, white-label branding</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Integration with any leading LLM</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Analytics and seamless ITSM integration</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Deliver AI Agents easily to clients</span>
                </li>
              </ul>
              
              <Button 
                className="rounded-lg px-6 py-3 text-base font-medium bg-synthreo-black-light text-white hover:bg-synthreo-black-dark h-auto font-gilroy"
                asChild
              >
                <a href="/products/threogpt">Learn More <ArrowRight className="h-4 w-4 ml-2" /></a>
              </Button>
            </CardContent>
            <CardFooter className="h-48 bg-synthreo-black-dark p-0 flex items-center justify-center">
              <img 
                src="/lovable-uploads/eb80bfdc-797a-4cfc-8f7c-2d28bf334679.png" 
                alt="ThreoGPT Interface" 
                className="max-h-full w-auto rounded border border-synthreo-blue-dark/30"
              />
            </CardFooter>
          </Card>
          
          {/* ThreoPortal AI Agent Builder Platform */}
          <Card className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light border-synthreo-blue-dark/20 card-glow h-full flex flex-col">
            <CardContent className="p-6 flex-grow">
              <h3 className="text-2xl font-bold mb-3 font-gilroy">ThreoPortal AI Agent Builder Platform</h3>
              <p className="text-lg text-gray-300 mb-4 font-poppins">Build powerful AI agents without coding expertise</p>
              <p className="text-gray-400 mb-6 font-poppins">Create custom AI solutions for your clients with our intuitive platform.</p>
              
              <ul className="space-y-3 mb-8 font-poppins">
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Built-in RAG data</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Cloud database integration</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Security first ZDR (Zero Day Retention)</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                  <span>Native Machine Learning capabilities</span>
                </li>
              </ul>
              
              <Button 
                className="rounded-lg px-6 py-3 text-base font-medium bg-synthreo-black-light text-white hover:bg-synthreo-black-dark h-auto font-gilroy"
                asChild
              >
                <a href="/products/threoportal">Learn More <ArrowRight className="h-4 w-4 ml-2" /></a>
              </Button>
            </CardContent>
            <CardFooter className="h-48 bg-synthreo-black-dark p-0 flex items-center justify-center">
              <img 
                src="/lovable-uploads/102549ce-bf71-430d-b3bd-210b82d7ea67.png" 
                alt="ThreoPortal AI Agent Builder Interface" 
                className="max-h-full w-auto rounded border border-synthreo-blue-dark/30"
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default ProductsForMSPs;
