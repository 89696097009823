import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import { Button } from '@/components/ui/button';
import { Code, Database, Lightbulb, MessageSquare, Monitor, Briefcase } from 'lucide-react';
import { Link } from 'react-router-dom';

const Careers = () => {
  useEffect(() => {
    document.title = 'Careers at Synthreo | Join Our Team';
  }, []);

  const openings = [
    {
      title: "Senior AI Engineer",
      department: "Engineering",
      location: "Remote (US/Canada)",
      type: "Full-time",
      description: "Design and implement cutting-edge AI solutions for enterprise clients.",
      icon: <Code className="h-6 w-6 text-synthreo-blue-light" />
    },
    {
      title: "Product Manager, AI Solutions",
      department: "Product",
      location: "San Francisco, CA",
      type: "Full-time",
      description: "Lead the strategy and execution of our AI product portfolio.",
      icon: <Monitor className="h-6 w-6 text-synthreo-blue-light" />
    },
    {
      title: "Machine Learning Researcher",
      department: "Research",
      location: "Remote (Global)",
      type: "Full-time",
      description: "Explore and develop novel approaches to NLP and conversational AI.",
      icon: <Lightbulb className="h-6 w-6 text-synthreo-blue-light" />
    },
    {
      title: "Solutions Architect",
      department: "Client Success",
      location: "New York, NY",
      type: "Full-time",
      description: "Design and implement custom AI solutions for enterprise clients.",
      icon: <Database className="h-6 w-6 text-synthreo-blue-light" />
    },
    {
      title: "Conversational AI Specialist",
      department: "Engineering",
      location: "Remote (US/Canada)",
      type: "Full-time",
      description: "Build next-generation chatbots and conversational interfaces.",
      icon: <MessageSquare className="h-6 w-6 text-synthreo-blue-light" />
    }
  ];

  const benefits = [
    {
      title: "Competitive Compensation",
      description: "Attractive salary packages with equity options in a fast-growing company."
    },
    {
      title: "Remote-First Culture",
      description: "Work from anywhere with flexible hours and generous time-off policies."
    },
    {
      title: "Health & Wellness",
      description: "Comprehensive health coverage, wellness stipends, and mental health support."
    },
    {
      title: "Growth & Learning",
      description: "Dedicated budget for conferences, courses, and professional development."
    },
    {
      title: "Cutting-Edge Projects",
      description: "Work on innovative solutions that push the boundaries of AI technology."
    },
    {
      title: "Inclusive Environment",
      description: "A diverse, supportive workplace where every voice matters and contributes."
    }
  ];

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="py-28 md:py-32 relative">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
                Join Our <span className="text-gradient">Team</span>
              </h1>
              <p className="text-lg text-gray-300 leading-relaxed mb-8">
                At Synthreo, we're building the future of AI-powered business solutions. 
                Join us in our mission to transform how companies operate.
              </p>
              <Button 
                variant="default" 
                className="btn-glow text-white hover:bg-synthreo-blue-darkest font-gilroy"
              >
                View Open Positions
              </Button>
            </div>
          </div>
        </section>
        
        {/* Why Join Section */}
        <section className="py-16 relative bg-synthreo-black-light">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl font-bold mb-6 font-gilroy">
                Why Join <span className="text-synthreo-blue-medium">Synthreo</span>?
              </h2>
              <p className="text-gray-300">
                We're a team of passionate innovators working at the cutting edge of AI technology. 
                Here's what makes Synthreo special:
              </p>
            </div>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <div 
                  key={index} 
                  className="p-6 rounded-lg card-glow bg-synthreo-black-dark transition-transform duration-300 hover:-translate-y-2"
                >
                  <h3 className="text-xl font-semibold mb-3">{benefit.title}</h3>
                  <p className="text-gray-400">{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        {/* Open Positions Section */}
        <section className="py-16 relative">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl font-bold mb-6 font-gilroy">
                Open <span className="text-synthreo-blue-medium">Positions</span>
              </h2>
              <p className="text-gray-300">
                Explore our current opportunities and find your place in shaping the future of AI.
              </p>
            </div>
            
            <div className="space-y-6">
              {openings.map((job, index) => (
                <div 
                  key={index} 
                  className="p-6 rounded-lg card-glow bg-synthreo-black-dark hover:bg-synthreo-black-light transition-colors"
                >
                  <div className="flex flex-col md:flex-row md:items-center">
                    <div className="flex-grow">
                      <div className="flex items-center mb-2">
                        <div className="mr-3 flex h-10 w-10 items-center justify-center rounded-full bg-synthreo-blue-darkest/30">
                          {job.icon}
                        </div>
                        <h3 className="text-xl font-semibold">{job.title}</h3>
                      </div>
                      <p className="text-gray-400 mb-4">{job.description}</p>
                      <div className="flex flex-wrap gap-3 mb-4 md:mb-0">
                        <span className="inline-flex items-center rounded-full bg-synthreo-blue-darkest/30 px-3 py-1 text-xs text-synthreo-blue-light">
                          <Briefcase className="mr-1 h-3 w-3" />
                          {job.department}
                        </span>
                        <span className="inline-flex items-center rounded-full bg-synthreo-blue-darkest/30 px-3 py-1 text-xs text-synthreo-blue-light">
                          {job.location}
                        </span>
                        <span className="inline-flex items-center rounded-full bg-synthreo-blue-darkest/30 px-3 py-1 text-xs text-synthreo-blue-light">
                          {job.type}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0">
                      <Button variant="outline" className="border-synthreo-blue-medium/40 text-synthreo-blue-medium">
                        Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        {/* Culture Section */}
        <section className="py-16 relative bg-synthreo-black-light">
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h2 className="text-3xl font-bold mb-6 font-gilroy">
                  Our <span className="text-synthreo-blue-medium">Culture</span>
                </h2>
                <p className="text-gray-300 mb-4">
                  At Synthreo, we believe that the best work happens when passionate people from diverse 
                  backgrounds come together in an environment that fosters creativity, collaboration, and growth.
                </p>
                <p className="text-gray-300 mb-4">
                  We're committed to building a workplace where everyone feels valued, heard, and empowered to do 
                  their best work. Our team thrives on solving complex problems, learning continuously, and making 
                  a tangible impact for our clients.
                </p>
                <p className="text-gray-300">
                  Whether you're working remotely or from one of our offices, you'll be part of a tight-knit 
                  community that supports each other and celebrates successes together.
                </p>
              </div>
              <div className="card-glow p-6 rounded-lg">
                <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden bg-synthreo-black-light">
                  <img 
                    src="/lovable-uploads/eb80bfdc-797a-4cfc-8f7c-2d28bf334679.png" 
                    alt="Synthreo Team Culture" 
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* CTA Section */}
        <section className="py-16 relative">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6 font-gilroy">
                Don't See the <span className="text-synthreo-blue-medium">Right Fit</span>?
              </h2>
              <p className="text-gray-300 mb-8">
                We're always looking for exceptional talent to join our team. Send us your resume and let's 
                explore how we might work together.
              </p>
              <Button 
                variant="default" 
                className="btn-glow text-white hover:bg-synthreo-blue-darkest font-gilroy"
              >
                Submit Your Resume
              </Button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Careers;
