
import React, { useEffect } from 'react';

const PricingHeader = () => {
  // Add error recovery for this component
  useEffect(() => {
    try {
      // Ensure the page is visible if it loaded this component
      const root = document.getElementById('root');
      if (root && root.style.visibility !== 'visible') {
        root.style.visibility = 'visible';
      }
    } catch (error) {
      console.error('Error in PricingHeader useEffect:', error);
    }
  }, []);

  return (
    <section className="py-8 md:py-10 relative">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
            Transparent <span className="text-gradient">Pricing</span>
          </h1>
          <p className="text-base md:text-lg text-gray-300 leading-relaxed mb-5">
            What You're Paying For
          </p>
          <div className="max-w-3xl mx-auto bg-synthreo-black-light/70 p-6 rounded-lg border-l-4 border-synthreo-blue-light mb-6">
            <p className="text-base md:text-lg text-gray-200 leading-relaxed">
              A Managed AI Agent is more than just automation — it's a fully supported digital worker. Built, deployed, and maintained by Synthreo, each agent includes setup, training, integration, and continuous optimization. You get powerful AI capabilities without the technical lift — just results.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingHeader;
