
import React from 'react';

const HeroSection = () => {
  return (
    <section className="relative py-24 md:py-32 px-4">
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/2 top-1/3 h-[500px] w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-synthreo-blue-darkest/10 blur-3xl" />
        <div className="absolute right-1/4 bottom-1/3 h-[350px] w-[350px] translate-x-1/4 translate-y-1/4 rounded-full bg-synthreo-blue-medium/5 blur-2xl" />
      </div>
      
      <div className="container mx-auto relative z-10">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-gilroy">
            Lead in the <span className="text-gradient">AI-Driven Era</span> with Synthreo
          </h1>
          <p className="text-xl text-gray-300 font-poppins">
            Empower your MSP business to address the $18B potential revenue loss due to AI-driven job displacement by 2027.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
