
import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Linkedin, Instagram, Mail } from 'lucide-react';
import { Separator } from "@/components/ui/separator";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-gradient-to-b from-synthreo-black-light to-synthreo-black-dark py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          {/* Left Column - Logo, Email, Social */}
          <div className="md:col-span-1">
            <Link to="/" className="inline-block mb-6">
              <img
                src="/lovable-uploads/e0f0d81d-f1ae-453b-af8b-dc39543df6e6.png"
                alt="Synthreo Logo"
                className="h-10 w-auto img-logo-dark"
              />
            </Link>
            
            <a href="mailto:info@synthreo.com" className="flex items-center text-gray-400 hover:text-synthreo-blue-medium transition-colors mb-8">
              <Mail className="h-4 w-4 mr-2" />
              <span>info@synthreo.com</span>
            </a>
            
            {/* Social Links */}
            <div className="flex space-x-4 mb-8">
              <a 
                href="https://facebook.com/synthreo" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-gray-400 transition-colors hover:bg-synthreo-blue-dark hover:text-white"
              >
                <Facebook size={18} />
                <span className="sr-only">Facebook</span>
              </a>
              <a 
                href="https://linkedin.com/company/synthreo" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-gray-400 transition-colors hover:bg-synthreo-blue-dark hover:text-white"
              >
                <Linkedin size={18} />
                <span className="sr-only">LinkedIn</span>
              </a>
              <a 
                href="https://instagram.com/synthreo" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-gray-400 transition-colors hover:bg-synthreo-blue-dark hover:text-white"
              >
                <Instagram size={18} />
                <span className="sr-only">Instagram</span>
              </a>
            </div>
          </div>

          {/* Navigation Columns with equal width and consistent spacing */}
          <div className="md:col-span-3 grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Products Column */}
            <div>
              <h3 className="text-xl font-semibold text-white mb-6">Products</h3>
              <ul className="space-y-4">
                <li>
                  <Link to="/products/threoai" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    ThreoAI
                  </Link>
                </li>
                <li>
                  <Link to="/products/threobuilder" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    ThreoBuilder
                  </Link>
                </li>
              </ul>
            </div>

            {/* Solutions Column - Alphabetically sorted */}
            <div>
              <h3 className="text-xl font-semibold text-white mb-6">Solutions</h3>
              <ul className="space-y-4">
                <li>
                  <Link to="/solutions/ai-agents-chatbots" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    AI Agents & Chatbots
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/document-processing" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Document Processing
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/workflow-automation" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Workflow Automation
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources Column - Alphabetically sorted */}
            <div>
              <h3 className="text-xl font-semibold text-white mb-6">Resources</h3>
              <ul className="space-y-4">
                <li>
                  <Link to="/resources/blog" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/resources/case-studies" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Case Studies
                  </Link>
                </li>
                <li>
                  <Link to="/resources/documentation" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to="/company/support" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Support
                  </Link>
                </li>
              </ul>
            </div>

            {/* Company Column - Alphabetically sorted */}
            <div>
              <h3 className="text-xl font-semibold text-white mb-6">Company</h3>
              <ul className="space-y-4">
                <li>
                  <Link to="/company/about" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/company/careers" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/company/leadership" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Leadership
                  </Link>
                </li>
                <li>
                  <Link to="/partners" className="text-gray-400 hover:text-synthreo-blue-medium transition-colors">
                    Partners
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        {/* Contact Us Banner */}
        <div className="mt-16 mb-8 p-6 bg-synthreo-black-light/50 rounded-lg text-center">
          <h3 className="text-xl font-semibold text-white mb-3">Need to get in touch?</h3>
          <p className="text-gray-400 mb-4">We're here to help with any questions or inquiries</p>
          <Link 
            to="/company/contact" 
            className="inline-flex items-center justify-center px-6 py-3 rounded-md bg-synthreo-blue-dark hover:bg-synthreo-blue-medium text-white text-sm font-medium transition-colors"
          >
            <Mail className="mr-2 h-4 w-4" />
            Contact Us
          </Link>
        </div>
        
        {/* Copyright and attribution */}
        <Separator className="bg-gray-800 my-8" />
        <div className="text-center text-sm text-gray-500">
          &copy; {currentYear} Synthreo. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
