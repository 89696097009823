
import React, { useEffect } from 'react';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import { Button } from '@/components/ui/button';
import SEOMetaTags from '@/components/SEOMetaTags';
import { 
  MessageSquare, 
  BookOpen, 
  PhoneCall, 
  Mail, 
  HelpCircle, 
  FileText, 
  Video, 
  Users 
} from 'lucide-react';
import { Link } from 'react-router-dom';

const Support = () => {
  useEffect(() => {
    document.title = 'Support | Synthreo';
  }, []);

  const supportSchema = {
    "@context": "https://schema.org",
    "@type": "CustomerService",
    "name": "Synthreo Support",
    "telephone": "+1 (602) 649-4979",
    "email": "info@synthreo.ai",
    "description": "Get help with Synthreo products and services through our comprehensive support options."
  };

  const supportOptions = [
    {
      title: "Documentation",
      description: "Comprehensive guides, API references, and tutorials.",
      icon: <BookOpen className="h-6 w-6 text-synthreo-blue-light" />,
      link: "/resources/documentation"
    },
    {
      title: "Live Chat Support",
      description: "Connect with our support team in real-time.",
      icon: <MessageSquare className="h-6 w-6 text-synthreo-blue-light" />,
      link: "#chat-support"
    },
    {
      title: "Phone Support",
      description: "Call us at +1 (602) 649-4979 to speak with our experts.",
      icon: <PhoneCall className="h-6 w-6 text-synthreo-blue-light" />,
      link: "tel:+16026494979"
    },
    {
      title: "Email Support",
      description: "Get detailed answers to complex questions.",
      icon: <Mail className="h-6 w-6 text-synthreo-blue-light" />,
      link: "mailto:info@synthreo.ai"
    }
  ];
  
  const faqs = [
    {
      question: "What is a Managed AI Agent?",
      answer: "A Managed AI Agent is an artificial intelligence solution that we build, deploy, and maintain for your specific business needs. Unlike off-the-shelf AI tools, our agents are custom-developed using our proprietary platform to address your unique requirements. We handle the entire lifecycle - from initial development and training with your data to ongoing optimization, monitoring, and maintenance. This ensures your AI agents consistently perform at their best, adapt to changing needs, and deliver reliable results without requiring in-house AI expertise from your team."
    },
    {
      question: "How do I reset my password?",
      answer: "You can reset your password by clicking on the 'Forgot Password' link on the login page. Follow the instructions sent to your email to create a new password."
    },
    {
      question: "What are the system requirements for Synthreo products?",
      answer: "Our cloud-based solutions run in any modern web browser (Chrome, Firefox, Safari, Edge). For optimal performance, we recommend a high-speed internet connection and at least 8GB of RAM."
    },
    {
      question: "How do I upgrade my subscription plan?",
      answer: "You can upgrade your subscription at any time from the Account Settings page. Select 'Subscription' and choose your desired plan. Changes will be prorated for the remainder of your billing cycle."
    },
    {
      question: "Is my data secure with Synthreo?",
      answer: "Yes, we implement bank-level security measures including end-to-end encryption, regular security audits, and strict access controls. All data is stored in SOC 2 compliant facilities."
    },
    {
      question: "Can I integrate Synthreo with my existing systems?",
      answer: "Yes, we offer extensive API options and pre-built integrations for popular platforms including Salesforce, Microsoft Teams, Slack, and many others. Our Solutions team can assist with custom integrations."
    }
  ];

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white overflow-hidden">
      <SEOMetaTags 
        title="Support | Get Help with Synthreo Products"
        description="Get comprehensive support for all Synthreo products and services. Find answers in our documentation, contact our support team, or browse our knowledge base."
        url="https://synthreo.ai/company/support"
        canonical="https://synthreo.ai/company/support"
        keywords="Synthreo support, AI product help, customer service, technical support"
        schema={supportSchema}
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow w-full">
        <section className="py-28 md:py-32 relative">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-6 font-gilroy">
                How Can We <span className="text-gradient">Help</span>?
              </h1>
              <p className="text-lg text-gray-300 leading-relaxed mb-8">
                Our team is ready to assist you with any questions or challenges you might face with 
                Synthreo products and services.
              </p>
              {/* Search bar has been removed */}
            </div>
          </div>
        </section>
        
        <section className="pt-10 pb-16 relative">
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {supportOptions.map((option, index) => (
                <a
                  href={option.link}
                  key={index} 
                  className="p-6 rounded-lg card-glow bg-synthreo-black-dark transition-transform duration-300 hover:-translate-y-2"
                >
                  <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-synthreo-blue-darkest/30 blue-glow">
                    {option.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{option.title}</h3>
                  <p className="text-gray-400">{option.description}</p>
                </a>
              ))}
            </div>
          </div>
        </section>
        
        <section className="py-16 relative">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl font-bold mb-6 font-gilroy">
                Frequently Asked <span className="text-synthreo-blue-medium">Questions</span>
              </h2>
              <p className="text-gray-300">
                Quick answers to common questions about our products and services.
              </p>
            </div>
            
            <div className="max-w-3xl mx-auto space-y-6">
              {faqs.map((faq, index) => (
                <div 
                  key={index} 
                  className="p-6 rounded-lg card-glow bg-synthreo-black-dark transition-all duration-300"
                >
                  <h3 className="text-xl font-semibold mb-3">{faq.question}</h3>
                  <p className="text-gray-400">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        <section className="py-16 relative bg-synthreo-black-light">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl font-bold mb-6 font-gilroy">
                Still Need <span className="text-synthreo-blue-medium">Help</span>?
              </h2>
              <p className="text-gray-300 mb-8">
                Our team is here to assist you. Contact us directly for personalized support.
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Button 
                  variant="default" 
                  className="btn-glow text-white hover:bg-synthreo-blue-darkest font-gilroy"
                >
                  <MessageSquare className="mr-2 h-4 w-4" />
                  Start Live Chat
                </Button>
                <a href="mailto:info@synthreo.ai">
                  <Button 
                    variant="outline" 
                    className="border-synthreo-blue-medium/40 text-synthreo-blue-medium"
                  >
                    <Mail className="mr-2 h-4 w-4" />
                    Email Support
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Support;
