
/**
 * Microsoft Clarity Analytics Integration
 * Handles initialization and consent management for Clarity
 */

// Ensure the clarity object exists on window
export const ensureClarityAvailable = (): void => {
  if (typeof window === 'undefined') return;
  
  if (!window.clarity) {
    // Create a placeholder function that queues calls until the real clarity loads
    window.clarity = function(...args: any[]) {
      // If q exists, push to queue, otherwise just log
      if ((window.clarity as any).q) {
        (window.clarity as any).q.push(args);
      } else {
        console.log('Clarity stub called with:', args);
      }
    } as any;
    
    // Add required properties
    (window.clarity as any).v = "1.0";
    (window.clarity as any).q = [];
    (window.clarity as any).push = function(args: any) {
      if ((window.clarity as any).q) (window.clarity as any).q.push(args);
    };
    
    console.log('Created Clarity placeholder');
  }
  
  // Safety measure - add property to prevent multiple catches
  if (!window._clarityCatcherAdded) {
    window._clarityCatcherAdded = true;
    
    // Catch any errors in case Clarity fails to load properly
    try {
      const originalClarityFunc = window.clarity;
      window.clarity = function(...args: any[]) {
        try {
          if (typeof originalClarityFunc === 'function') {
            return originalClarityFunc(...args);
          } else if (originalClarityFunc && typeof (originalClarityFunc as any).push === 'function') {
            return (originalClarityFunc as any).push(args);
          }
        } catch (e) {
          console.error('Error in Clarity wrapper:', e);
        }
      } as any;
      
      // Copy over properties
      if (originalClarityFunc) {
        Object.keys(originalClarityFunc as any).forEach(key => {
          (window.clarity as any)[key] = (originalClarityFunc as any)[key];
        });
      }
    } catch (e) {
      console.error('Failed to create safety wrapper for Clarity:', e);
    }
  }
};

/**
 * Update Clarity consent based on cookie preferences
 * @param consentGranted Whether consent has been granted
 */
export const updateClarityConsent = (consentGranted?: boolean): void => {
  if (typeof window === 'undefined') return;
  
  try {
    // Ensure clarity is available
    ensureClarityAvailable();
    
    // Update consent setting
    if (typeof consentGranted === 'boolean') {
      window.clarity && window.clarity('consent', consentGranted);
      console.log(`Clarity consent ${consentGranted ? 'granted' : 'revoked'}`);
    }
  } catch (e) {
    console.error('Error updating Clarity consent:', e);
  }
};
