
/**
 * Blank screen recovery utilities
 */
import { toast } from 'sonner';

let recoveryAttempts = 0;

/**
 * Attempts to recover from blank screen issues
 * @param force - Whether to force recovery even if no blank screen is detected
 * @returns boolean - Whether recovery was performed
 */
export function recoverFromBlankScreen(force = false): boolean {
  try {
    console.log("Running blank screen recovery check");
    recoveryAttempts++;
    
    // Force root element visible no matter what
    const root = document.getElementById('root');
    if (root) {
      root.style.visibility = 'visible';
      root.style.display = 'block';
    }
    
    // Force body and html to be visible too
    document.body.style.visibility = 'visible';
    document.documentElement.style.visibility = 'visible';
    
    // Remove loading classes
    document.documentElement.classList.remove('js-loading');
    document.body.classList.remove('js-loading');
    
    // Check if document has rendered
    const mainContent = document.querySelector('main, .App, #app');
    
    // Determine if we have a blank screen
    const isBlankScreen = force || 
                          !mainContent ||
                          (root && root.children.length === 0) ||
                          document.body.children.length === 0;
    
    if (isBlankScreen) {
      console.log(`Recovery attempt #${recoveryAttempts}: Detected blank screen`);
      
      // Check if we need to create fallback UI
      if (recoveryAttempts > 1 && (!root || root.children.length === 0)) {
        console.log("Creating fallback UI due to empty content");
        createFallbackUI();
      }
      
      // Only notify user on interactive recoveries (not initial ones)
      if (recoveryAttempts > 1 && !force) {
        toast.info("App recovered from loading issue", {
          duration: 3000,
          position: "bottom-center"
        });
      }
      
      return true;
    }
    
    // Even if we didn't detect a blank screen, ensure the page is visible
    if (root) {
      root.style.visibility = 'visible';
      root.style.display = 'block';
    }
    
    console.log("Recovery check completed, no blank screen detected");
    return false;
  } catch(e) {
    console.error("Error in recovery function:", e);
    // Last resort recovery
    if (recoveryAttempts > 2) {
      createFallbackUI();
    }
    return false;
  }
}

/**
 * Creates a minimal UI when recovery fails
 */
export function createFallbackUI(): void {
  try {
    // Check if we already have a fallback UI
    if (document.getElementById('synthreo-fallback-ui')) {
      return;
    }
    
    console.log("Creating fallback UI");
    
    // Create a simple container for essential content
    const fallbackUI = document.createElement('div');
    fallbackUI.id = 'synthreo-fallback-ui';
    fallbackUI.style.position = 'fixed';
    fallbackUI.style.top = '0';
    fallbackUI.style.left = '0';
    fallbackUI.style.width = '100%';
    fallbackUI.style.height = '100%';
    fallbackUI.style.backgroundColor = '#0A0A0A';
    fallbackUI.style.zIndex = '9999';
    fallbackUI.style.display = 'flex';
    fallbackUI.style.flexDirection = 'column';
    fallbackUI.style.alignItems = 'center';
    fallbackUI.style.justifyContent = 'center';
    fallbackUI.style.color = 'white';
    fallbackUI.style.fontFamily = 'system-ui, sans-serif';
    
    fallbackUI.innerHTML = `
      <div style="text-align: center; max-width: 80%;">
        <img src="/lovable-uploads/e0f0d81d-f1ae-453b-af8b-dc39543df6e6.png" 
             alt="Synthreo Logo" style="height: 60px; margin-bottom: 40px;">
        <h1 style="font-size: 24px; margin-bottom: 20px;">We're experiencing technical difficulties</h1>
        <p style="font-size: 16px; margin-bottom: 30px;">Please try refreshing the page.</p>
        <button onclick="window.location.reload()" 
                style="background: #3B82F6; color: white; border: none; 
                       padding: 12px 24px; border-radius: 4px; font-size: 16px;
                       cursor: pointer; transition: background 0.2s;">
          Reload Page
        </button>
      </div>
    `;
    
    // Insert at the beginning of body for maximum priority
    document.body.insertBefore(fallbackUI, document.body.firstChild);
    
    // Also make the normal root visible in case application loads
    const root = document.getElementById('root');
    if (root) {
      root.style.visibility = 'visible';
      root.style.display = 'block';
    }
  } catch(e) {
    console.error("Error creating fallback UI:", e);
    // Absolute last resort - if even our fallback UI fails, try to show something
    try {
      document.body.innerHTML = '<div style="text-align:center;padding:20px;"><h2>Application Error</h2><p>Please refresh the page.</p></div>';
    } catch(e2) {
      console.error("Critical UI recovery failed:", e2);
    }
  }
}
