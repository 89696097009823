
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import ParticleBackground from '@/components/ParticleBackground';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card } from '@/components/ui/card';
import { toast } from 'sonner';
import SEOMetaTags from '@/components/SEOMetaTags';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    // Here you would typically make an API call to send a reset password email
    toast.success('Password reset instructions sent to your email');
    setIsSubmitted(true);
  };

  return (
    <div className="flex min-h-screen flex-col bg-synthreo-black text-white">
      <SEOMetaTags 
        title="Forgot Password | Reset Your Synthreo Account"
        description="Reset your Synthreo account password. Enter your email address to receive password reset instructions."
        url="https://synthreo.ai/auth/forgot-password"
        canonical="https://synthreo.ai/auth/forgot-password"
        keywords="Synthreo password reset, forgot password, account recovery, reset login"
        robots="noindex, follow"
      />
      <ParticleBackground />
      <Navbar />
      <main className="flex-grow pt-24">
        <div className="container mx-auto px-4">
          <div className="max-w-md mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold font-gilroy mb-6 text-center">
              Reset <span className="text-synthreo-blue-medium">Password</span>
            </h1>
            <p className="text-xl mb-10 text-gray-300 text-center">
              Enter your email to receive password reset instructions.
            </p>

            <Card className="bg-synthreo-black-light border-synthreo-blue-dark/20 p-6 mb-8">
              {!isSubmitted ? (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="space-y-4">
                    <div>
                      <Label htmlFor="email">Email Address</Label>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="bg-synthreo-black-darker border-synthreo-blue-dark/30 text-white"
                        placeholder="john.doe@company.com"
                      />
                    </div>
                  </div>

                  <Button 
                    type="submit"
                    className="w-full btn-glow"
                  >
                    Send Reset Instructions
                  </Button>
                </form>
              ) : (
                <div className="text-center py-4">
                  <div className="bg-synthreo-blue-darkest/20 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-synthreo-blue-medium">
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                      <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                  </div>
                  <h3 className="text-xl font-medium text-white mb-2">Check Your Email</h3>
                  <p className="text-gray-300 mb-4">
                    We've sent password reset instructions to your email address.
                  </p>
                  <Button 
                    variant="outline"
                    className="border-synthreo-blue-dark/30 text-synthreo-blue-medium"
                    onClick={() => setIsSubmitted(false)}
                  >
                    Resend Email
                  </Button>
                </div>
              )}
            </Card>

            <div className="text-center text-gray-400 text-sm">
              <p>
                Remember your password? <Link to="/login" className="text-synthreo-blue-medium hover:underline">Back to login</Link>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
