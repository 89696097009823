
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { ArrowRight } from 'lucide-react';

interface SuccessStoryCardProps {
  title: string;
  benefits: string[];
}

const SuccessStoryCard: React.FC<SuccessStoryCardProps> = ({ title, benefits }) => {
  return (
    <Card className="card-glow bg-synthreo-black-light border-synthreo-blue-dark/20">
      <CardContent className="p-6">
        <h3 className="text-xl font-bold mb-3 text-center">{title}</h3>
        <div className="space-y-3 text-gray-300 font-poppins">
          {benefits.map((benefit, index) => (
            <p key={index} className="flex items-start">
              <span className="text-synthreo-blue-medium mr-2 mt-1"><ArrowRight size={16} /></span>
              <span>{benefit}</span>
            </p>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default SuccessStoryCard;
