
import { useState, useEffect } from 'react';
import { getCurrentConsentStatus, openCookieSettings } from '../utils/cookieConsent';

/**
 * Hook to manage cookie consent status
 * @returns Object containing the current cookie status and functions to update it
 */
export function useCookieStatus() {
  const [status, setStatus] = useState('');
  
  const updateStatus = () => {
    const { consentChoice, analyticsConsent, marketingConsent } = getCurrentConsentStatus();
  
    let statusText = "Cookie settings";
    if (consentChoice === 'all') {
      statusText = "All cookies accepted";
    } else if (consentChoice === 'rejected') {
      statusText = "Only essential cookies";
    } else if (consentChoice === 'selected') {
      const enabledTypes = [];
      if (analyticsConsent) enabledTypes.push("Analytics");
      if (marketingConsent) enabledTypes.push("Marketing");
      
      if (enabledTypes.length > 0) {
        statusText = `${enabledTypes.join(", ")} cookies enabled`;
      } else {
        statusText = "Only essential cookies";
      }
    }
    
    setStatus(statusText);
  };

  useEffect(() => {
    // Initial status
    updateStatus();
    
    // Update status whenever localStorage changes
    const handleStorageChange = () => {
      updateStatus();
    };
    
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('consentUpdated', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('consentUpdated', handleStorageChange);
    };
  }, []);

  return {
    status,
    openSettings: openCookieSettings
  };
}
