
import React from 'react';
import { Link } from 'react-router-dom';
import { HoverCardContent } from "@/components/ui/hover-card";
import { Rss, Briefcase, BookOpen } from 'lucide-react';

interface ResourcesDropdownProps {
  onClose: () => void;
}

const ResourcesDropdown = ({ onClose }: ResourcesDropdownProps) => {
  return (
    <HoverCardContent 
      className="bg-synthreo-black-light border-synthreo-black-light w-80 p-0"
      align="start"
    >
      <div className="flex flex-col">
        <Link 
          to="/resources/blog" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <Rss size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">Blog</span>
            <span className="text-xs text-gray-400">Latest insights and updates</span>
          </div>
        </Link>
        <Link 
          to="/resources/case-studies" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <Briefcase size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">Case Studies</span>
            <span className="text-xs text-gray-400">Success stories from our clients</span>
          </div>
        </Link>
        <Link 
          to="/resources/documentation" 
          className="flex items-start gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <BookOpen size={18} className="text-blue-400 mt-0.5" />
          <div className="flex flex-col items-start">
            <span className="font-medium">Documentation</span>
            <span className="text-xs text-gray-400">Guides and technical resources</span>
          </div>
        </Link>
        <Link 
          to="/resources" 
          className="flex items-start gap-2 p-3 bg-synthreo-black-dark hover:bg-synthreo-black hover:text-synthreo-blue-medium text-white"
          onClick={onClose}
        >
          <div className="flex flex-col items-start">
            <span className="font-medium">View All Resources</span>
          </div>
        </Link>
      </div>
    </HoverCardContent>
  );
};

export default ResourcesDropdown;
