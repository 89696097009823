
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { Calendar, Tag, User } from 'lucide-react';
import { ContentItem } from '@/hooks/useContentData';

interface BlogCardProps {
  post: ContentItem;
  featured?: boolean;
}

const BlogCard = ({ post, featured = false }: BlogCardProps) => {
  // Add safeguards in case post is undefined or missing properties
  if (!post) {
    return null;
  }
  
  const formattedDate = post.date ? formatDistance(new Date(post.date), new Date(), { addSuffix: true }) : '';
  
  if (featured) {
    return (
      <div className="col-span-1 md:col-span-2 bg-synthreo-black-light rounded-lg overflow-hidden border border-synthreo-blue-dark/20 hover:border-synthreo-blue-dark/50 transition-all">
        <div className="flex flex-col md:flex-row h-full">
          <div className="md:w-1/2 h-60 md:h-auto">
            {post.thumbnail ? (
              <img 
                src={post.thumbnail} 
                alt={post.title || 'Blog post'} 
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-synthreo-blue-dark/20 flex items-center justify-center">
                <span className="text-synthreo-blue-medium text-lg">Synthreo</span>
              </div>
            )}
          </div>
          <div className="md:w-1/2 p-6 flex flex-col">
            <div className="flex items-center space-x-2 mb-3">
              <span className="bg-synthreo-blue-dark/20 text-synthreo-blue-medium px-3 py-1 rounded-full text-xs font-medium">
                {post.category || 'AI Insights'}
              </span>
              <span className="text-gray-400 text-xs flex items-center">
                <Calendar size={12} className="mr-1" />
                {formattedDate}
              </span>
            </div>
            <h3 className="text-xl font-bold mb-3">{post.title || 'Untitled Post'}</h3>
            <p className="text-gray-300 mb-4 flex-grow line-clamp-3">{post.description || 'No description available'}</p>
            <div className="flex items-center justify-between mt-auto">
              <div className="flex items-center">
                {post.authorImage ? (
                  <img 
                    src={post.authorImage} 
                    alt={post.author || 'Author'} 
                    className="w-8 h-8 rounded-full mr-2"
                  />
                ) : (
                  <User size={16} className="text-gray-400 mr-2" />
                )}
                <span className="text-sm text-gray-300">{post.author || 'Synthreo Team'}</span>
              </div>
              <Link 
                to={`/resources/blog/${post.slug || '#'}`}
                className="text-synthreo-blue-medium hover:text-synthreo-blue-light text-sm font-medium"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="bg-synthreo-black-light rounded-lg overflow-hidden border border-synthreo-blue-dark/20 hover:border-synthreo-blue-dark/50 transition-all h-full flex flex-col">
      <div className="h-48 bg-synthreo-blue-dark/20 relative">
        {post.thumbnail ? (
          <img 
            src={post.thumbnail} 
            alt={post.title || 'Blog post'} 
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-synthreo-blue-dark/20 flex items-center justify-center">
            <span className="text-synthreo-blue-medium text-lg">Synthreo</span>
          </div>
        )}
      </div>
      <div className="p-6 flex flex-col flex-grow">
        <div className="flex items-center space-x-2 mb-3">
          <span className="bg-synthreo-blue-dark/20 text-synthreo-blue-medium px-3 py-1 rounded-full text-xs font-medium">
            {post.category || 'AI Insights'}
          </span>
          <span className="text-gray-400 text-xs flex items-center">
            <Calendar size={12} className="mr-1" />
            {formattedDate}
          </span>
        </div>
        <h3 className="text-xl font-bold mb-3">{post.title || 'Untitled Post'}</h3>
        <p className="text-gray-300 mb-4 flex-grow line-clamp-3">{post.description || 'No description available'}</p>
        <div className="flex items-center justify-between mt-auto">
          <div className="flex items-center">
            {post.authorImage ? (
              <img 
                src={post.authorImage} 
                alt={post.author || 'Author'} 
                className="w-8 h-8 rounded-full mr-2"
              />
            ) : (
              <User size={16} className="text-gray-400 mr-2" />
            )}
            <span className="text-sm text-gray-300">{post.author || 'Synthreo Team'}</span>
          </div>
          <Link 
            to={`/resources/blog/${post.slug || '#'}`}
            className="text-synthreo-blue-medium hover:text-synthreo-blue-light text-sm font-medium"
          >
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
