
import React, { createContext, useContext, ReactNode } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

// Define the Auth context type
type AuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  loginWithRedirect: () => void;
  logout: () => void;
  user?: {
    name?: string;
    email?: string;
    picture?: string;
  };
};

// Create the Auth context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom provider component that combines Auth0Provider with our context
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || '/');
  };

  // Get Auth0 domain and client ID from environment variables
  const domain = import.meta.env.VITE_AUTH0_DOMAIN || '';
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID || '';

  if (!domain || !clientId) {
    console.error('Auth0 configuration not found. Make sure VITE_AUTH0_DOMAIN and VITE_AUTH0_CLIENT_ID environment variables are set in Netlify.');
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthContextConsumer>{children}</AuthContextConsumer>
    </Auth0Provider>
  );
};

// Consumer component that exposes Auth0 hooks via our context
const AuthContextConsumer = ({ children }: { children: ReactNode }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout: () => logout({ logoutParams: { returnTo: window.location.origin } }),
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to easily use the Auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
