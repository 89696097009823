
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Menu, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import NavLinks from './NavLinks';
import CompanyButton from './CompanyButton';
import AuthButton from './AuthButton';
import MobileMenu from './MobileMenu';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close mobile menu function
  const closeMobileMenu = () => setMobileMenuOpen(false);

  return (
    <header
      className={cn(
        'fixed top-0 z-50 w-full transition-all duration-300',
        isScrolled 
          ? 'bg-synthreo-black-dark/80 backdrop-blur-lg'
          : 'bg-synthreo-black'
      )}
    >
      <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <Link to="/" className="flex items-center gap-2">
            <img
              src="/lovable-uploads/e0f0d81d-f1ae-453b-af8b-dc39543df6e6.png"
              alt="Synthreo Logo"
              className="h-8 w-auto img-logo-dark"
            />
          </Link>
          
          <NavLinks />
        </div>

        <div className="hidden items-center space-x-4 md:flex">
          <CompanyButton />
          <AuthButton />
          
          <Link to="/auth/signup">
            <Button 
              variant="default" 
              className="btn-glow text-white hover:bg-synthreo-blue-darkest font-gilroy"
            >
              Sign Up
            </Button>
          </Link>
        </div>

        <button
          className="md:hidden text-white"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {mobileMenuOpen && (
        <MobileMenu onClose={closeMobileMenu} />
      )}
    </header>
  );
};

export default Navbar;
