
import React from 'react';

const SynthreoWay = () => {
  return (
    <section className="py-20 relative">
      <div className="absolute inset-0 z-0">
        <div className="absolute left-0 top-1/3 h-[400px] w-[400px] -translate-x-1/2 rounded-full bg-synthreo-blue-darkest/5 blur-3xl" />
        <div className="absolute right-0 bottom-1/4 h-[300px] w-[300px] translate-x-1/2 rounded-full bg-synthreo-blue-medium/5 blur-3xl" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 font-gilroy">
            The Synthreo Way: <span className="text-gradient">Your Path to AI Success</span>
          </h2>
          <div className="mx-auto h-1 w-20 bg-gradient-to-r from-synthreo-blue-darkest to-synthreo-blue-medium mb-8" />
          <p className="text-lg text-gray-300 max-w-3xl mx-auto font-poppins">
            Our proven three-phase methodology helps MSPs build and scale successful AI practices.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Phase 1 */}
          <div className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light rounded-lg p-6 border border-synthreo-blue-dark/20 relative card-glow">
            <div className="absolute -top-4 -right-4 bg-synthreo-blue-darkest/80 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold z-10 blue-glow font-gilroy">1</div>
            <h3 className="text-xl font-bold mb-4 mt-2 font-gilroy">Foundation</h3>
            <p className="text-gray-300 mb-6 font-poppins">Synthreo creates and delivers the AI practice, builds AI agents, and trains GPT models for MSPs.</p>
            <ul className="space-y-3 font-poppins">
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Full AI practice setup and delivery</span>
              </li>
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Complete AI agent development</span>
              </li>
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Custom GPT model training</span>
              </li>
            </ul>
          </div>
          
          {/* Phase 2 */}
          <div className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light rounded-lg p-6 border border-synthreo-blue-dark/20 relative card-glow">
            <div className="absolute -top-4 -right-4 bg-synthreo-blue-darkest/80 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold z-10 blue-glow font-gilroy">2</div>
            <h3 className="text-xl font-bold mb-4 mt-2 font-gilroy">Accelerate</h3>
            <p className="text-gray-300 mb-6 font-poppins">Collaboration between Synthreo and MSPs to refine AI solutions and share knowledge.</p>
            <ul className="space-y-3 font-poppins">
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Collaborative solution refinement</span>
              </li>
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Knowledge transfer and training</span>
              </li>
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Co-development of AI strategies</span>
              </li>
            </ul>
          </div>
          
          {/* Phase 3 */}
          <div className="bg-gradient-to-b from-synthreo-black to-synthreo-black-light rounded-lg p-6 border border-synthreo-blue-dark/20 relative card-glow">
            <div className="absolute -top-4 -right-4 bg-synthreo-blue-darkest/80 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold z-10 blue-glow font-gilroy">3</div>
            <h3 className="text-xl font-bold mb-4 mt-2 font-gilroy">Elevate</h3>
            <p className="text-gray-300 mb-6 font-poppins">MSPs independently offer AI practices, build AI agents, and manage GPTs, with Synthreo providing support as needed.</p>
            <ul className="space-y-3 font-poppins">
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Independent AI practice operation</span>
              </li>
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Self-sufficient agent development</span>
              </li>
              <li className="flex items-start">
                <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                <span>Ongoing advisory support</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SynthreoWay;
