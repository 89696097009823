
import React from 'react';
import { BarChart2 } from 'lucide-react';

const MarketOpportunity = () => {
  return (
    <section className="py-16 bg-synthreo-black-light relative">
      <div className="absolute inset-0 z-0 bg-grid-pattern opacity-5" />
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 font-gilroy">
              The AI <span className="text-gradient">Opportunity</span> for MSPs
            </h2>
            <div className="mx-auto h-1 w-20 bg-gradient-to-r from-synthreo-blue-darkest to-synthreo-blue-medium mb-8" />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <p className="text-lg mb-6 text-gray-300 font-poppins">
                By 2027, AI is expected to displace approximately 12 million jobs, with half of those managed by MSPs. This represents a potential <span className="font-semibold text-white">$18 billion revenue loss</span> for the MSP industry.
              </p>
              <p className="text-lg mb-6 text-gray-300 font-poppins">
                But this disruption also presents an unprecedented opportunity: MSPs who embrace AI now can lead the market transformation and secure their position as strategic technology partners.
              </p>
              <p className="text-lg text-gray-300 font-poppins">
                Synthreo empowers MSPs to capitalize on this shift by providing the tools, knowledge, and support needed to build a successful AI practice.
              </p>
            </div>
            <div className="bg-synthreo-black-dark p-6 rounded-lg border border-synthreo-blue-dark/20">
              <BarChart2 className="h-16 w-16 text-synthreo-blue-medium-darkest mb-4" />
              <h3 className="text-xl font-bold mb-3 font-gilroy">Market Trends</h3>
              <ul className="space-y-3 font-poppins">
                <li className="flex items-start">
                  <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                  <span>12M+ jobs potentially displaced by AI by 2027</span>
                </li>
                <li className="flex items-start">
                  <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                  <span>50% of these jobs currently managed by MSPs</span>
                </li>
                <li className="flex items-start">
                  <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                  <span>$18B in potential MSP revenue at risk</span>
                </li>
                <li className="flex items-start">
                  <span className="h-2 w-2 rounded-full bg-synthreo-blue-medium mr-3 mt-2"></span>
                  <span>83% of organizations planning to increase AI spending</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketOpportunity;
