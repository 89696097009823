
import React from 'react';
import { CheckCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const PartnerCTA = () => {
  return (
    <section id="partner-application" className="py-24 relative overflow-hidden">
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/4 top-1/4 h-[400px] w-[400px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-synthreo-blue-darkest/10 blur-3xl" />
        <div className="absolute right-1/4 bottom-1/4 h-[400px] w-[400px] translate-x-1/2 translate-y-1/2 rounded-full bg-synthreo-blue-medium/10 blur-3xl" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 font-gilroy">
              Secure Your Position in the <span className="text-gradient">Evolving Digital Labor Market</span>
            </h2>
            <p className="text-xl text-gray-300 mb-6 font-poppins">
              Join Synthreo's partner program today and transform AI disruption into your next growth opportunity.
            </p>
          </div>
          
          <div className="bg-gradient-to-r from-synthreo-blue-darkest/20 to-synthreo-blue-dark/10 rounded-2xl p-8 border border-synthreo-blue-dark/20 mb-8">
            <h3 className="text-xl font-bold mb-4 font-gilroy">Why Partner With Us?</h3>
            <ul className="space-y-4 font-poppins">
              <li className="flex items-start">
                <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                <span>White-label AI solutions ready to sell</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                <span>Comprehensive 90-day implementation plan</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                <span>Marketing and sales enablement resources</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-5 w-5 text-synthreo-blue-medium mr-3 flex-shrink-0" />
                <span>Ongoing technical support and training</span>
              </li>
            </ul>
          </div>
          
          <div className="text-center">
            <p className="mb-4 text-gray-300 font-poppins">
              Ready to get started? Contact us to learn more about our partner program.
            </p>
            <Button 
              className="rounded-lg btn-glow px-8 py-4 text-base font-medium text-white h-auto"
              asChild
            >
              <Link to="/company/contact">Contact Us</Link>
            </Button>
            <p className="mt-4 text-gray-300 font-poppins">
              Or schedule a demo to see our solutions in action.
            </p>
            <Button 
              className="mt-2 rounded-lg bg-synthreo-black-light px-8 py-4 text-base font-medium text-white transition-colors hover:bg-synthreo-black-dark h-auto"
              asChild
            >
              <Link to="/company/demo">Schedule a Demo</Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerCTA;
