
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { formSchema, FormValues } from './signupFormSchema';

export const useSignupForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      company: "",
      phone: "",
      desiredOutcome: "",
      agreeToTerms: false
    }
  });

  const onSubmit = async (data: FormValues) => {
    try {
      setIsSubmitting(true);
      console.log('Form data to be submitted:', data);
      
      // Create a URLSearchParams object directly
      const formData = new URLSearchParams();
      
      // Add the form name (required for Netlify)
      formData.append('form-name', 'synthreo-signup');
      
      // Add all other form fields
      Object.entries(data).forEach(([key, value]) => {
        // Convert boolean values to strings for Netlify
        if (typeof value === 'boolean') {
          formData.append(key, value ? 'true' : 'false');
        } else if (value !== undefined && value !== null) {
          formData.append(key, value.toString());
        }
      });
      
      // Log the form data for debugging
      console.log('FormData contents:');
      for (let [key, value] of formData.entries()) {
        console.log(key + ': ' + value);
      }
      
      // Submit directly to Netlify
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData.toString()
      });
      
      if (!response.ok) {
        throw new Error(`Network response error: ${response.status} ${response.statusText}`);
      }
      
      console.log('Form submitted successfully to Netlify');
      toast.success("Account request submitted successfully!");
      
      // Reset form
      form.reset();
      
      // Navigate to success page
      navigate("/auth/signup?signup=success");
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    form,
    isSubmitting,
    onSubmit
  };
};
