
import React from 'react';
import { useIsMobile } from '@/hooks/use-mobile';

const MissionHeader = () => {
  const isMobile = useIsMobile();
  
  return (
    <section className={`${isMobile ? 'py-20' : 'py-28 md:py-32'} relative`}>
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-6">
            Our <span className="text-gradient">Mission</span> & Story
          </h1>
          <p className="text-base md:text-lg text-gray-300 leading-relaxed">
            Founded in 2021, Synthreo is on a mission to transform how businesses operate through 
            intelligent AI solutions that work seamlessly alongside human teams.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MissionHeader;
