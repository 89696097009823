
import React from 'react';
import { Mail, MapPin, Phone, Facebook, Linkedin, Instagram } from 'lucide-react';

const contactInfo = [
  {
    icon: <Mail className="h-5 w-5 text-synthreo-blue-medium" />,
    title: 'Email',
    details: 'info@synthreo.com',
  },
  {
    icon: <Phone className="h-5 w-5 text-synthreo-blue-medium" />,
    title: 'Phone',
    details: '+1 (602) 649-4979',
  },
  {
    icon: <MapPin className="h-5 w-5 text-synthreo-blue-medium" />,
    title: 'Location',
    details: 'Phoenix, AZ',
  },
];

const ContactInfo = () => {
  return (
    <div className="rounded-lg bg-synthreo-black-light p-8">
      <div className="mb-8">
        <h3 className="mb-8 text-2xl font-bold text-white">Contact Information</h3>
        <div className="space-y-8">
          {contactInfo.map((item, index) => (
            <div key={index} className="flex items-center">
              <div className="mr-6 flex h-12 w-12 items-center justify-center rounded-full bg-synthreo-blue-darkest/20 text-synthreo-blue-medium">
                {item.icon}
              </div>
              <div>
                <h4 className="text-lg font-semibold text-white mb-1">{item.title}</h4>
                <p className="text-lg text-gray-400">{item.details}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-12">
        <h3 className="mb-6 text-xl font-bold text-white">Connect With Us</h3>
        <div className="flex space-x-5">
          <a href="https://facebook.com/synthreo" target="_blank" rel="noopener noreferrer" className="flex h-12 w-12 items-center justify-center rounded-full bg-synthreo-blue-darkest/20 text-synthreo-blue-medium transition-colors hover:bg-synthreo-blue-darkest/30">
            <Facebook size={22} />
          </a>
          <a href="https://linkedin.com/company/synthreo" target="_blank" rel="noopener noreferrer" className="flex h-12 w-12 items-center justify-center rounded-full bg-synthreo-blue-darkest/20 text-synthreo-blue-medium transition-colors hover:bg-synthreo-blue-darkest/30">
            <Linkedin size={22} />
          </a>
          <a href="https://instagram.com/synthreo" target="_blank" rel="noopener noreferrer" className="flex h-12 w-12 items-center justify-center rounded-full bg-synthreo-blue-darkest/20 text-synthreo-blue-medium transition-colors hover:bg-synthreo-blue-darkest/30">
            <Instagram size={22} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
