
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import ProductsDropdown from './dropdowns/ProductsDropdown';
import SolutionsDropdown from './dropdowns/SolutionsDropdown';
import ResourcesDropdown from './dropdowns/ResourcesDropdown';

const NavLinks = () => {
  const location = useLocation();
  const [productsHoverOpen, setProductsHoverOpen] = useState(false);
  const [resourcesHoverOpen, setResourcesHoverOpen] = useState(false);
  const [solutionsHoverOpen, setSolutionsHoverOpen] = useState(false);

  // Regular links in alphabetical order
  const navLinks = [
    { name: 'Partners', href: '/partners' },
    { name: 'Pricing', href: '/pricing' },
  ];

  // Toggle function for hover cards
  const handleDropdownClick = (setter) => {
    // Close all other dropdowns
    if (setter !== setProductsHoverOpen) setProductsHoverOpen(false);
    if (setter !== setResourcesHoverOpen) setResourcesHoverOpen(false);
    if (setter !== setSolutionsHoverOpen) setSolutionsHoverOpen(false);
    
    // Toggle the clicked dropdown
    setter(prev => !prev);
  };

  return (
    <nav className="hidden md:flex md:ml-6">
      <ul className="flex space-x-6">
        <li>
          <HoverCard open={productsHoverOpen} onOpenChange={setProductsHoverOpen} openDelay={0} closeDelay={0}>
            <HoverCardTrigger asChild>
              <button
                onClick={() => handleDropdownClick(setProductsHoverOpen)}
                className={cn(
                  "inline-flex items-center text-sm font-medium transition-colors font-poppins",
                  location.pathname.includes('/products') 
                    ? "text-synthreo-blue-medium" 
                    : "text-gray-300 hover:text-synthreo-blue-medium"
                )}
              >
                Products
              </button>
            </HoverCardTrigger>
            <ProductsDropdown onClose={() => setProductsHoverOpen(false)} />
          </HoverCard>
        </li>
        
        <li>
          <HoverCard open={resourcesHoverOpen} onOpenChange={setResourcesHoverOpen} openDelay={0} closeDelay={0}>
            <HoverCardTrigger asChild>
              <button
                onClick={() => handleDropdownClick(setResourcesHoverOpen)}
                className={cn(
                  "inline-flex items-center text-sm font-medium transition-colors font-poppins",
                  location.pathname.includes('/resources') 
                    ? "text-synthreo-blue-medium" 
                    : "text-gray-300 hover:text-synthreo-blue-medium"
                )}
              >
                Resources
              </button>
            </HoverCardTrigger>
            <ResourcesDropdown onClose={() => setResourcesHoverOpen(false)} />
          </HoverCard>
        </li>
        
        <li>
          <HoverCard open={solutionsHoverOpen} onOpenChange={setSolutionsHoverOpen} openDelay={0} closeDelay={0}>
            <HoverCardTrigger asChild>
              <button
                onClick={() => handleDropdownClick(setSolutionsHoverOpen)}
                className={cn(
                  "inline-flex items-center text-sm font-medium transition-colors font-poppins",
                  location.pathname.includes('/solutions') 
                    ? "text-synthreo-blue-medium" 
                    : "text-gray-300 hover:text-synthreo-blue-medium"
                )}
              >
                Solutions
              </button>
            </HoverCardTrigger>
            <SolutionsDropdown onClose={() => setSolutionsHoverOpen(false)} />
          </HoverCard>
        </li>
        
        {navLinks.map((link) => (
          <li key={link.name}>
            <Link
              to={link.href}
              className={cn(
                "text-sm font-medium transition-colors font-poppins",
                location.pathname === link.href 
                  ? "text-synthreo-blue-medium" 
                  : "text-gray-300 hover:text-synthreo-blue-medium"
              )}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavLinks;
