
import React from 'react';
import CompanySection from './mobile/CompanySection';
import SolutionsSection from './mobile/SolutionsSection';
import ProductsSection from './mobile/ProductsSection';
import ResourcesSection from './mobile/ResourcesSection';
import SignUpButton from './mobile/SignUpButton';
import AuthButton from './mobile/AuthButton';

interface MobileMenuProps {
  onClose: () => void;
}

const MobileMenu = ({ onClose }: MobileMenuProps) => {
  return (
    <div className="absolute inset-x-0 top-16 z-40 bg-synthreo-black-dark py-2 shadow-lg md:hidden">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex flex-col">
          <ul className="space-y-1">
            <SolutionsSection onClose={onClose} />
            <ProductsSection onClose={onClose} />
            <ResourcesSection onClose={onClose} />
            <CompanySection onClose={onClose} />
            <AuthButton onClose={onClose} />
            <SignUpButton onClose={onClose} />
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu;
