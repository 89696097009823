
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { LucideIcon } from 'lucide-react';

interface MenuLinkProps {
  title: string;
  route: string;
  icon?: LucideIcon;
  description?: string;
  onClose: () => void;
}

const MenuLink = ({ title, route, icon: Icon, description, onClose }: MenuLinkProps) => {
  const location = useLocation();
  
  return (
    <li>
      <Link 
        to={route} 
        className={cn(
          "block py-1.5 font-medium transition-colors flex items-center gap-3",
          location.pathname === route 
            ? "text-synthreo-blue-medium" 
            : "text-gray-300 hover:text-synthreo-blue-medium"
        )}
        onClick={onClose}
      >
        {Icon && <Icon size={18} className="text-blue-400 flex-shrink-0" />}
        {description ? (
          <div className="flex flex-col">
            <span className="text-base">{title}</span>
            <span className="text-xs text-gray-400">{description}</span>
          </div>
        ) : (
          <span className="text-base">{title}</span>
        )}
      </Link>
    </li>
  );
};

export default MenuLink;
