
import React from 'react';
import { Monitor, Terminal } from 'lucide-react';
import MenuSection from './MenuSection';
import MenuLink from './MenuLink';

interface ProductsSectionProps {
  onClose: () => void;
}

const ProductsSection = ({ onClose }: ProductsSectionProps) => {
  return (
    <MenuSection title="Products" route="/products" onClose={onClose}>
      <MenuLink 
        title="ThreoAI"
        route="/products/threoai" 
        icon={Monitor}
        description="Enterprise-grade AI assistant platform"
        onClose={onClose}
      />
      <MenuLink 
        title="ThreoBuilder"
        route="/products/threobuilder" 
        icon={Terminal}
        description="No-code AI solution platform"
        onClose={onClose}
      />
    </MenuSection>
  );
};

export default ProductsSection;
