
import React from 'react';
import { Link } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';

const LeadershipCTA = () => {
  const isMobile = useIsMobile();
  
  return (
    <section className="py-12 md:py-16 relative">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 font-gilroy">
          Meet Our <span className="text-synthreo-blue-medium">Leadership</span> Team
        </h2>
        <p className="text-sm md:text-base text-gray-300 mb-6 md:mb-8 max-w-2xl mx-auto">
          Discover the passionate individuals driving Synthreo's innovation and growth.
        </p>
        <Link 
          to="/company/leadership"
          className={`inline-flex items-center justify-center rounded-md bg-synthreo-blue-darkest ${isMobile ? 'px-4 py-2 text-sm' : 'px-6 py-3 text-base'} font-medium text-white shadow-sm hover:bg-synthreo-blue-dark transition-colors duration-200 btn-glow`}
        >
          Meet Our Leadership
        </Link>
      </div>
    </section>
  );
};

export default LeadershipCTA;
