
import React from 'react';
import { Form } from "@/components/ui/form";
import FormNameField from './FormNameField';
import FormEmailField from './FormEmailField';
import FormCompanyField from './FormCompanyField';
import FormPhoneField from './FormPhoneField';
import FormDesiredOutcomeField from './FormDesiredOutcomeField';
import FormTermsField from './FormTermsField';
import { useSignupForm } from './useSignupForm';

const SignUpForm = () => {
  const { form, isSubmitting, onSubmit } = useSignupForm();

  return (
    <div className="rounded-lg bg-synthreo-black-light p-8 max-w-2xl mx-auto">
      <Form {...form}>
        <form 
          className="space-y-6"
          onSubmit={form.handleSubmit(onSubmit)}
          data-netlify="true"
          name="synthreo-signup"
        >
          {/* Hidden Netlify form fields */}
          <input type="hidden" name="form-name" value="synthreo-signup" />
          <input type="hidden" name="bot-field" />
          
          <FormNameField control={form.control} />
          <FormEmailField control={form.control} />
          <FormCompanyField control={form.control} />
          <FormPhoneField control={form.control} />
          <FormDesiredOutcomeField control={form.control} />
          <FormTermsField control={form.control} />
          
          <button
            type="submit"
            className="btn-glow w-full rounded-md py-3 text-white transition-all duration-300"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Create Account"}
          </button>
        </form>
      </Form>
    </div>
  );
};

export default SignUpForm;
